<div class="container">
    <div style="margin-top: 15px; margin-bottom: 30px">
        <h1>Realtime Statistics</h1>
    </div>
    <!-- <div class="row" *ngIf="!subResultsLoaded || !userResultsLoaded || !esimResultsLoaded">
        <div class="col">
            <div class="text-center">
                <div class="spinner-border text-secondary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    </div> -->
    <div class="row" style="margin-bottom: 30px" *ngIf="esimResultsLoaded">
        <div class="col">
            <h2>eSIM Reserves</h2>
            <div class="chart-container">
                <ngx-charts-number-card
                    [view]="[400, 200]"
                    [results]="esimData"
                    [cardColor]="'#1E293B'"
                    [bandColor]="'#647ACB'"
                    [animations]="true"
                >
                </ngx-charts-number-card>
            </div>
        </div>
        <div class="col">
            <h2>Last 24 Hour Sign Ups</h2>
            <div class="chart-container">
                <ngx-charts-bar-vertical
                    [view]="[600, 300]"
                    [results]="signUpData"
                    [xAxis]="true"
                    [yAxis]="true"
                    [showGridLines]="false"
                    [roundDomains]="false"
                    [showXAxisLabel]="true"
                    [showYAxisLabel]="true"
                    [rotateXAxisTicks]="true"
                    [animations]="true"
                    [gradient]="false"
                    [legend]="false"
                    [showDataLabel]="false"
                    [roundEdges]="false"
                >
                </ngx-charts-bar-vertical>
            </div>
        </div>
    </div>
    <div class="row" style="margin-bottom: 30px" *ngIf="userResultsLoaded">
        <div class="col">
            <h2>User Data</h2>
            <div class="chart-container">
                <ngx-charts-bar-vertical
                    [view]="[500, 300]"
                    [results]="userData"
                    [xAxis]="true"
                    [yAxis]="true"
                    [showGridLines]="false"
                    [roundDomains]="false"
                    [showXAxisLabel]="true"
                    [showYAxisLabel]="true"
                    [rotateXAxisTicks]="true"
                    [animations]="true"
                    [gradient]="false"
                    [legend]="false"
                    [showDataLabel]="false"
                    [roundEdges]="false"
                >
                </ngx-charts-bar-vertical>
            </div>
        </div>
        <div class="col">
            <h2>Last 24 Hour Usages</h2>
            <div class="chart-container">
                <ngx-charts-number-card
                    [view]="[500, 200]"
                    [results]="lastDayData"
                    [cardColor]="'#1E293B'"
                    [bandColor]="'#647ACB'"
                    [animations]="true"
                >
                </ngx-charts-number-card>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="subResultsLoaded">
        <div class="col">
            <h2>Active Subscriptions</h2>
            <div class="chart-container">
                <ngx-charts-advanced-pie-chart
                    [view]="[700, 200]"
                    [scheme]="colorScheme"
                    [results]="subscriptionStats"
                    [gradient]="true"
                    [animations]="true"
                >
                </ngx-charts-advanced-pie-chart>
            </div>
        </div>
    </div>
</div>
