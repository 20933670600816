import { Component, OnDestroy, OnInit } from '@angular/core';
import { Partner } from 'src/app/core/models/partners/partner';
import { PartnerDataUsage } from 'src/app/core/models/partners/partner-data-usage';
import { PartnerUsageCount } from 'src/app/core/models/partners/partner-usage-counts';
import { PartnersHttpService } from 'src/app/core/services/partners.http.service';
import { ToasterService } from 'src/app/core/services/toaster.service';
import * as shape from "d3-shape";
import { DATA_MONTHLY_USAGE } from 'src/assets/mocks/mockDataMonthlyUsage';
import { USAGE_COUNTS } from 'src/assets/mocks/mockUsageCounts';
import { FirebaseAuthService } from 'src/app/core/services/firebase-auth.service';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { Country } from 'src/app/core/models/country';
import { ProviderHttpService } from 'src/app/core/services/provider.http.service';
import { ChartData } from 'src/app/core/models/charts/chart-data';
import { ChartTools } from 'src/app/shared/utils/chart-tools';

@Component({
  selector: 'app-partner-company-dashboard',
  templateUrl: './partner-company-dashboard.component.html',
  styleUrls: ['./partner-company-dashboard.component.scss']
})
export class PartnerCompanyDashboardComponent implements OnInit, OnDestroy {
  public partners$: BehaviorSubject<Partner[]> = new BehaviorSubject<Partner[]>([]);
  public partnerUsageCounts: PartnerUsageCount;
  public partnerSelected: Partner;
  public countryList: Country[] = [];

  public colorScheme = {
    domain: ChartTools.colors
  };

  public curve = shape.curveMonotoneX;

  public usageCountValuesAndLabels = [
    {
      name: 'Total users',
      value: null
    },
    {
      name: 'Total active SIMs',
      value: null
    },
    {
      name: 'Total SIMs in use (today)',
      value: null
    },
    {
      name: 'New users (last month)',
      value: null
    }
  ];

  private unsubscribe$: Subject<void> = new Subject<void>();

  public periodicityAggregationOptions = [
    { value: 'DAILY', label: 'Daily' },
    { value: 'WEEKLY', label: 'Weekly' },
    { value: 'MONTHLY', label: 'Monthly' }
  ];

  filterOptionSelected = 'ICCID';

  public partnerDailyUsage$ = new BehaviorSubject<PartnerDataUsage[]>([]);
  public totalCostPerCountryPeriodicity$ = new BehaviorSubject('DAILY');
  public activeUsersPeriodicity$ = new BehaviorSubject('DAILY');
  public selectedCountry$: BehaviorSubject<Country> = new BehaviorSubject(null);


  public activeUsersVerticalChartData$ = combineLatest([
    this.activeUsersPeriodicity$,
    this.partnerDailyUsage$,
    this.selectedCountry$
  ]).pipe(
    map(([periodicity, partnerDailyUsage, selectedCountry]) => this.aggregateActiveUsersChartData(periodicity, partnerDailyUsage, selectedCountry))
  );

  public totalDailyUsageChartData$ = combineLatest([
    this.totalCostPerCountryPeriodicity$,
    this.partnerDailyUsage$,
    this.selectedCountry$
  ]).pipe(
    map(([periodicity, partnerDailyUsage, selectedCountry]) => this.aggregateTotalCoastPerCountryChartData(periodicity, partnerDailyUsage, selectedCountry))
  );

  part
  constructor(
    private readonly partnersHttpService: PartnersHttpService,
    private readonly toasterService: ToasterService,
    private readonly _providerHttpService: ProviderHttpService,
    private readonly _datePipe: DatePipe,
    public readonly firebaseAuthService: FirebaseAuthService
  ) { }

  ngOnInit(): void {
    this.getPartners();
    this.getCountries();

    combineLatest([
      this.firebaseAuthService.user,
      this.partners$
    ])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(([user, partners]) => {
      if(partners?.length) {
        const customAttributes = JSON.parse(user['reloadUserInfo']?.customAttributes);
        if(customAttributes.role === 'partner') {
          this.partnerSelected = partners.find(partner => partner.partnerId === customAttributes.partnerId);
          this.getDataDailyUsage(this.partnerSelected);
          this.getUsageCounts(this.partnerSelected);
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setActiveUsersPeriodicity(periodicity: string) {
    this.activeUsersPeriodicity$.next(periodicity);
  }

  setTotalCostPerCountryPeriodicity(periodicity: string) {
    this.totalCostPerCountryPeriodicity$.next(periodicity);
  }

  aggregateActiveUsersChartData(periodicity: string, _partnerDailyUsage: PartnerDataUsage[], country: Country): ChartData[] {
    const partnerDailyUsage = ChartTools.filterByCountry(
      globalThis.structuredClone(_partnerDailyUsage),
      country
    );

    if (periodicity === 'DAILY') {
      return this._generateActiveUsersBarVerticalStackedChartData(partnerDailyUsage);
    }
    if (periodicity === 'WEEKLY') {
      return this._generateActiveUsersBarVerticalStackedChartData(
        ChartTools.aggregateDailyDataIntoWeekly(partnerDailyUsage)
      );
    }
    if (periodicity === 'MONTHLY') {
      return this._generateActiveUsersBarVerticalStackedChartData(
        ChartTools.aggregateDailyDataIntoMonthly(partnerDailyUsage)
      );
    }
    return [];
  }

  aggregateTotalCoastPerCountryChartData(periodicity: string, _partnerDailyUsage: PartnerDataUsage[], country: Country): ChartData[] {
    const partnerDailyUsage = ChartTools.filterByCountry(
      globalThis.structuredClone(_partnerDailyUsage),
      country
    );

    if (periodicity === 'DAILY') {
      return this._generateUsageLineChartData(partnerDailyUsage);
    }
    if (periodicity === 'WEEKLY') {
      return this._generateUsageLineChartData(
        ChartTools.aggregateDailyDataIntoWeekly(partnerDailyUsage)
      );
    }
    if (periodicity === 'MONTHLY') {
      return this._generateUsageLineChartData(
        ChartTools.aggregateDailyDataIntoMonthly(partnerDailyUsage)
      );
    }
    return [];
  }

  getPartners(): void {
    this.partnersHttpService.listPartners().subscribe(
      (response) => {
        if (response.data?.length > 0) {
          this.partners$.next(response.data);
        } else {
          this.toasterService.error('No partners found');
        }
      },
      () => {
        this.toasterService.error('Error fetching partners');
      }
    );
  }

  partnerChanged(event: Event): void {
    this.partnerSelected = this.partners$.value.find((partner) => partner.partnerId === event.target['value']);
    this.getDataDailyUsage(this.partnerSelected);
    this.getUsageCounts(this.partnerSelected);
  }

  countryChanged(event: Event): void {
    this.selectedCountry$.next(this.countryList.find((country) => country.isoCountryCode === event.target['value']));
  }

  private _getTickName(date: string): string {
    return RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}').test(date)
    ? this._datePipe.transform(date, 'YYYY-MM-dd')
    : date;
  }
  
  private _generateUsageLineChartData(partnerDataUsage: PartnerDataUsage[]): ChartData[] {
    const countriesSet = new Set<string>();

    partnerDataUsage.forEach((data) => {
      data.usage.forEach((usageItem) => {
        countriesSet.add(usageItem.country);
      });
    });

    const countriesData = [];
    countriesSet.forEach((country) => {
      countriesData.push({
        name: country,
        series: partnerDataUsage.map((data) => {
          const usageItem = data.usage.find((item) => item.country === country);

          return {
            name: this._getTickName(data.date),
            value: usageItem ? usageItem.usageAmount : 0
          }
        })
      });
    });

    return countriesData;
  }

  private _generateActiveUsersBarVerticalStackedChartData(partnerDataUsage: PartnerDataUsage[]): ChartData[] {
    const chartData = [];

    partnerDataUsage.forEach((data) => {
      chartData.push({
        name: this._getTickName(data.date),
        series: data.usage.map((usageItem) => {
          return {
            name: usageItem.country,
            value: usageItem.activeUsers
          }
        })
      })
    });

    return chartData;
  }

  getDataDailyUsage(partner: Partner) {
    this.partnerDailyUsage$.next(DATA_MONTHLY_USAGE);
  }

  getUsageCounts(partner: Partner) {
    this.partnerUsageCounts = USAGE_COUNTS as any;

    this.usageCountValuesAndLabels = [
      {
        name: 'Total users',
        value: this.partnerUsageCounts.totalUsers
      },
      {
        name: 'Total active SIMs',
        value: this.partnerUsageCounts.activeEsims
      },
      {
        name: 'Total SIMs in use (today)',
        value: this.partnerUsageCounts.availableEsims
      },
      {
        name: 'New users (last month)',
        value: this.partnerUsageCounts.newUsers
      }
    ]
  }

  onSelect(data): void {
  }

  onActivate(data): void {
  }

  onDeactivate(data): void {
  }

  totalCostYAxisTickFormatting(_value: number): string {
    const value = new Intl.NumberFormat("en-EN", {}).format(
      _value,
    )
    return `${value} €`;
  }

  activeUsersYAxisTickFormatting(_value: number): string {
    return new Intl.NumberFormat("en-EN", {}).format(
      _value,
    )
  }

  public onDateRangeSelection(range: { from: Date, to: Date }) {
    console.log(`Selected range: ${range.from} - ${range.to}`);
  }

  private getCountries(): void {
    this._providerHttpService.getCountries().subscribe(
      (response) => {
        this.countryList = [{ isoCountryCode: "global", name: "Global" }];
        response.data.forEach((country) => {
          this.countryList.push({ isoCountryCode: country.isoCountryCode, name: `${country.isoCountryCode} - ${country.name}` });
        });
      },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      }
    );
  }

  setFilterOption(event: Event): void {
    this.filterOptionSelected = event.target['value'];
  }

}
