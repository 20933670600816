<div class="bg-dark-blue-transparent-0-3 px-5 pt-5 br-0-5rem position-relative">
    <div class="position-absolute top-0 end-0 p-3">
        <span class="d-inline-flex align-items-center">
            <button [tabindex]="isVisible ? 0 : -1" class="unstyled-btn" (click)="close()">
                <i class="bi bi-x-circle-fill h1 text-firsty-green"></i>
            </button>
        </span>
    </div>

    <h1 class="text-center mb-4">Partner Authentication</h1>

    <form [formGroup]="loginForm" (ngSubmit)="sendData()">
        <div class="mb-3">
            <div class="form-floating">
                <input [tabindex]="isVisible ? 0 : -1" type="email" id="email-input" formControlName="email" class="form-control" placeholder="Email address">
                <label for="email-input">Email address</label>
            </div>
            <div *ngIf="loginForm.get('email')?.touched && loginForm.get('email')?.hasError('required')">
                <small class="form-input-error">Email required</small>
            </div>
            <div *ngIf="loginForm.get('email')?.touched && loginForm.get('email')?.hasError('email')">
                <small class="form-input-error">Invalid e-mail format</small>
            </div>
        </div>
        
        <div class="mb-3">
            <div class="form-floating">
                <input [tabindex]="isVisible ? 0 : -1" type="password" id="password-input" formControlName="password" class="form-control" placeholder="Password">
                <label for="password-input">Password</label>
            </div>
            <div *ngIf="loginForm.get('password')?.touched && loginForm.get('password')?.hasError('required')">
                <small class="form-input-error">Password required</small>
            </div>
        </div>
        
        <div>
            <button type="submit" [disabled]="loginForm.invalid" class="large-pill-btn">Login</button>
        </div>

        <div class="text-center mt-4">
            <small class="text-white"><span (click)="signUp()" role="button" class="text-decoration-underline">Signup</span> with new account</small>
        </div>

        <div class="text-center pt-3 pb-4">
            <span class="footer-logo-container bg-firsty-green">
                <img src="assets/images/logo/firsty-logo-dark.svg" alt="">
            </span>
            <span class="footer-text-logo-container ms-1">
                <img src="assets/images/logo/firsty-text-logo.svg" alt="">
            </span>
        </div>
    </form>
</div>