import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent implements OnInit {

  @Input() type: string;
  @Input() message: string;
  @Input() buttons: {btnText: string, action: CallableFunction, btnClass?: string}[] = [];

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
