<!-- <div class="modal-header">
    <h4 class="modal-title">Hi there!</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div> -->
<div class="modal-body">
    <div>
        <div [ngSwitch]="type">
            <div *ngSwitchCase="'info'" class="text-center">
                <i class="bi bi-info-circle-fill text-info fs-2rem"></i>
            </div>
            <div *ngSwitchCase="'success'" class="text-center">
                <i class="bi bi-check-circle-fill text-success fs-2rem"></i>
            </div>
            <div *ngSwitchCase="'warn'" class="text-center">
                <i class="bi bi-exclamation-triangle-fill text-warning fs-2rem"></i>
            </div>
            <div *ngSwitchCase="'error'" class="text-center">
                <i class="bi bi-x-circle-fill text-danger fs-2rem"></i>
            </div>
            <div *ngSwitchDefault></div>
        </div>
    </div>
    <div class="text-center py-3">{{message}}</div>

    <div *ngIf="buttons.length" class="text-center pt-2">
        <button *ngFor="let button of buttons" type="button" [class]="'btn mx-2 ' + (button.btnClass || 'btn-outline-secondary')" (click)="button.action?.(); activeModal.close('')">{{button.btnText}}</button>
    </div>

    <div *ngIf="!buttons.length" class="text-center pt-2">
        <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close('Close click')">Ok</button>
    </div>
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close('Close click')">Close</button>
</div> -->