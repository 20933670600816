<div class="container pb-3">
    <h1>Employee Data Consumption</h1>

    <div class="bg-dark-blue-transparent br-0-5rem p-3 shadow d-flex justify-content-between align-items-center mb-4">
        <div class="input-group w-auto">
            <span class="input-group-text">
                <i class="bi bi-search"></i>
            </span>
            <input 
                type="text"
                class="form-control" 
                name="employee-search"
                id="employee-search"
                autocomplete="employee-search"
                (input)="searchTermInput($event)"
            />
        </div>

        <div>
            <button 
                type="button" 
                class="btn btn-primary mx-2"
                (click)="loadEmployeesDataConsumption()"
            >
                <i class="bi bi-arrow-clockwise"></i>
                <span class="ms-2 d-none d-lg-inline-block">Refresh</span>
            </button>

            <button 
                type="button" 
                class="btn btn-primary mx-2"
                (click)="exportAsExcel($event)"
            >
                <i class="bi bi-file-earmark-spreadsheet"></i>
                <span class="ms-2 d-none d-lg-inline-block">Export</span>
            </button>
        </div>
    </div>

    <div class="d-lg-none">
        <div *ngFor="let item of (filteredList$ | async)" class="br-0-5rem shadow mb-4 text-white bg-dark-blue-transparent p-3 d-flex">
            <div class="d-flex align-items-center me-3">
                <i style="font-size: 3rem;" class="bi bi-bar-chart-fill"></i>
            </div>
            
            <div class="flex-grow-1">
                <div class="fw-bold">{{item.email}}</div>
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        <div>{{item.country}}</div>
                        <div>{{item.days}} days</div>
                    </div>
                    <div class="d-flex flex-column justify-content-evenly align-items-center h-100">
                        <div>{{item.data_consumed}}</div>
                        <div class="fw-bold">{{item.total_price}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="d-none d-lg-block br-0-5rem shadow mb-4">
        <table class="table table-striped rounded-table">
            <thead>
                <tr>
                    <th *ngFor="let column of columns">
                        <div role="button" (click)="sort(column.name)">
                            <span class="user-select-none">{{column.label}}</span>
                            <span class="sorting-order-indicator">
                                <ng-container *ngIf="column.name === (sortingColumn$ | async)">
                                    <span class="ps-2" *ngIf="sortingOrder === 'asc'">
                                        <i class="bi bi-caret-up-fill"></i>
                                    </span>
                                    <span class="ps-2" *ngIf="sortingOrder === 'desc'">
                                        <i class="bi bi-caret-down-fill"></i>
                                    </span>
                                </ng-container>
                            </span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of (filteredList$ | async)">
                    <td *ngFor="let column of columns">
                        <div class="d-flex">{{item[column.name]}}</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="bg-dark-blue-transparent br-0-5rem p-3 shadow d-lg-flex justify-content-between align-items-center">
        <div class="text-white opacity-75 py-1 text-center">
            {{ itemsPerPage }} items per page
        </div>

        <div class="d-flex justify-content-center align-items-center">
            <ngb-pagination
                [collectionSize]="resultSize"
                [page]="currentPage$ | async"
                [pageSize]="itemsPerPage"
                (pageChange)="pageChange($event)"
                [maxSize]="3"
                [rotate]="true"
                [boundaryLinks]="false"
                size="sm"
                class="mb-0 pagination-dark"
                (pageChange)="pageChange($event)"
            ></ngb-pagination>
        </div>

        <div class="text-white opacity-75 py-1 text-center">
            Showing {{ startIndex + 1 }}-{{
                endIndex > resultSize ? resultSize : endIndex
            }}
            of {{ resultSize }}
        </div>
    </div>

</div>