<div class="sidebar" [ngClass]="{ expanded: isExpanded }">
    <div *ngIf="isExpanded" (click)="handleSidebarToggle()" class="sidebar-backdrop"></div>
    <div class="header">
        <h1 class="title" style="height: 50px">
            <img
                src="../../../assets/images/logo/firsty-logo.svg"
                width="30"
                height="30"
                style="margin-top: 10px; color: white !important;"
                class="d-inline-block align-top"
                alt="Firsty"
            />
        </h1>
        <div class="toggle" (click)="handleSidebarToggle()">
            <span class="d-inline-block d-lg-none">
                <span *ngIf="!isExpanded" class="d-inline-block bg-dark-blue-transparent px-2 br-0-5rem">
                    <i class="bi bi-list mobile-menu-toggle"></i>
                </span>

                <span *ngIf="isExpanded">
                    <i class="bi bi-chevron-left"></i>
                </span>
            </span>

            <span class="chevron d-none d-lg-inline-block"></span>
        </div>
    </div>
    <div class="content">
        <div class="link">
            <div
                class="content"
                [routerLink]="['/home']"
                [routerLinkActive]="'active'"
            >
                <div class="icon">
                    <i class="bi bi-house-door-fill"></i>
                </div>
                <div class="title">Home</div>
            </div>
        </div>
        <div class="link" *ngIf="userRole === 'admin' || userRole === 'bizwhiz' || userRole === 'patron'">
            <div
                class="content"
                [routerLink]="['/dashboard']"
                [routerLinkActive]="'active'"
            >
                <div class="icon">
                    <i class="bi bi-graph-up"></i>
                </div>
                <div class="title">Dashboard</div>
            </div>
        </div>
        <div class="link" *ngIf="userRole === 'admin' || userRole === 'bizwhiz' || userRole === 'patron'">
            <div
                class="content"
                [routerLink]="['/development']"
                [routerLinkActive]="'active'"
            >
                <div class="icon">
                    <i class="bi bi-code-slash"></i>
                </div>
                <div class="title">Development</div>
            </div>
        </div>
        <div class="link" *ngIf="userRole === 'admin' || userRole === 'angel' || userRole === 'bizwhiz'">
            <div
                class="content"
                [routerLink]="['/users']"
                [routerLinkActive]="'active'"
            >
                <div class="icon">
                    <i class="bi bi-people-fill"></i>
                </div>
                <div class="title">Users</div>
            </div>
        </div>
        <div class="link with-children" *ngIf="userRole === 'admin' || userRole === 'bizwhiz'">
            <div
                class="content"
                [routerLinkActive]="'active'"
            >
                <div class="icon">
                    <i class="bi bi-gear-fill"></i>
                </div>
                <div class="title">Management</div>
            </div>
            <div class="children">
                <div class="link">
                    <div
                        class="content"
                        [routerLink]="['/admin/partners']"
                        [routerLinkActive]="'active'"
                    >
                        <div class="icon">
                            <i class="bi bi-briefcase-fill"></i>
                        </div>
                        <div class="title">Partners</div>
                    </div>
                </div>
                <div class="link">
                    <div
                        class="content"
                        [routerLink]="['/admin/promo-codes']"
                        [routerLinkActive]="'active'"
                    >
                        <div class="icon">
                            <i class="bi bi-ticket-perforated-fill"></i> 
                        </div>
                        <div class="title">Promo Codes</div>
                    </div>
                </div>
                <div class="link" *ngIf="userRole === 'admin'">
                    <div
                        class="content"
                        [routerLink]="['/admin/esim-management']"
                        [routerLinkActive]="'active'"
                    >
                        <div class="icon">
                            <i class="bi bi-sim-fill"></i>
                        </div>
                        <div class="title">eSIMs</div>
                    </div>
                </div>
                <div class="link" *ngIf="userRole === 'admin'">
                    <div
                        class="content"
                        [routerLink]="['/admin/news']"
                        [routerLinkActive]="'active'"
                    >
                        <div class="icon">
                            <i class="bi bi-newspaper"></i>
                        </div>
                        <div class="title">News</div>
                    </div>
                </div>
                <div class="link" *ngIf="userRole === 'admin'">
                    <div
                        class="content"
                        [routerLink]="['/admin/notifications']"
                        [routerLinkActive]="'active'"
                    >
                        <div class="icon">
                            <i class="bi bi-bell"></i>
                        </div>
                        <div class="title">Notifications</div>
                    </div>
                </div>
                <div class="link">
                    <div
                        class="content"
                        [routerLink]="['/admin/blocked-users']"
                        [routerLinkActive]="'active'"
                    >
                        <div class="icon">
                            <i class="bi bi-person-fill-slash"></i>
                        </div>
                        <div class="title">Blocks</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="link with-children" *ngIf="userRole === 'admin'">
            <div
                class="content"
                [routerLinkActive]="'active'"
            >
                <div class="icon">
                    <i class="bi bi-bug-fill"></i>
                </div>
                <div class="title">Error Tracker</div>
            </div>
            <div class="children">
                <div class="link">
                    <div
                    class="content"
                    [routerLink]="['/errors/rollovers']"
                    [routerLinkActive]="'active'"
                    >
                        <div class="icon">
                            <i class="bi bi-arrow-clockwise"></i>
                        </div>
                        <div class="title">Rollovers</div>
                    </div>
                </div>
                <div class="link">
                    <div
                    class="content"
                    [routerLink]="['/errors/addons']"
                    [routerLinkActive]="'active'"
                    >
                        <div class="icon">
                            <i class="bi bi-reception-4"></i>
                        </div>
                        <div class="title">AddOns</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="link with-children">
            <div
                class="content"
                [routerLinkActive]="'active'"
            >
                <div class="icon">
                    <i class="bi bi-hand-thumbs-up"></i>
                </div>
                <div class="title">Partner</div>
            </div>
            <div class="children">
                <div class="link" *ngIf="userRole === 'admin' || userRole === 'angel' || userRole === 'bizwhiz' || userRole === 'partner'">
                    <div
                        class="content"
                        [routerLink]="['/users/partner']"
                        [routerLinkActive]="'active'"
                    >
                        <div class="icon">
                            <i class="bi bi-people-fill"></i>
                        </div>
                        <div class="title">Partner Users</div>
                    </div>
                </div>
                <div class="link" *ngIf="userRole === 'admin' || userRole === 'partner'">
                    <div
                        class="content"
                        [routerLink]="['/partner-company-dashboard']"
                        [routerLinkActive]="'active'"
                    >
                        <div class="icon">
                            <i class="bi bi-graph-up"></i>
                        </div>
                        <div class="title">Partner Dashboard</div>
                    </div>
                </div>
                <div class="link" *ngIf="userRole === 'admin' || userRole === 'partner'">
                    <div
                        class="content"
                        [routerLink]="['/invoices']"
                        [routerLinkActive]="'active'"
                    >
                        <div class="icon">
                            <i class="bi bi-receipt"></i>
                        </div>
                        <div class="title">Invoices</div>
                    </div>
                </div>
                <div class="link" *ngIf="userRole === 'admin' || userRole === 'partner'">
                    <div
                        class="content"
                        [routerLink]="['/price-list']"
                        [routerLinkActive]="'active'"
                    >
                        <div class="icon">
                            <i class="bi bi-tags"></i>
                        </div>
                        <div class="title">Price list</div>
                    </div>
                </div>
                <div class="link" *ngIf="userRole === 'admin' || userRole === 'partner'">
                    <div
                        class="content"
                        [routerLink]="['/employee-data-consumption']"
                        [routerLinkActive]="'active'"
                    >
                        <div class="icon">
                            <i class="bi bi-bar-chart-fill"></i>
                        </div>
                        <div class="title">Employee consumption</div>
                    </div>
                </div>
                <div class="link" *ngIf="userRole === 'admin' || userRole === 'partner'">
                    <div
                        class="content"
                        [routerLink]="['/partner/api']"
                        [routerLinkActive]="'active'"
                    >
                        <div class="icon">
                            <i class="bi bi-luggage-fill"></i>
                        </div>
                        <div class="title">Partners API</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer"></div>
</div>
