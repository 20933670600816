export const DATA_MONTHLY_USAGE = [
  {
    "date": "2025-02-01T00:00:00.000Z",
    "usage": [
      {
        "country": "US",
        "usageAmount": 75450,
        "activeUsers": 102
      },
      {
        "country": "DE",
        "usageAmount": 57320,
        "activeUsers": 233
      },
      {
        "country": "other",
        "usageAmount": 754500,
        "activeUsers": 64
      }
    ]
  },
  {
    "date": "2025-02-02T00:00:00.000Z",
    "usage": [
      {
        "country": "US",
        "usageAmount": 78760,
        "activeUsers": 175
      },
      {
        "country": "UK",
        "usageAmount": 62140,
        "activeUsers": 474
      },
      {
        "country": "other",
        "usageAmount": 787600,
        "activeUsers": 249
      }
    ]
  },
  {
    "date": "2025-02-03T00:00:00.000Z",
    "usage": [
      {
        "country": "US",
        "usageAmount": 81250,
        "activeUsers": 138
      },
      {
        "country": "ES",
        "usageAmount": 66430,
        "activeUsers": 33
      },
      {
        "country": "other",
        "usageAmount": 812500,
        "activeUsers": 164
      }
    ]
  },
  {
    "date": "2025-02-04T00:00:00.000Z",
    "usage": [
      {
        "country": "DE",
        "usageAmount": 65460,
        "activeUsers": 201
      },
      {
        "country": "FR",
        "usageAmount": 53240,
        "activeUsers": 112
      },
      {
        "country": "other",
        "usageAmount": 654600,
        "activeUsers": 237
      }
    ]
  },
  {
    "date": "2025-02-05T00:00:00.000Z",
    "usage": [
      {
        "country": "US",
        "usageAmount": 72380,
        "activeUsers": 284
      },
      {
        "country": "UK",
        "usageAmount": 59570,
        "activeUsers": 123
      },
      {
        "country": "other",
        "usageAmount": 723800,
        "activeUsers": 147
      }
    ]
  },
  {
    "date": "2025-02-06T00:00:00.000Z",
    "usage": [
      {
        "country": "IT",
        "usageAmount": 68630,
        "activeUsers": 121
      },
      {
        "country": "ES",
        "usageAmount": 54920,
        "activeUsers": 57
      },
      {
        "country": "other",
        "usageAmount": 686300,
        "activeUsers": 171
      }
    ]
  },
  {
    "date": "2025-02-07T00:00:00.000Z",
    "usage": [
      {
        "country": "US",
        "usageAmount": 76780,
        "activeUsers": 254
      },
      {
        "country": "DE",
        "usageAmount": 61340,
        "activeUsers": 87
      },
      {
        "country": "other",
        "usageAmount": 767800,
        "activeUsers": 269
      }
    ]
  },
  {
    "date": "2025-02-08T00:00:00.000Z",
    "usage": [
      {
        "country": "FR",
        "usageAmount": 64230,
        "activeUsers": 223
      },
      {
        "country": "UK",
        "usageAmount": 59650,
        "activeUsers": 114
      },
      {
        "country": "other",
        "usageAmount": 642300,
        "activeUsers": 130
      }
    ]
  },
  {
    "date": "2025-02-09T00:00:00.000Z",
    "usage": [
      {
        "country": "US",
        "usageAmount": 75670,
        "activeUsers": 33
      },
      {
        "country": "IT",
        "usageAmount": 62480,
        "activeUsers": 132
      },
      {
        "country": "other",
        "usageAmount": 756700,
        "activeUsers": 308
      }
    ]
  },
  {
    "date": "2025-02-10T00:00:00.000Z",
    "usage": [
      {
        "country": "DE",
        "usageAmount": 67840,
        "activeUsers": 346
      },
      {
        "country": "ES",
        "usageAmount": 58530,
        "activeUsers": 146
      },
      {
        "country": "other",
        "usageAmount": 678400,
        "activeUsers": 187
      }
    ]
  },
  {
    "date": "2025-02-11T00:00:00.000Z",
    "usage": [
      {
        "country": "US",
        "usageAmount": 79240,
        "activeUsers": 242
      },
      {
        "country": "UK",
        "usageAmount": 65670,
        "activeUsers": 464
      },
      {
        "country": "other",
        "usageAmount": 792400,
        "activeUsers": 491
      }
    ]
  },
  {
    "date": "2025-02-12T00:00:00.000Z",
    "usage": [
      {
        "country": "FR",
        "usageAmount": 62140,
        "activeUsers": 250
      },
      {
        "country": "NL",
        "usageAmount": 57930,
        "activeUsers": 444
      },
      {
        "country": "other",
        "usageAmount": 621400,
        "activeUsers": 68
      }
    ]
  },
  {
    "date": "2025-02-13T00:00:00.000Z",
    "usage": [
      {
        "country": "US",
        "usageAmount": 75780,
        "activeUsers": 194
      },
      {
        "country": "IT",
        "usageAmount": 65340,
        "activeUsers": 59
      },
      {
        "country": "other",
        "usageAmount": 757800,
        "activeUsers": 325
      }
    ]
  },
  {
    "date": "2025-02-14T00:00:00.000Z",
    "usage": [
      {
        "country": "DE",
        "usageAmount": 63670,
        "activeUsers": 425
      },
      {
        "country": "ES",
        "usageAmount": 51240,
        "activeUsers": 411
      },
      {
        "country": "other",
        "usageAmount": 636700,
        "activeUsers": 499
      }
    ]
  },
  {
    "date": "2025-02-15T00:00:00.000Z",
    "usage": [
      {
        "country": "US",
        "usageAmount": 78750,
        "activeUsers": 383
      },
      {
        "country": "UK",
        "usageAmount": 68430,
        "activeUsers": 181
      },
      {
        "country": "other",
        "usageAmount": 787500,
        "activeUsers": 51
      }
    ]
  },
  {
    "date": "2025-02-16T00:00:00.000Z",
    "usage": [
      {
        "country": "FR",
        "usageAmount": 66970,
        "activeUsers": 35
      },
      {
        "country": "IT",
        "usageAmount": 59850,
        "activeUsers": 404
      },
      {
        "country": "other",
        "usageAmount": 669700,
        "activeUsers": 244
      }
    ]
  },
  {
    "date": "2025-02-17T00:00:00.000Z",
    "usage": [
      {
        "country": "US",
        "usageAmount": 73280,
        "activeUsers": 183
      },
      {
        "country": "DE",
        "usageAmount": 64520,
        "activeUsers": 66
      },
      {
        "country": "other",
        "usageAmount": 732800,
        "activeUsers": 292
      }
    ]
  },
  {
    "date": "2025-02-18T00:00:00.000Z",
    "usage": [
      {
        "country": "ES",
        "usageAmount": 53840,
        "activeUsers": 77
      },
      {
        "country": "UK",
        "usageAmount": 61430,
        "activeUsers": 246
      },
      {
        "country": "other",
        "usageAmount": 614300,
        "activeUsers": 246
      }
    ]
  },
  {
    "date": "2025-02-19T00:00:00.000Z",
    "usage": [
      {
        "country": "US",
        "usageAmount": 71920,
        "activeUsers": 433
      },
      {
        "country": "FR",
        "usageAmount": 57650,
        "activeUsers": 331
      },
      {
        "country": "other",
        "usageAmount": 719200,
        "activeUsers": 440
      }
    ]
  },
  {
    "date": "2025-02-20T00:00:00.000Z",
    "usage": [
      {
        "country": "IT",
        "usageAmount": 56280,
        "activeUsers": 487
      },
      {
        "country": "NL",
        "usageAmount": 54730,
        "activeUsers": 319
      },
      {
        "country": "other",
        "usageAmount": 562800,
        "activeUsers": 355
      }
    ]
  },
  {
    "date": "2025-02-21T00:00:00.000Z",
    "usage": [
      {
        "country": "US",
        "usageAmount": 78450,
        "activeUsers": 36
      },
      {
        "country": "DE",
        "usageAmount": 66380,
        "activeUsers": 282
      },
      {
        "country": "other",
        "usageAmount": 784500,
        "activeUsers": 344
      }
    ]
  },
  {
    "date": "2025-02-22T00:00:00.000Z",
    "usage": [
      {
        "country": "UK",
        "usageAmount": 64250,
        "activeUsers": 41
      },
      {
        "country": "ES",
        "usageAmount": 52580,
        "activeUsers": 228
      },
      {
        "country": "other",
        "usageAmount": 642500,
        "activeUsers": 80
      }
    ]
  },
  {
    "date": "2025-02-23T00:00:00.000Z",
    "usage": [
      {
        "country": "US",
        "usageAmount": 76830,
        "activeUsers": 245
      },
      {
        "country": "FR",
        "usageAmount": 65740,
        "activeUsers": 74
      },
      {
        "country": "other",
        "usageAmount": 768300,
        "activeUsers": 226
      }
    ]
  },
  {
    "date": "2025-02-24T00:00:00.000Z",
    "usage": [
      {
        "country": "IT",
        "usageAmount": 54960,
        "activeUsers": 348
      },
      {
        "country": "DE",
        "usageAmount": 62570,
        "activeUsers": 166
      },
      {
        "country": "other",
        "usageAmount": 625700,
        "activeUsers": 161
      }
    ]
  },
  {
    "date": "2025-02-25T00:00:00.000Z",
    "usage": [
      {
        "country": "US",
        "usageAmount": 73760,
        "activeUsers": 388
      },
      {
        "country": "UK",
        "usageAmount": 63290,
        "activeUsers": 474
      },
      {
        "country": "other",
        "usageAmount": 737600,
        "activeUsers": 358
      }
    ]
  },
  {
    "date": "2025-02-26T00:00:00.000Z",
    "usage": [
      {
        "country": "ES",
        "usageAmount": 58470,
        "activeUsers": 464
      },
      {
        "country": "NL",
        "usageAmount": 56380,
        "activeUsers": 269
      },
      {
        "country": "other",
        "usageAmount": 584700,
        "activeUsers": 274
      }
    ]
  },
  {
    "date": "2025-02-27T00:00:00.000Z",
    "usage": [
      {
        "country": "US",
        "usageAmount": 74590,
        "activeUsers": 308
      },
      {
        "country": "FR",
        "usageAmount": 61920,
        "activeUsers": 220
      },
      {
        "country": "other",
        "usageAmount": 745900,
        "activeUsers": 200
      }
    ]
  },
  {
    "date": "2025-02-28T00:00:00.000Z",
    "usage": [
      {
        "country": "DE",
        "usageAmount": 65830,
        "activeUsers": 429
      },
      {
        "country": "IT",
        "usageAmount": 53640,
        "activeUsers": 443
      },
      {
        "country": "other",
        "usageAmount": 658300,
        "activeUsers": 373
      }
    ]
  }
];