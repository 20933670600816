<div class="container">
    <div *ngIf="(firebaseAuthService.role | async) === 'partner'">
        <h1>{{partnerSelected?.name}}</h1>
    </div>

    <div *ngIf="(firebaseAuthService.role | async) !== 'partner'">
        <h1>Partner Company Dashboard</h1>
    </div>


    <div *ngIf="(firebaseAuthService.role | async) === 'admin'" class="d-inline-flex align-items-center">
        <label for="select-partner" class="form-label h2 pe-3">Partner</label>
        <select id="select-partner" (change)="partnerChanged($event)" class="form-select" aria-label="Select partner">
            <option disabled selected>Select partner</option>
            <option *ngFor="let partner of (partners$ | async)" [value]="partner.partnerId">{{partner.name}}</option>
        </select>
    </div>

    <ngb-accordion class="filter-accordion my-3 d-block" #a="ngbAccordion">
        <ngb-panel id="custom-panel-1">
            <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-inline-flex accordion-button custom-header justify-content-between p-0 z-0" [class.collapsed]="!opened">
                    <button ngbPanelToggle class="btn btn-link p-0 text-white">
                        <i class="bi bi-funnel-fill"></i>
                        <span class="ps-2" *ngIf="opened">Hide filters</span>
                        <span class="ps-2" *ngIf="!opened">Show filters</span>
                    </button>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <div class="py-3 pb-2 bg-dark-blue-transparent br-0-5rem shadow mt-2 container-fluid">
                    <div class="row">
                        <div class="col-12 input-group mb-3">
                            <div class="input-group-text me-3">
                                <input type="radio" (change)="setFilterOption($event)" [ngModel]="filterOptionSelected" value="ICCID" id="search-by-iccid" name="filter-options">
                                <label class="ps-2" for="search-by-iccid">Search by ICCID</label>
                            </div>
                            <div class="input-group-text me-3">
                                <input type="radio" (change)="setFilterOption($event)" [ngModel]="filterOptionSelected" value="EMAIL" id="search-by-email" name="filter-options">
                                <label class="ps-2" for="search-by-email">Search by e-mail</label>
                            </div>
                            <div class="input-group-text me-3">
                                <input type="radio" (change)="setFilterOption($event)" [ngModel]="filterOptionSelected" value="COUNTRY_AND_DATE_RANGE" id="search-by-country-and-dates" name="filter-options">
                                <label class="ps-2" for="search-by-country-and-dates">Search by country and date range</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <ng-container *ngIf="filterOptionSelected === 'COUNTRY_AND_DATE_RANGE'">
                            <div class="col-md-3 col-12 mb-2">
                                <div class="input-group">
                                    <span class="input-group-text">Country</span>
                                    <select (change)="countryChanged($event)" [ngModel]="(selectedCountry$ | async)?.isoCountryCode" class="form-control" id="countries" name="countries">
                                        <option *ngFor="let country of countryList" [value]="country.isoCountryCode">{{ country.name }}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-3 col-12 mb-2">
                                <app-date-range-picker (dateRangeSelection)="onDateRangeSelection($event)"></app-date-range-picker>
                            </div>
                        </ng-container>
                        
                        
                        <div *ngIf="filterOptionSelected === 'ICCID'" class="col-md-3 col-12 mb-2">
                            <div class="input-group">
                                <span class="input-group-text">ICCID</span>
                                <input 
                                  class="form-control" 
                                  name="ICCID"
                                  id="ICCID"
                                  autocomplete="ICCID"
                                />
                            </div>
                        </div>

                        <div *ngIf="filterOptionSelected === 'EMAIL'" class="col-md-3 col-12 mb-2">
                            <div class="input-group">
                                <span class="input-group-text">E-mail</span>
                                <input 
                                  class="form-control" 
                                  name="email"
                                  id="email"
                                  autocomplete="email"
                                />
                            </div>
                        </div>
    
                        <div class="col-md-3 col-12">
                            <button
                                type="button"
                                class="btn btn-info"
                            >
                                <i class="bi bi-search"></i>
                                <span class="ms-2">Search</span>
                            </button>
                        </div>
                    </div>
                    
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>

    <div class="row mt-4">
        <div *ngFor="let usageCountItem of usageCountValuesAndLabels; let i = index" class="col-12 col-sm-6 col-md-3 mb-4">
            <div class="bg-dark-blue-transparent shadow stat-box br-0-5rem">
                <div class="text-white p-3 top-line-{{i+1}}">
                    <div class="box-value">
                        <app-animated-number [to]="usageCountItem.value"></app-animated-number>
                    </div>
                    <div>{{usageCountItem.name}}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mb-4">
        <div class="col">
            <div #chartbarVerticalStackedContainer class="bg-dark-blue-transparent br-0-5rem p-3 shadow" [style.height]="390+'px'">
                <div class="d-flex justify-content-between">
                    <h2 class="fw-bold pb-3 text-white">Active users</h2>

                    <div *ngIf="(activeUsersVerticalChartData$ | async) as activeUsersVerticalChartData">
                        <span *ngFor="let option of periodicityAggregationOptions"
                            role="button"
                            (click)="setActiveUsersPeriodicity(option.value)"
                            class="period-aggregation-filter mx-2"
                            [class.active]="option.value === (activeUsersPeriodicity$ | async)"
                        >{{option.label}}</span>
                    </div>
                </div>
               
                <ngx-charts-bar-vertical-stacked
                    [view]="[chartbarVerticalStackedContainer.clientWidth - 40, 300]"
                    [scheme]="colorScheme"
                    [results]="activeUsersVerticalChartData$ | async"
                    [gradient]="true"
                    [xAxis]="true"
                    [yAxis]="true"
                    [legend]="true"
                    [showXAxisLabel]="false"
                    [showYAxisLabel]="false"
                    [xAxisLabel]="'Date'"
                    [yAxisLabel]="'Data Usage'"
                    [animations]="true"
                    [yAxisTickFormatting]="activeUsersYAxisTickFormatting"
                    (select)="onSelect($event)">
                </ngx-charts-bar-vertical-stacked>
            </div>
        </div>
    </div>

    <div class="row mb-4">
        <div class="col">
            <div #chartLineContainer class="bg-dark-blue-transparent br-0-5rem p-3 shadow" [style.height]="390+'px'">
                <div class="d-flex justify-content-between">
                    <h2 class="fw-bold pb-3 text-white">Total cost per country</h2>
                    <div *ngIf="(totalDailyUsageChartData$ | async) as activeUsersVerticalChartData">
                        <span *ngFor="let option of periodicityAggregationOptions"
                            role="button"
                            (click)="setTotalCostPerCountryPeriodicity(option.value)"
                            class="period-aggregation-filter mx-2"
                            [class.active]="option.value === (totalCostPerCountryPeriodicity$ | async)"
                        >{{option.label}}</span>
                    </div>
                </div>

                <ngx-charts-line-chart
                    [view]="[chartLineContainer.clientWidth - 40, 300]"
                    [scheme]="colorScheme"
                    [legend]="true"
                    [showXAxisLabel]="false"
                    [showYAxisLabel]="false"
                    [animations]="true"
                    [xAxis]="true"
                    [yAxis]="true"
                    [showGridLines]="true"
                    [roundDomains]="false"
                    [gradient]="true"
                    [xAxisLabel]="'Date'"
                    [yAxisLabel]="'Data Usage'"
                    [timeline]="false"
                    [results]="totalDailyUsageChartData$ | async"
                    [curve]="curve"
                    [yAxisTickFormatting]="totalCostYAxisTickFormatting"
                    (select)="onSelect($event)"
                    (activate)="onActivate($event)"
                    (deactivate)="onDeactivate($event)"
                ></ngx-charts-line-chart>
            </div>
        </div>
    </div>

</div>