<div class="container pb-3">
    <h1>Invoices</h1>

    <div class="bg-dark-blue-transparent br-0-5rem p-3 shadow d-flex justify-content-between align-items-center mb-4">
        <div class="input-group w-auto">
            <span class="input-group-text">
                <i class="bi bi-search"></i>
            </span>
            <input 
                type="text"
                class="form-control" 
                name="invoice-search"
                id="invoice-search"
                autocomplete="invoice-search"
                (input)="searchTermInput($event)"
            />
        </div>

        <div>
            <button 
                type="button" 
                class="btn btn-primary mx-3"
                (click)="loadInvoices()"
            >
                <i class="bi bi-arrow-clockwise"></i>
                <span class="ms-2">Refresh</span>
            </button>
        </div>
    </div>

    <div class="d-lg-none">
        <div *ngFor="let invoice of (filteredInvoicesList$ | async)" class="br-0-5rem shadow mb-4 text-white bg-dark-blue-transparent p-3 d-flex">
            <div class="d-flex align-items-center me-3">
                <i style="font-size: 3rem;" class="bi bi-receipt"></i>
            </div>
            
            <div class="flex-grow-1">
                <div class="d-flex justify-content-between align-items-center">
                    <span class="fw-bold">{{invoice.grandTotal | number:'1.2-2'}}</span>
    
                    <span [class.paid-invoice]="invoice.status === 'Paid'"
                        [class.unpaid-invoice]="invoice.status === 'Unpaid'"
                    >{{invoice.status}}</span>
                </div>
                <div>{{invoice.invoiceNumber}}</div>
                
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        <span class="me-2">
                            <i class="bi bi-clock-history"></i>
                        </span>
                        
                        <span>{{invoice.dueDate}}</span>
                    </div>
                    
                    <div>
                        <button class="btn btn-info btn-sm" type="button" ngbTooltip="Download invoice" (click)="downloadInvoice($event, invoice)">
                            <i class="bi bi-download"></i>
                        </button>
    
                        <button *ngIf="(userRole$ | async) === 'admin'" class="btn btn-info btn-sm ms-2" type="button"
                            [ngbPopover]="invoiceItemMenu"
                            triggers="manual"
                            placement="start"
                            popoverClass="popover-no-padding"
                            #p="ngbPopover"
                            (click)="openInvoiceOptions(p, invoice)"
                        >
                            <i class="bi bi-three-dots"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="d-none d-lg-block br-0-5rem shadow mb-4">
        <table class="table table-striped rounded-table">
            <thead>
                <tr>
                    <th *ngFor="let column of columns">
                        <div role="button" (click)="sort(column.name)">
                            <span class="user-select-none">{{column.label}}</span>
                            <span class="sorting-order-indicator">
                                <ng-container *ngIf="column.name === (sortingColumn$ | async)">
                                    <span class="ps-2" *ngIf="sortingOrder === 'asc'">
                                        <i class="bi bi-caret-up-fill"></i>
                                    </span>
                                    <span class="ps-2" *ngIf="sortingOrder === 'desc'">
                                        <i class="bi bi-caret-down-fill"></i>
                                    </span>
                                </ng-container>
                            </span>
                        </div>
                    </th>
                    <th>
                        <div class="user-select-none">Actions</div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let invoice of (filteredInvoicesList$ | async)">
                    <td *ngFor="let column of columns">
                        <div class="d-flex" [ngSwitch]="column.name">
                            <span *ngSwitchCase="'status'"
                                [class.paid-invoice]="invoice[column.name] === 'Paid'"
                                [class.unpaid-invoice]="invoice[column.name] === 'Unpaid'"
                            >
                                {{invoice[column.name]}}
                            </span>
                            <span *ngSwitchCase="'grandTotal'">
                                {{invoice[column.name] | number:'1.2-2'}}
                            </span>
                            
                            <span *ngSwitchDefault>{{invoice[column.name]}}</span>
                        </div>
                    </td>
                    <td>
                        <button class="btn btn-info btn-sm" type="button" ngbTooltip="Download invoice" (click)="downloadInvoice($event, invoice)">
                            <i class="bi bi-download"></i>
                        </button>

                        <button *ngIf="(userRole$ | async) === 'admin'" class="btn btn-info btn-sm ms-2" type="button"
                            [ngbPopover]="invoiceItemMenu"
                            triggers="manual"
                            placement="start"
                            popoverClass="popover-no-padding"
                            #p="ngbPopover"
                            (click)="openInvoiceOptions(p, invoice)"
                        >
                            <i class="bi bi-three-dots"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="bg-dark-blue-transparent br-0-5rem p-3 shadow d-lg-flex justify-content-between align-items-center">
        <div class="text-white opacity-75 py-1 text-center">
            {{ itemsPerPage }} items per page
        </div>

        <div class="d-flex justify-content-center align-items-center">
            <ngb-pagination
                [collectionSize]="resultSize"
                [page]="currentPage$ | async"
                [pageSize]="itemsPerPage"
                (pageChange)="pageChange($event)"
                [maxSize]="3"
                [rotate]="true"
                [boundaryLinks]="false"
                size="sm"
                class="mb-0 pagination-dark"
                (pageChange)="pageChange($event)"
            ></ngb-pagination>
        </div>

        <div class="text-white opacity-75 py-1 text-center">
            Showing {{ startIndex + 1 }}-{{
                endIndex > resultSize ? resultSize : endIndex
            }}
            of {{ resultSize }}
        </div>
    </div>

</div>

<ng-template #invoiceItemMenu let-invoice="invoice">
    <div class="list-group">
        <div *ngIf="invoice.status === 'Paid'" (click)="askForMarkingAsUnpaid(invoice)" class="list-group-item list-group-item-action" role="button">Mark as unpaid</div>
        <div *ngIf="invoice.status === 'Unpaid'" (click)="askForMarkingAsPaid(invoice)" class="list-group-item list-group-item-action" role="button">Mark as paid</div>
    </div>
</ng-template>