<ng-container *ngIf="!router.url.includes('token')">
    <ngb-toast
        *ngFor="let toast of toasts"
        [class.ngb-toasts]="true"
        [class.text-light]="toast.type !== ''"
        [class.bg-success]="toast.type === 'success'"
        [class.bg-danger]="toast.type === 'danger'"
        [class.bg-warning]="toast.type === 'warning'"
        [autohide]="true"
        [delay]="toast.delay"
        [header]="toast.header"
        (hidden)="removeToasts(toast)"
        aria-live="polite"
        aria-atomic="true"
    >
        {{ toast.message }}
    </ngb-toast>

    <app-firsty-loader [isLoading]="!!requestTrackerService.requestCount"></app-firsty-loader>
    
    <div class="d-flex">
        <app-sidebar
            *ngIf="isLoggedIn"
            [isExpanded]="sidebarExpanded"
            (toggleSidebar)="sidebarExpanded = !sidebarExpanded"
        ></app-sidebar>
        
        <div class="flex-grow-1 vh-100 overflow-auto">
            <nav class="navbar navbar-dark backgrounded">
                <!-- <a class="navbar-brand" href="/">
                    <img
                        src="../assets/images/logo/firsty-logo.svg"
                        width="30"
                        height="30"
                        class="d-inline-block align-top"
                        alt="firsty"
                        style="margin-left: 20px"
                    />
                </a> -->
                <ul ngbNav [(activeId)]="active" class="nav-pills"></ul>
                <ul ngbNav class="nav-pills" style="margin-right: 20px">
                    <li>
                        <!-- <span
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover focus"
                        data-bs-custom-class="button-tooltip"
                        data-bs-placement="top"
                        data-bs-title="Claim your admin rights"
                    >
                        <button
                            type="button"
                            class="btn btn-outline-secondary text-light"
                            *ngIf="!isAdmin && isLoggedIn"
                            (click)="claimAdmin()"
                        >
                            <i class="bi bi-person-badge-fill"></i>
                        </button>
                        &nbsp;&nbsp;
                    </span> -->
                        <span
                            *ngIf="isAdmin && isLoggedIn"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover focus"
                            data-bs-custom-class="button-tooltip"
                            data-bs-placement="bottom"
                            data-bs-title="Copy your Access Token for testing"
                        >
                            <button
                                copyToClipboard
                                [textToCopy]="token"
                                type="button"
                                class="btn btn-outline-secondary text-light"
                            >
                                <i class="bi bi-clipboard2-plus-fill"></i>
                            </button>
                            &nbsp;&nbsp;
                        </span>
                        <div *ngIf="!isLoggedIn" class="fixed-top left-0 vh-100 vw-100 bg-dark-blue-transparent d-flex justify-content-center align-items-center blur-backdrop">
                            <div class="pulse admin-login-btn mx-4">
                                <span style="--i:0;"></span>
                                <span style="--i:1;"></span>
                                <button (click)="loginWithGoogle()" class="login-btn">Authenticate Admin</button>
                            </div>

                            <div class="pulse mx-4">
                                <span style="--i:0;"></span>
                                <span style="--i:1;"></span>
                                <button (click)="showPartnerAuthenticationForm = true" class="login-btn">Authenticate Partner</button>
                            </div>
                        </div>
                        <button
                            *ngIf="isLoggedIn"
                            type="button"
                            class="btn btn-outline-secondary text-light"
                            (click)="logout()"
                        >
                            Logout
                        </button>

                        <div [class.is-visible]="!isLoggedIn && showPartnerAuthenticationForm" class="bg-dark-blue-transparent blur-backdrop partner-forms-container">
                            <div class="login-and-registration-forms-container" [class.registration-visible]="showPartnerRegistrationForm">
                                <app-partner-login-form #loginForm class="d-inline-block w-100"
                                    (formSubmit)="loginWithEmailAndPassword($event['email'], $event['password'], loginForm)"
                                    (goToSignUp)="showPartnerRegistrationForm = true"
                                    (closeForm)="showPartnerAuthenticationForm = false"
                                    [isVisible]="!showPartnerRegistrationForm"
                                ></app-partner-login-form>
                                
                                <app-partner-registration-form #registrationForm  class="d-inline-block w-100"
                                    (formSubmit)="registerWithEmailAndPassword($event['name'], $event['email'], $event['password'], registrationForm)"
                                    (closeForm)="showPartnerRegistrationForm = false"
                                    [isVisible]="showPartnerRegistrationForm"
                                ></app-partner-registration-form>
                            </div>
                        </div>
                    </li>
                </ul>
            </nav>
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <ngb-alert
                            id="altStatus"
                            type="info"
                            *ngIf="!!status"
                            [dismissible]="false"
                        >
                            <div>{{ status }}</div>
                        </ngb-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="router.url.includes('token')">
    <router-outlet></router-outlet>
</ng-container>
