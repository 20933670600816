import { Injectable } from "@angular/core";
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from "@angular/common/http";
import { BehaviorSubject, from, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { FirebaseAuthService } from "src/app/core/services/firebase-auth.service";
import { AuthError } from "src/app/core/enums/auth-error";

@Injectable()
export class SessionTokenValidityInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    public constructor(
        private _firebaseAuthService: FirebaseAuthService
    ) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.error.code === AuthError.INVALID_TOKEN) {
                    return this._handleInvalidToken(req, next);
                }
                return throwError(() => error);
            })
        );
    }

    private _handleInvalidToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return from(this._firebaseAuthService.refreshToken()).pipe(
                switchMap((newToken: string) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(newToken);
                    return next.handle(this._addToken(request, newToken));
                }),
                catchError((error) => {
                    this.isRefreshing = false;
                    return throwError(() => error);
                })
            );
        } else {
            return this.refreshTokenSubject.pipe(
                filter((token) => token !== null),
                take(1),
                switchMap((token) => next.handle(this._addToken(request, token!)))
            );
        }
    }

    private _addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: { authorization: `Bearer ${token}` },
        });
    }
}
