import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';

import { PriceListRoutingModule } from './price-list-routing.module';
import { PriceListComponent } from './price-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DeepObjectValuePipe } from 'src/app/core/pipes/deep-object-value.pipe';


@NgModule({
  declarations: [
    PriceListComponent
  ],
  imports: [
    CommonModule,
    PriceListRoutingModule,
    SharedModule
  ],
  providers: [DeepObjectValuePipe, AsyncPipe]
})
export class PriceListModule { }
