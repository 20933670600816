import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { User } from '@angular/fire/auth';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Country } from 'src/app/core/models/country';
import { Partner } from 'src/app/core/models/partners/partner';
import { DeepObjectValuePipe } from 'src/app/core/pipes/deep-object-value.pipe';
import { ExcelUtilsService } from 'src/app/core/services/excel-utils.service';
import { FirebaseAuthService } from 'src/app/core/services/firebase-auth.service';
import { MessageModalService } from 'src/app/core/services/message-modal.service';
import { PartnersHttpService } from 'src/app/core/services/partners.http.service';
import { MOCK_PRICE_LIST } from 'src/assets/mocks/mockPriceList';

@Component({
  selector: 'app-price-list',
  templateUrl: './price-list.component.html',
  styleUrls: ['./price-list.component.scss']
})
export class PriceListComponent implements OnInit {

  sortingColumn$ = new BehaviorSubject<string>('');
  sortingOrder: string = 'asc';
  currentPage$ = new BehaviorSubject(1);
  itemsPerPage = 10;
  startIndex = 0;
  endIndex = this.itemsPerPage;

  columns = [];

  tieredPricingColumns = [
    { name: 'country.isoCountryCode', label: 'Iso2' },
    { name: 'country.name', label: 'Country' },
    { name: 'tier1', label: 'Tier 1' },
    { name: 'tier2', label: 'Tier 2' },
    { name: 'tier3', label: 'Tier 3' },
    { name: 'tier4', label: 'Tier 4' },
    { name: 'comfort_plus', label: 'Comfort+' },
    { name: 'first_class', label: 'Fisrt Class' },
  ];

  list$ = new BehaviorSubject<PartnerPrices[]>([]);

  resultSize = 0;
  searchTerm$ = new BehaviorSubject('');
  
  private _unsubscribe$: Subject<void> = new Subject<void>();

  public partners$: BehaviorSubject<PartnerPrices[]> = new BehaviorSubject<PartnerPrices[]>(MOCK_PRICE_LIST);
  public partnerSelected$: BehaviorSubject<PartnerPrices> = new BehaviorSubject<PartnerPrices>(null);
  
  filteredList$ = combineLatest([
    this.partnerSelected$,
    this.searchTerm$,
    this.sortingColumn$,
    this.currentPage$
  ]).pipe(
    map(([partner, searchTerm, sortingColumn, currentPage]) => {
      const result = (partner?.prices || [])
      .filter(item =>
        item.country.name?.toLowerCase().includes(searchTerm?.toLowerCase())
        || item.country.isoCountryCode?.toLowerCase().includes(searchTerm?.toLowerCase())
        || item.tier1?.toLowerCase().includes(searchTerm?.toLowerCase())
        || item.tier2?.toLowerCase().includes(searchTerm?.toLowerCase())
        || item.tier3?.toLowerCase().includes(searchTerm?.toLowerCase())
        || item.tier4?.toLowerCase().includes(searchTerm?.toLowerCase())
        || item.comfort_plus?.toLowerCase().includes(searchTerm?.toLowerCase())
        || item.first_class?.toLowerCase().includes(searchTerm?.toLowerCase())
      )
        .sort((a, b) => {
          if (sortingColumn) {
            if (this.sortingOrder === 'asc') {
              return this._deepObjectValuePipe.transform(a, sortingColumn) > this._deepObjectValuePipe.transform(b, sortingColumn) ? 1 : -1;
            } else {
              return this._deepObjectValuePipe.transform(a, sortingColumn) < this._deepObjectValuePipe.transform(b, sortingColumn) ? 1 : -1;
            }
          }
          return 0;
        })

      this.resultSize = result.length;
      this.startIndex = (currentPage - 1) * this.itemsPerPage;
      this.endIndex = currentPage * this.itemsPerPage;

      return result.slice((currentPage - 1) * this.itemsPerPage, currentPage * this.itemsPerPage);
    })
  );

  user: User;
  constructor(
    private readonly _excelUtilsService: ExcelUtilsService,
    private readonly _deepObjectValuePipe: DeepObjectValuePipe,
    private readonly _asyncPipe: AsyncPipe,
    private readonly _partnersHttpService: PartnersHttpService,
    private readonly _messageModalService: MessageModalService,
    public readonly firebaseAuthService: FirebaseAuthService
  ) { }

  

  ngOnInit(): void {
    this.loadPriceList();

    combineLatest([
      this.partners$,
      this.firebaseAuthService.role
    ])
    .pipe(takeUntil(this._unsubscribe$))
    .subscribe(([partners, role]) => {
      if (partners?.length && (role === 'partner')) {
        this.partnerChanged(partners[8].code);
      }
    });
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  searchTermInput(event: Event) {
    this.searchTerm$.next(event.target['value']);
  }

  loadPriceList() {
    this.list$.next(MOCK_PRICE_LIST);
  }

  pageChange(page: number) {
    this.currentPage$.next(page);
  }

  sort(columnName: string) {
    if (this.sortingColumn$.value === columnName) {
      this.sortingOrder = this.sortingOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortingOrder = 'asc';
    }
    this.sortingColumn$.next(columnName);
  }

  exportAsExcel(e: Event) {
    e.preventDefault();
    const columns = this.columns.map((column) => ({ 'key': column.name, 'header': column.label }))
   
    const data = this.partnerSelected$.value?.prices?.map((item) => {
      return columns.map((column) => {
        const value = this._deepObjectValuePipe.transform(item, column.key);
        return ['tier1', 'tier2', 'tier3', 'comfort_plus', 'first_class'].includes(column.key) ? +value : value;
      });
    });

    this._excelUtilsService.exportAsExcel(columns, data, `price_list_${new Date().getTime()}`);
  }

  // getPartners(): void {
  //   console.log('getPartners');
  //   this._partnersHttpService.listPartners().subscribe(
  //     (response) => {
  //       if (response.data?.length > 0) {
  //         this.partners$.next(response.data);
  //       } else {
  //         this._messageModalService.open('warn', 'No partners found');
  //       }
  //     },
  //     () => {
  //       this._messageModalService.open('warn', 'Error fetching partners');
  //     }
  //   );
  // }

  partnerChanged(code: string): void {
    const partnerSelected = this.partners$.value.find((p) => p.code === code);
    if(['DEME'].includes(partnerSelected.code)) {
      this.columns = [...this.tieredPricingColumns.filter(column => !['comfort_plus', 'first_class'].includes(column.name))];
    } else {
      this.columns = [...this.tieredPricingColumns];
    }

    this.partnerSelected$.next(partnerSelected);
  }

}

export type PartnerPrices = Partner & { prices: {
  country: Country;
  tier1?: string;
  tier2?: string;
  tier3?: string;
  tier4?: string;
  comfort_plus?: string;
  first_class?: string;
}[] };
