import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageModalComponent } from '../components/message-modal/message-modal.component';

@Injectable({
  providedIn: 'root'
})
export class MessageModalService {

  constructor(
    private modalService: NgbModal
  ) { }

  open(type: 'info' | 'success' | 'warn' | 'error', message: string, buttons: {btnText: string, action: CallableFunction, btnClass?: string}[] = []) {
    const modalRef = this.modalService.open(MessageModalComponent, {
      centered: true,
      windowClass: 'message-modal'
    });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.buttons = buttons;
    return modalRef;
  }
}
