import { AfterViewInit, Component, ElementRef, Input, OnChanges, Renderer2, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-animated-number',
  templateUrl: './animated-number.component.html',
  styleUrls: ['./animated-number.component.scss']
})
export class AnimatedNumberComponent implements AfterViewInit, OnChanges {
  _from = 0;
  @Input() to!: string | number;
  @Input() duration = 1;

  @ViewChild('valueContainer') valueContainer!: ElementRef;
  constructor(private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(this.valueContainer) {
      if (changes['to']) {
        this._from = changes['to'].previousValue;
        this.animatedNumber();
      }
    }
  }

  ngAfterViewInit(): void {
    if(this.valueContainer) {
      this.animatedNumber();
    }
  }

  animatedNumber() {
    const el = this.valueContainer.nativeElement;
    this.renderer.removeClass(el, 'counter');
    void el.offsetWidth; 
    this.renderer.addClass(el, 'counter');
    this.renderer.setAttribute(el, 'style', `--from: ${this._from}; --to: ${this.to}; --time: ${this.duration}s;`);
  }

}
