

export class FirebaseErrorCodeMapping {
    public static FIREBASE_ERROR_CODE_MAPPING = {
        'auth/invalid-login-credentials': 'Invalid login credentials',
        'auth/email-already-in-use': 'Email already in use',
    };

    public static getMessage(error: any): string {
        if(Object.prototype.hasOwnProperty.call(FirebaseErrorCodeMapping.FIREBASE_ERROR_CODE_MAPPING, error?.code)) {
            return FirebaseErrorCodeMapping.FIREBASE_ERROR_CODE_MAPPING[error.code];
        }
        return error?.message || error;
    }
}