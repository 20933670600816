import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PartnerCompanyDashboardComponent } from './partner-company-dashboard.component';

const routes: Routes = [
  {
    path: '',
    component: PartnerCompanyDashboardComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PartnerCompanyDashboardRoutingModule { }
