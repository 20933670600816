import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'app-partner-registration-form',
  templateUrl: './partner-registration-form.component.html',
  styleUrls: ['./partner-registration-form.component.scss']
})
export class PartnerRegistrationFormComponent implements OnInit {

  @Input() isVisible: boolean = false;
  @Output() formSubmit = new EventEmitter();
  @Output() closeForm = new EventEmitter();
  
  comparePasswords: ValidatorFn = (
    group: AbstractControl
  ): ValidationErrors | null => {
    const password = group.get('password')?.value;
    const passwordConfirm = group.get('confirm_password')?.value;
    return password === passwordConfirm ? null : { notSame: true };
  };

  registrationForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    confirm_password: new FormControl('', [Validators.required, Validators.pattern(/./)]),
  },
  {
    validators: [this.comparePasswords]
  });

  constructor() { }

  ngOnInit(): void {}

  sendData() {
    this.formSubmit.emit(this.registrationForm.value);
  }

  close() {
    this.closeForm.emit();
    this.registrationForm.reset();
  }

  resetForm() {
    this.registrationForm.reset();
  }

}
