import { DatePipe } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {

  @ViewChild('dp') private datePicker: NgbInputDatepicker;
  @Input() from: Date;
  @Input() to: Date;
  @Input() placeholder = 'starting today';
  @Output() dateRangeSelection = new EventEmitter<{ from: Date, to: Date }>();
  hoveredDate: Date;
  isOpen = false;

  @HostListener('document:click', ['$event.target']) onClick(element) {
    const host = document.getElementById('dateRangePicker');
    if (this.datePicker && this.isOpen && !this.isDescendant(host, element)) {
      this.emit(true);
    }
  }

  constructor(private _datePipe: DatePipe) { }

  ngOnInit() {
  }

  private emit(close?: boolean) {
    const dateRange = {
      from: this.from,
      to: this.to,
    };

    this.dateRangeSelection.emit(dateRange);

    if (close) {
      this.isOpen = false;
      this.datePicker.close();
    }
  }

  /**
   * Check whether or not an element is a child of another element
   *
   * @private
   * @param {any} parent
   * @param {any} child
   * @returns if child is a descendant of parent
   * @memberof DateRangeSelectionComponent
   */
  private isDescendant(parent, child) {
    let node = child;
    while (node !== null) {
      if (node === parent) {
        return true;
      } else {
        node = node.parentNode;
      }
    }
    return false;
  }

  get formattedDateRange(): string {
    if (!this.from) {
      // return `missing start date`;
      return `Select dates`;
    }

    const fromFormatted = this._datePipe.transform(this.from, 'YYYY-MM-dd');

    return this.to      
      ? `${fromFormatted}`
      + ` - `
      + `${this._datePipe.transform(this.to, 'YYYY-MM-dd')}`
      : `${fromFormatted}`;

  }

  onDateSelection(date: NgbDateStruct) {
    if (!this.from && !this.to) {
      this.from = this.toDate(date);
    } else if (this.from && !this.to && (this.toDate(date)?.getTime() > this.from.getTime())) {
      this.to = this.toDate(date);
      this.emit(true);
    } else {
      this.to = null;
      this.from = this.toDate(date);
    }
  }

  toDate(dateStruct: NgbDateStruct): Date {
    return dateStruct ? new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day) : null;
  }

}
