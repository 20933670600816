import { Injectable } from '@angular/core';
import {Workbook} from 'exceljs';
import { saveAs } from 'file-saver';
@Injectable({
  providedIn: 'root'
})
export class ExcelUtilsService {

  constructor() { }

  async exportAsExcel(columns: {header: string, key: string, width?: number}[], data: {[key: string]: any}[], exportName: string) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    worksheet.columns = columns.map(column => ({header: column.header, key: column.key, width: column.width || 20}));
    worksheet.addRows(data);

    workbook.xlsx.writeBuffer()
    .then(buffer => saveAs(new Blob([buffer]), `${exportName}.xlsx`))
    .catch(err => console.log('Error writing excel export', err))
  }
}
