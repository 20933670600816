import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-partner-login-form',
  templateUrl: './partner-login-form.component.html',
  styleUrls: ['./partner-login-form.component.scss']
})
export class PartnerLoginFormComponent implements OnInit {

  @Input() isVisible: boolean = false;
  @Output() formSubmit = new EventEmitter();
  @Output() closeForm = new EventEmitter();
  @Output() goToSignUp = new EventEmitter();
  
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.pattern(/./)]),
  });

  constructor() { }

  ngOnInit(): void {
  }

  sendData() {
    this.formSubmit.emit(this.loginForm.value);
  }

  signUp() {
    this.goToSignUp.emit();
  }

  close() {
    this.closeForm.emit();
    this.loginForm.reset();
  }

  resetForm() {
    this.loginForm.reset();
  }

}
