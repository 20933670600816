// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    useEmulators: false,
    firebase: {
        apiKey: "AIzaSyDckgdY2tr3cZQgGkG9_bVWTruaaR8MS6g",
        authDomain: "firsty-test.firebaseapp.com",
        projectId: "firsty-test",
        storageBucket: "firsty-test.appspot.com",
        messagingSenderId: "411779711619",
        appId: "1:411779711619:web:37934b1b2f3928fda851b6"
    },
    isTest: true
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import "zone.js/dist/zone-error";  // Included with Angular CLI.
