import { Country } from "src/app/core/models/country";
import { PartnerDataUsage } from "src/app/core/models/partners/partner-data-usage";

export class ChartTools {
    public static months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    public static colors = [
        "#5AA454",
        "#ffae00",
        "#008cff",
        "#ff5eac",
        "#ff5733",
        "#33ff57",
        "#5733ff",
        "#ff33a1",
        "#33fff5",
        "#a133ff",
        "#ff3333",
        "#33ff99",
        "#ff9933",
        "#3399ff",
        "#9933ff",
        "#ff33cc",
        "#33ccff",
        "#ffcc33",
        "#cc33ff",
        "#33ffcc",
        "#ff3366",
        "#66ff33",
        "#3366ff",
        "#ff6633",
        "#6633ff",
        "#33ff66",
        "#ff66cc",
        "#66ccff",
        "#ccff33",
        "#ffcc66",
        "#ff3399",
        "#99ff33",
        "#3399ff",
        "#ff9933",
        "#9933ff",
        "#ff33ff",
        "#33ffff",
        "#ffff33",
        "#ff6666",
        "#66ff66",
        "#6666ff",
        "#ff66ff",
        "#66ffff",
        "#ffff66",
        "#ff9999",
        "#99ff99",
        "#9999ff",
        "#ff99ff",
        "#99ffff",
        "#ffff99",
        "#ffcccc",
        "#ccffcc",
        "#ccccff",
        "#ffccff",
        "#ccffff",
        "#ffffcc",
        "#ff0000",
        "#00ff00",
        "#0000ff",
        "#ff00ff",
        "#00ffff",
        "#ffff00",
        "#ff4500",
        "#32cd32",
        "#1e90ff",
        "#ff1493",
        "#00ced1",
        "#ffa500",
        "#8a2be2",
        "#dc143c",
        "#2e8b57",
        "#4682b4",
        "#d2691e",
        "#b22222",
        "#ff6347",
        "#20b2aa",
        "#9932cc",
        "#ff8c00",
        "#ff00ff",
        "#8b0000",
        "#adff2f",
        "#ff69b4",
        "#dda0dd",
        "#87ceeb",
        "#6495ed",
        "#8fbc8f",
        "#ffdab9",
        "#ba55d3",
        "#cd5c5c",
        "#ffb6c1",
        "#7b68ee",
        "#ffdead",
        "#40e0d0",
        "#ff4500",
        "#00ff7f",
        "#da70d6",
        "#ff8c00",
        "#bc8f8f",
        "#b0e0e6",
        "#778899",
        "#f0e68c",
        "#6b8e23",
        "#ff1493",
        "#8b4513",
        "#d2b48c",
        "#5f9ea0",
        "#9932cc",
        "#d2691e",
        "#ff7f50",
        "#9acd32",
    ];

    private static getWeekNumber(_date: Date): number {
        const date = new Date(_date);
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    }

    public static aggregateDailyDataIntoWeekly(dailyPartnerData: PartnerDataUsage[]): PartnerDataUsage[] {
        const weeklyData: PartnerDataUsage[] = [];

        dailyPartnerData.forEach((data) => {
            const date = new Date(data.date);
            const week = ChartTools.getWeekNumber(date);
            const year = date.getFullYear();
            const weekYear = `Week ${week} (${year})`;

            const existingData = weeklyData.find((item) => item.date === weekYear);

            if (existingData) {
                data.usage.forEach(countryUsage => {
                    const countryUsageFound = existingData.usage.find(item => item.country === countryUsage.country);

                    if (countryUsageFound) {
                        countryUsageFound.usageAmount += countryUsage.usageAmount;
                        countryUsageFound.activeUsers += countryUsage.activeUsers;
                    } else {
                        existingData.usage.push({
                            country: countryUsage.country,
                            usageAmount: countryUsage.usageAmount,
                            activeUsers: countryUsage.activeUsers,
                        });
                    }
                });
            } else {
                weeklyData.push({
                    date: weekYear,
                    usage: data.usage,
                });
            }
        });

        return weeklyData;
    }

    public static aggregateDailyDataIntoMonthly(dailyPartnerData: PartnerDataUsage[]): PartnerDataUsage[] {
        const monthlyData = [];

        dailyPartnerData.forEach((data) => {
            const date = new Date(data.date);
            const month = date.getMonth();
            const year = date.getFullYear();
            const monthYear = `${ChartTools.months[+month]} ${year}`;

            const existingData = monthlyData.find((item) => item.date === monthYear);

            if (existingData) {
                data.usage.forEach((countryUsage) => {
                    const existingCountryUsage = existingData.usage.find((item) => item.country === countryUsage.country);

                    if (existingCountryUsage) {
                        existingCountryUsage.usageAmount += countryUsage.usageAmount;
                        existingCountryUsage.activeUsers += countryUsage.activeUsers;
                    } else {
                        existingData.usage.push({
                            country: countryUsage.country,
                            usageAmount: countryUsage.usageAmount,
                            activeUsers: countryUsage.activeUsers
                        });
                    }
                });
            } else {
                monthlyData.push({
                    date: monthYear,
                    usage: data.usage,
                });
            }
        });

        return monthlyData;
    }

    public static filterByCountry(data: PartnerDataUsage[], country: Country) {
        if (country && country?.isoCountryCode !== 'global') {
            return data.map(entry => ({
                date: entry.date,
                usage: entry.usage.filter(item => {
                    if(country.isoCountryCode === 'GB') {
                        return ['GB', 'UK'].includes(item.country);
                    }
                    return item.country === country.isoCountryCode
                })
            }));
        }

        return data;
    }
}
