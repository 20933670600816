import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Route } from '../../models/route';
import { FirebaseAuthService } from '../../services/firebase-auth.service';
import { takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() isExpanded: boolean = false;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();

  screenWidth = window.innerWidth;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = event.target.innerWidth;
  }

  public routes: Route[];
  public userRole: string;

  handleSidebarToggle = () => this.toggleSidebar.emit(!this.isExpanded);

  constructor(
    private readonly firebaseAuthService: FirebaseAuthService,
    private readonly router: Router
  ) {
    this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        if(this.screenWidth < 768) {
          this.handleSidebarToggle();
        }
      }
    });
  }

  ngOnInit(): void {
    this.firebaseAuthService.role
    .subscribe((value: string) => {
        this.userRole = value;
    });
  }

  private compileRoutes(userRole: any): void {
    this.routes = [
      {
        title: 'Home',
        icon: 'bi bi-house-door-fill',
        path: '',
        allowed: true
      },
      {
        title: 'Dashboard',
        icon: 'bi bi-clipboard-data-fill',
        path: '/dashboard',
        allowed: true
      },
      {
        title: 'Users',
        icon: 'bi bi-people-fill',
        path: '/users',
        allowed: true
      }
    ]
  }
}
