export const MOCK_EMPLOYEES_DATA = [
    {
        "email": "alice.smith@example.com",
        "country": "United States",
        "days": 15,
        "data_consumed": "12.5 GB",
        "total_price": "€12.5"
    },
    {
        "email": "bob.johnson@example.com",
        "country": "Canada",
        "days": 7,
        "data_consumed": "5.2 GB",
        "total_price": "€5.2"
    },
    {
        "email": "charlie.brown@example.com",
        "country": "United Kingdom",
        "days": 22,
        "data_consumed": "25.8 GB",
        "total_price": "€25.8"
    },
    {
        "email": "david.lee@example.com",
        "country": "Japan",
        "days": 10,
        "data_consumed": "8.0 GB",
        "total_price": "€8.0"
    },
    {
        "email": "eve.williams@example.com",
        "country": "Germany",
        "days": 18,
        "data_consumed": "19.1 GB",
        "total_price": "€19.1"
    },
    {
        "email": "frank.garcia@example.com",
        "country": "Australia",
        "days": 5,
        "data_consumed": "3.7 GB",
        "total_price": "€3.7"
    },
    {
        "email": "grace.rodriguez@example.com",
        "country": "France",
        "days": 25,
        "data_consumed": "30.2 GB",
        "total_price": "€30.2"
    },
    {
        "email": "henry.martinez@example.com",
        "country": "Brazil",
        "days": 12,
        "data_consumed": "11.0 GB",
        "total_price": "€11.0"
    },
    {
        "email": "ivy.anderson@example.com",
        "country": "India",
        "days": 9,
        "data_consumed": "6.8 GB",
        "total_price": "€6.8"
    },
    {
        "email": "jack.thomas@example.com",
        "country": "South Africa",
        "days": 16,
        "data_consumed": "14.5 GB",
        "total_price": "€14.5"
    },
    {
        "email": "kevin.white@example.com",
        "country": "Mexico",
        "days": 14,
        "data_consumed": "12.0 GB",
        "total_price": "€12.0"
    },
    {
        "email": "laura.harris@example.com",
        "country": "Spain",
        "days": 20,
        "data_consumed": "21.5 GB",
        "total_price": "€21.5"
    },
    {
        "email": "michael.martin@example.com",
        "country": "Italy",
        "days": 8,
        "data_consumed": "7.1 GB",
        "total_price": "€7.1"
    },
    {
        "email": "nancy.thompson@example.com",
        "country": "Netherlands",
        "days": 11,
        "data_consumed": "9.5 GB",
        "total_price": "€9.5"
    },
    {
        "email": "oliver.garcia@example.com",
        "country": "Sweden",
        "days": 19,
        "data_consumed": "20.8 GB",
        "total_price": "€20.8"
    },
    {
        "email": "patricia.perez@example.com",
        "country": "Switzerland",
        "days": 6,
        "data_consumed": "4.9 GB",
        "total_price": "€4.9"
    },
    {
        "email": "quincy.wilson@example.com",
        "country": "Belgium",
        "days": 23,
        "data_consumed": "27.0 GB",
        "total_price": "€27.0"
    },
    {
        "email": "raymond.moore@example.com",
        "country": "Austria",
        "days": 13,
        "data_consumed": "11.8 GB",
        "total_price": "€11.8"
    },
    {
        "email": "samantha.taylor@example.com",
        "country": "Denmark",
        "days": 17,
        "data_consumed": "18.2 GB",
        "total_price": "€18.2"
    },
    {
        "email": "thomas.anderson@example.com",
        "country": "Norway",
        "days": 21,
        "data_consumed": "23.5 GB",
        "total_price": "€23.5"
    },
    {
        "email": "ursula.thomas@example.com",
        "country": "Finland",
        "days": 9,
        "data_consumed": "7.8 GB",
        "total_price": "€7.8"
    },
    {
        "email": "victor.jackson@example.com",
        "country": "Ireland",
        "days": 14,
        "data_consumed": "12.7 GB",
        "total_price": "€12.7"
    },
    {
        "email": "wendy.white@example.com",
        "country": "Portugal",
        "days": 24,
        "data_consumed": "28.1 GB",
        "total_price": "€28.1"
    },
    {
        "email": "xavier.harris@example.com",
        "country": "Greece",
        "days": 10,
        "data_consumed": "8.9 GB",
        "total_price": "€8.9"
    },
    {
        "email": "yvonne.martin@example.com",
        "country": "Poland",
        "days": 16,
        "data_consumed": "15.4 GB",
        "total_price": "€15.4"
    },
    {
        "email": "zachary.thompson@example.com",
        "country": "Hungary",
        "days": 12,
        "data_consumed": "11.2 GB",
        "total_price": "€11.2"
    },
    {
        "email": "abigail.garcia@example.com",
        "country": "Czech Republic",
        "days": 18,
        "data_consumed": "19.9 GB",
        "total_price": "€19.9"
    },
    {
        "email": "benjamin.perez@example.com",
        "country": "Slovakia",
        "days": 7,
        "data_consumed": "6.1 GB",
        "total_price": "€6.1"
    },
    {
        "email": "catherine.wilson@example.com",
        "country": "Slovenia",
        "days": 20,
        "data_consumed": "22.3 GB",
        "total_price": "€22.3"
    },
    {
        "email": "daniel.moore@example.com",
        "country": "Estonia",
        "days": 11,
        "data_consumed": "10.0 GB",
        "total_price": "€10.0"
    },
    {
        "email": "eleanor.taylor@example.com",
        "country": "Latvia",
        "days": 17,
        "data_consumed": "18.7 GB",
        "total_price": "€18.7"
    },
    {
        "email": "frederick.anderson@example.com",
        "country": "Lithuania",
        "days": 13,
        "data_consumed": "12.5 GB",
        "total_price": "€12.5"
    },
    {
        "email": "georgia.thomas@example.com",
        "country": "Croatia",
        "days": 19,
        "data_consumed": "21.1 GB",
        "total_price": "€21.1"
    }
]