import { NgModule } from "@angular/core";
import { SortableDirective } from "./directives/sortable.directive";
import { CopyToClipboardDirective } from "./directives/copy-to-clipboard.directive";
import { CommonModule } from "@angular/common";
import { NgbAccordionModule, NgbModule, NgbPaginationModule, NgbPopoverModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ProgressBarComponent } from "./components/progress-bar/progress-bar.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { AnimatedNumberComponent } from "./components/animated-number/animated-number.component";
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { PlanTypePipe } from "../core/pipes/plan-type.pipe";
import { DeepObjectValuePipe } from "../core/pipes/deep-object-value.pipe";

@NgModule({
    declarations: [
        SortableDirective,
        CopyToClipboardDirective,
        ProgressBarComponent,
        AnimatedNumberComponent,
        DateRangePickerComponent,
        PlanTypePipe,
        DeepObjectValuePipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NgSelectModule,
        NgbAccordionModule,
        NgbPaginationModule,
        NgxChartsModule,
        NgbTooltipModule,
        NgbPopoverModule
    ],
    exports: [
        SortableDirective,
        CopyToClipboardDirective,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        ProgressBarComponent,
        NgSelectModule,
        NgbAccordionModule,
        NgbPaginationModule,
        NgxChartsModule,
        AnimatedNumberComponent,
        DateRangePickerComponent,
        NgbTooltipModule,
        PlanTypePipe,
        DeepObjectValuePipe,
        NgbPopoverModule
    ]
})
export class SharedModule { }
