import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import { FirebaseAuthService } from "./core/services/firebase-auth.service";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { ToasterService } from "./core/services/toaster.service";
import { Toast } from "./shared/models/toast";
import { RequestTrackerService } from "./core/services/request-tracker.service";
import { ApiResponse } from "./shared/models/api-response";
import { ClaimsService } from "./core/services/claims.service";
import { Member } from "./shared/models/member";
import { User } from "@angular/fire/auth";
import * as bootstrap from "bootstrap";
import { environment } from "src/environments/environment";
import { MessageModalService } from "./core/services/message-modal.service";
import { PartnerRegistrationFormComponent } from "./core/components/partner-registration-form/partner-registration-form.component";
import { PartnerLoginFormComponent } from "./core/components/partner-login-form/partner-login-form.component";
import { FirebaseErrorCodeMapping } from "./shared/mapping/firebase-error-code-mapping";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
    public isTest: boolean;
    public sidebarExpanded = true;
    private _uid : string;
    private roleTrial: number= 0;

    public userRole: any;

    public get uid() : string {
        return this._uid;
    }

    private _token : string;

    public get token() : string {
        return this._token;
    }

    public _active: string;

    public get active(): string {
        return this._active;
    }
    
    public set active(value: string) {
        this._active = value;
    }

    private _isLoggedIn: boolean;

    public get isLoggedIn(): boolean {
        return this._isLoggedIn;
    }

    private _isAdmin: boolean;

    public get isAdmin(): boolean {
        return this._isAdmin;
    }

    private _status: string;

    public get status(): string {
        return this._status;
    };

    private unsubscribe$: Subject<void> = new Subject<void>();

    public readonly toasts: Toast[] = [];
    public showPartnerAuthenticationForm = false;
    public showPartnerRegistrationForm = false;

    public constructor(
        public readonly router: Router,
        private readonly firebaseAuthService: FirebaseAuthService,
        private readonly toasterService: ToasterService,
        public readonly requestTrackerService: RequestTrackerService,
        private readonly claimsService: ClaimsService,
        private changeDetectorRef: ChangeDetectorRef,
        private messageModalService: MessageModalService
    ) {
        this.isTest = environment.isTest;
        this.router.events.subscribe((e: RouterEvent) => {
            if (e instanceof NavigationEnd) {
                this._active = e.url;
            }
        });

        this.toasterService.toasts.subscribe((toast: Toast) => {
            this.removeToasts(this.toasts[0], this.toasts.length); // fixme(onuro): stacking toasts not possible at the moment, therefore remove all before pushing new. dynamic bottom/top values???
            this.toasts.push(toast);
        });
    }

    public ngOnInit(): void {
        this.initialiseToolTips();
        
        this.firebaseAuthService.isLoggedIn
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((value: boolean) => {
                this._isLoggedIn = value;
            });

        this.firebaseAuthService.role
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((value: string) => {
                console.log("ROLE: ", value);
                if (this.userRole === 'admin') {
                    this._isAdmin = true;
                }
                if (this.userRole === value && this.roleTrial > 0) {
                    //null
                } else {
                    this.uid && this.claimRightRole()
                }
            });

            this.firebaseAuthService.user
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((value: User) => {
                if(value) {
                    this._uid = value.uid;
                    const customAttributes = JSON.parse(value['reloadUserInfo']?.customAttributes || '{}');

                    if(!customAttributes?.role) {
                        this.firebaseAuthService.role.subscribe((role: string) => {
                            if(role && !this._getAutoRefreshAttempt()) {
                                localStorage.setItem('autoRefreshAttempt', 'true');
                                window.location.reload();
                            }
                        });
                    }
                }
            });

        this.firebaseAuthService.idToken
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((value: string) => {
                console.log(value)
                this._token = value;
            });
    }

    private _getAutoRefreshAttempt() {
        let autoRefreshAttempt = localStorage.getItem('autoRefreshAttempt');
        autoRefreshAttempt && (autoRefreshAttempt = JSON.parse(autoRefreshAttempt));
        return !!autoRefreshAttempt;
    }

    public async loginWithGoogle(): Promise<void> {
        try {
            await this.firebaseAuthService.loginWithGoogle();
            // this.toasterService.success("Login successful.");

            this.router.navigate(["/dashboard"]);
        } catch (error: unknown) {
            // this.toasterService.error((error as Error).message);
            this.messageModalService.open("error", (error as Error).message);
        }
    }

    public async loginWithEmailAndPassword(email: string, password: string, partnerLoginFormComponent: PartnerLoginFormComponent): Promise<void> {
        this.requestTrackerService.startRequest();
        try {
            await this.firebaseAuthService.signInWithEmailAndPassword(email, password);
            partnerLoginFormComponent.resetForm();
            this.showPartnerAuthenticationForm = false;
            this.requestTrackerService.endRequest();
            this.router.navigate(["/dashboard"]);
        } catch (error: unknown) {
            this.requestTrackerService.endRequest();
            this.messageModalService.open("error", FirebaseErrorCodeMapping.getMessage(error));
        }
    }

    public async registerWithEmailAndPassword(name: string, email: string, password: string, registrationFormComponent: PartnerRegistrationFormComponent): Promise<void> {
        localStorage.setItem('autoRefreshAttempt', 'false');
        this.requestTrackerService.startRequest();
        try {
            await this.firebaseAuthService.register(name, email, password);
            registrationFormComponent.resetForm();
            this.showPartnerRegistrationForm = false;
            this.requestTrackerService.endRequest();
        } catch (error: unknown) {
            this.requestTrackerService.endRequest();
            this.messageModalService.open("error", FirebaseErrorCodeMapping.getMessage(error));
        }
    }

    public async logout(): Promise<void> {
        try {
            if (await this.firebaseAuthService.logout()) {
                indexedDB.deleteDatabase('firebaseLocalStorageDb');
                localStorage.setItem('autoRefreshAttempt', 'false');
                window.location.reload();
            }
        } catch (error: unknown) {
            // this.toasterService.error((error as Error).message);
            this.messageModalService.open("error", (error as Error).message);
        }
    }

    private async claimRightRole(): Promise<void> {
        this.claimsService.checkAndSetRole(this.uid).subscribe({
            next: (data: any) => {
                this.roleTrial++;
                this.userRole = data.data.role;
                if (data.data.role === null) {
                    data.data.role = "user";
                }
                this.firebaseAuthService.setRole(data.data.role);
            },
            error: (error: any) => {
                if(!this._getAutoRefreshAttempt()) {
                    localStorage.setItem('autoRefreshAttempt', 'true');

                    this.messageModalService.open(
                        'info',
                        'Your account is not registered for access. Please contact your sales representative and retry later.',
                        [
                            {
                                btnText: 'Retry',
                                action: () => {
                                    localStorage.setItem('autoRefreshAttempt', 'false');
                                    window.location.reload();
                                } 
                            },
                            {
                                btnText: 'Close',
                                action: () => {}
                            }
                        ]
                    );
                } else {
                    // this.messageModalService.open("error", error.message);
                }
            }
        });
    }

    public async claimAdmin(): Promise<void> {
        try {
            const claimAdminResponse: ApiResponse<Member> = await this.claimsService.claimAdmin(this.uid).toPromise();

            if (!claimAdminResponse.code && this.firebaseAuthService.logout()) {
                // this.toasterService.success("You are now an admin! Please login to see it in action!");
                this.messageModalService.open("success", "You are now an admin! Please login to see it in action!");
    
                this.router.navigate(["/"]);
            }
        } catch {
            // this.toasterService.error("Error! You are not an admin");
            this.messageModalService.open("warn", "You are not an admin");

        }
    }

    public removeToasts(toast: Toast, removeCount: number = 1) {
        const removedToasts: Toast[] = this.toasts.splice(this.toasts.indexOf(toast), removeCount);
        
        for (const removedToast of removedToasts) {
            console.info(removedToast.message); // fixme(onuro): if toast is error, send log to backend
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    
    public isLoading(): boolean {
        return this.requestTrackerService.isLoading();
    }

    private initialiseToolTips(): void {
        setTimeout(() => {
          const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
          const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl);
          })
        });
    }
}
