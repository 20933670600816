<div class="container pb-3">
    <div *ngIf="(firebaseAuthService.role | async) === 'partner'">
        <h1>{{(partnerSelected$ | async)?.name}}</h1>
    </div>

    <div *ngIf="(firebaseAuthService.role | async) !== 'partner'">
        <h1>Price list</h1>
    </div>

    <div *ngIf="(firebaseAuthService.role | async) === 'admin'" class="d-inline-flex align-items-center">
        <label for="select-partner" class="form-label h2 pe-3">Partner</label>
        <select id="select-partner" (change)="partnerChanged($event?.target?.['value'])" class="form-select" aria-label="Select partner">
            <option disabled selected>Select partner</option>
            <option *ngFor="let partner of (partners$ | async)" [value]="partner.code">{{partner.name}}</option>
        </select>
    </div>

    <div class="bg-dark-blue-transparent br-0-5rem p-3 shadow d-flex justify-content-between align-items-center mt-3 mb-4">
        <div class="input-group w-auto">
            <span class="input-group-text">
                <i class="bi bi-search"></i>
            </span>
            <input 
                type="text"
                class="form-control" 
                id="floatingInput"
                name="searchTerm"
                (input)="searchTermInput($event)"
            />
        </div>

        <div>
            <button 
                type="button" 
                class="btn btn-primary mx-2"
                (click)="loadPriceList()"
            >
                <i class="bi bi-arrow-clockwise"></i>
                <span class="ms-2 d-none d-lg-inline-block">Refresh</span>
            </button>

            <button 
                type="button" 
                class="btn btn-primary mx-2"
                (click)="exportAsExcel($event)"
            >
                <i class="bi bi-file-earmark-spreadsheet"></i>
                <span class="ms-2 d-none d-lg-inline-block">Export</span>
            </button>
        </div>
    </div>

    <div class="d-lg-none">
        <div *ngFor="let item of (filteredList$ | async)" class="br-0-5rem shadow mb-4 text-white bg-dark-blue-transparent p-3">
            <div class="d-flex align-items-center w-100">
                <span class="fi fi-{{item.country.isoCountryCode | lowercase}} fis"></span>
                <span class="ms-2">{{item.country.name}}</span>
            </div>

            <div class="d-flex" [class.justify-content-evenly]="item.comfort_plus || item.first_class">
                <div class="mt-2">
                    <ul>
                        <li class="mb-2">
                            <span>Tier 1:</span>
                            <span class="ms-2">{{item.tier1}}</span>
                        </li>
                        <li class="mb-2">
                            <span>Tier 2:</span>
                            <span class="ms-2">{{item.tier2}}</span>
                        </li>
                        <li class="mb-2">
                            <span>Tier 3:</span>
                            <span class="ms-2">{{item.tier3}}</span>
                        </li>
                        <li class="mb-2">
                            <span>Tier 4:</span>
                            <span class="ms-2">{{item.tier4}}</span>
                        </li>
                    </ul>
                </div>

                <ng-container *ngIf="item.comfort_plus || item.first_class">
                    <div class="border-start"></div>
                    <div>
                        <!-- <div class="mb-3">
                            <span class="fw-semibold">Day ULTRA:</span>
                        </div> -->
                        <ul>
                            <li class="mb-2">
                                <span>Comfort+:</span>
                                <span class="ms-2">{{item.comfort_plus}}</span>
                            </li>
                            <li class="mb-2">
                                <span>First Class:</span>
                                <span class="ms-2">{{item.first_class}}</span>
                            </li>
                        </ul>
                    </div>
                </ng-container>
            </div>
            <!-- <div class="d-flex flex-column justify-content-evenly align-items-center h-100">
                <div class="fw-bold">{{item.unitPrice| currency:'USD':'symbol':'1.2-2'}} / MB</div>
            </div> -->
        </div>
    </div>

    <div class="d-none d-lg-block br-0-5rem shadow mb-4">
        <table class="table table-striped rounded-table">
            <thead>
                <tr>
                    <th *ngFor="let column of columns">
                        <div role="button" (click)="sort(column.name)">
                            <span class="user-select-none">{{column.label}}</span>
                            <span class="sorting-order-indicator">
                                <ng-container *ngIf="column.name === (sortingColumn$ | async)">
                                    <span class="ps-2" *ngIf="sortingOrder === 'asc'">
                                        <i class="bi bi-caret-up-fill"></i>
                                    </span>
                                    <span class="ps-2" *ngIf="sortingOrder === 'desc'">
                                        <i class="bi bi-caret-down-fill"></i>
                                    </span>
                                </ng-container>
                            </span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let item of (filteredList$ | async)">
                    <tr>
                        <td *ngFor="let column of columns">
                            <div class="d-flex" [ngSwitch]="column.name">
                                <span *ngSwitchCase="'country.name'">
                                    <div class="d-flex align-items-center">
                                        <span class="fi fi-{{item.country.isoCountryCode | lowercase}} fis"></span>
                                        <span class="ms-2">{{item | deepObjectValue : column.name}}</span>
                                    </div>
                                </span>
                                
                                <span *ngSwitchDefault>{{item | deepObjectValue : column.name}}</span>
                            </div>
                        </td>
                    </tr>
                    <!-- <tr *ngIf="item['showDetails']">
                        <td colspan="5">
                            <div>
                                <div class="mb-3">
                                    <span class="fw-semibold">Day ULTRA:</span>
                                </div>
                                <ul>
                                    <li *ngFor="let pricePerDayUltra of item.pricePerDayUltra | keyvalue" class="mb-2">
                                        <span>{{ pricePerDayUltra.key }}:</span>
                                        <span class="ms-2">{{ pricePerDayUltra.value | currency:'USD':'symbol':'1.2-2'}}</span>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr> -->
                </ng-container>
            </tbody>
        </table>
    </div>

    <div class="bg-dark-blue-transparent br-0-5rem p-3 shadow d-lg-flex justify-content-between align-items-center">
        <div class="text-white opacity-75 py-1 text-center">
            {{ itemsPerPage }} items per page
        </div>

        <div class="d-flex justify-content-center align-items-center">
            <ngb-pagination
                [collectionSize]="resultSize"
                [page]="currentPage$ | async"
                [pageSize]="itemsPerPage"
                (pageChange)="pageChange($event)"
                [maxSize]="3"
                [rotate]="true"
                [boundaryLinks]="false"
                size="sm"
                class="mb-0 pagination-dark"
                (pageChange)="pageChange($event)"
            ></ngb-pagination>
        </div>

        <div class="text-white opacity-75 py-1 text-center">
            Showing {{ startIndex + 1 }}-{{
                endIndex > resultSize ? resultSize : endIndex
            }}
            of {{ resultSize }}
        </div>
    </div>

</div>