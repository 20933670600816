import { NgModule } from "@angular/core";
import { provideFirebaseApp, initializeApp } from "@angular/fire/app";
import { Auth, provideAuth, getAuth, connectAuthEmulator } from "@angular/fire/auth";
import { Functions, getFunctions, provideFunctions, connectFunctionsEmulator } from "@angular/fire/functions";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DashboardModule } from "./modules/dashboard/dashboard.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FirebaseIdTokenInterceptor } from "src/app/shared/interceptors/firebase-id-token.interceptor";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RequestLifecycleInterceptor } from "./shared/interceptors/request-lifecycle.interceptor";
import { DatePipe } from "@angular/common";
import { SidebarComponent } from './core/components/sidebar/sidebar.component';
import { SharedModule } from "./shared/shared.module";
import { HomepageComponent } from './core/components/homepage/homepage.component';
import { TokenComponent } from './core/components/token/token.component';
import { SessionTokenValidityInterceptor } from "./shared/interceptors/session-token-validity.interceptor";
import { PartnerCompanyDashboardModule } from "./modules/partner-company-dashboard/partner-company-dashboard.module";
import { InvoicesModule } from "./modules/invoices/invoices.module";
import { EmployeeDataConsumptionModule } from "./modules/employee-data-consumption/employee-data-consumption.module";
import { FirstyLoaderComponent } from "./core/components/firsty-loader/firsty-loader.component";

// For the Lottie animation on loader
import { LottieModule } from 'ngx-lottie';
import { PriceListModule } from "./modules/price-list/price-list.module";
import { MessageModalComponent } from './core/components/message-modal/message-modal.component';
import { PartnerLoginFormComponent } from './core/components/partner-login-form/partner-login-form.component';
import { PartnerRegistrationFormComponent } from './core/components/partner-registration-form/partner-registration-form.component';
export function playerFactory(): any {  
  return import('lottie-web');
}


@NgModule({
    declarations: [
        AppComponent,
        SidebarComponent,
        HomepageComponent,
        TokenComponent,
        FirstyLoaderComponent,
        MessageModalComponent,
        PartnerLoginFormComponent,
        PartnerRegistrationFormComponent
    ],
    imports: [
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => {
            const auth: Auth = getAuth();

            if (environment.useEmulators) {
                connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });
            }

            return auth;
        }),
        provideFunctions(() => {
            const functions: Functions = getFunctions();

            if (environment.useEmulators) {
                connectFunctionsEmulator(functions, "http://localhost", 5001);
            }

            return functions;
        }),
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        AppRoutingModule,
        DashboardModule,
        PartnerCompanyDashboardModule,
        InvoicesModule,
        EmployeeDataConsumptionModule,
        SharedModule,
        LottieModule.forRoot({ player: playerFactory }), 
        PriceListModule
    ],
    providers: [
        DatePipe,
        { provide: HTTP_INTERCEPTORS, useClass: FirebaseIdTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: RequestLifecycleInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: SessionTokenValidityInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
