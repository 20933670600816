import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { User } from "@angular/fire/auth";
import { Observable } from "rxjs";
import { ApiResponse } from "src/app/shared/models/api-response";
import { FirebaseAuthService } from "src/app/core/services/firebase-auth.service";
import { Partner } from "../models/partners/partner";
import { PartnerDetails } from "../models/partners/partner-details";
import { CreatePartnerRequest } from "../models/partners/create-partner-request";
import { CreatePartnerUserRequest } from "../models/partners/create-partner-user-request";
import { PartnerUser } from "../models/partners/partner-user";

@Injectable()
export class PartnersHttpService {
        adminUid: string;

    public constructor(
        private readonly httpClient: HttpClient,
        private readonly firebaseAuthService: FirebaseAuthService
    ) { 
        this.firebaseAuthService.user.subscribe((user: User) => {
            this.adminUid = user.uid;
        });
    }

    public listPartners(): Observable<ApiResponse<Partner[]>> {
        return this.httpClient.get<ApiResponse<Partner[]>>(`/api/backoffice/partners/${this.adminUid}`);
    }

    public getPartner(partnerId: string): Observable<ApiResponse<PartnerDetails>> {
        return this.httpClient.get<ApiResponse<PartnerDetails>>(`/api/backoffice/partners/${this.adminUid}/${partnerId}`);
    }

    public listPartnerUsers(partnerId: string): Observable<ApiResponse<PartnerUser[]>> {
        return this.httpClient.get<ApiResponse<PartnerUser[]>>(`/api/backoffice/partners/${this.adminUid}/${partnerId}/users`);
    }

    public createPartnerUsers(createUsersRequest: CreatePartnerUserRequest): Observable<ApiResponse<any[]>> {
        return this.httpClient.post<ApiResponse<any>>(`/api/backoffice/partners/${this.adminUid}/create-users`, createUsersRequest);
    }

    public createPartner(createPartnerRequest: CreatePartnerRequest): Observable<ApiResponse<any[]>> {
        return this.httpClient.post<ApiResponse<any>>(`/api/backoffice/partners/${this.adminUid}`, createPartnerRequest);
    }
}
