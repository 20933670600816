export const MOCK_INVOICES_LIST = [
    {
        "invoiceNumber": "Gaumat-062023-1",
        "invoiceDate": "20/06/2023",
        "billingPeriod": "June 1 - June 30 2023",
        "recipientName": "Gaumat",
        "companyName": "Davis & Co.",
        "address": "Street 19, City 5, Czech Republic",
        "vatNumber": 250145559,
        "subTotal": 4459,
        "vatPercentage": 21,
        "vatAmount": 936.39,
        "grandTotal": 5395.39,
        "dueDate": "05/07/2023",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Netherlands",
                "quantity": 29,
                "price": 18,
                "total": 522
            },
            {
                "description": "Romania",
                "quantity": 4,
                "price": 47,
                "total": 188
            },
            {
                "description": "Germany",
                "quantity": 37,
                "price": 53,
                "total": 1961
            },
            {
                "description": "Belgium",
                "quantity": 45,
                "price": 26,
                "total": 1170
            },
            {
                "description": "France",
                "quantity": 26,
                "price": 6,
                "total": 156
            },
            {
                "description": "Bulgaria",
                "quantity": 42,
                "price": 11,
                "total": 462
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-072023-1",
        "invoiceDate": "20/07/2023",
        "billingPeriod": "July 1 - July 31 2023",
        "recipientName": "Gaumat",
        "companyName": "White & Partners",
        "address": "Street 294, City 15, Bulgaria",
        "vatNumber": 3128359345,
        "subTotal": 2224,
        "vatPercentage": 21,
        "vatAmount": 467.04,
        "grandTotal": 2691.04,
        "dueDate": "05/08/2023",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Germany",
                "quantity": 28,
                "price": 27,
                "total": 756
            },
            {
                "description": "Greece",
                "quantity": 1,
                "price": 47,
                "total": 47
            },
            {
                "description": "Finland",
                "quantity": 7,
                "price": 28,
                "total": 196
            },
            {
                "description": "Finland",
                "quantity": 35,
                "price": 35,
                "total": 1225
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-082023-1",
        "invoiceDate": "20/08/2023",
        "billingPeriod": "August 1 - August 31 2023",
        "recipientName": "Gaumat",
        "companyName": "Anderson & Sons",
        "address": "Street 367, City 14, Cyprus",
        "vatNumber": 1870173433,
        "subTotal": 2426,
        "vatPercentage": 21,
        "vatAmount": 509.46,
        "grandTotal": 2935.46,
        "dueDate": "05/09/2023",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Latvia",
                "quantity": 42,
                "price": 31,
                "total": 1302
            },
            {
                "description": "Romania",
                "quantity": 23,
                "price": 16,
                "total": 368
            },
            {
                "description": "Malta",
                "quantity": 21,
                "price": 33,
                "total": 693
            },
            {
                "description": "Cyprus",
                "quantity": 7,
                "price": 9,
                "total": 63
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-092023-1",
        "invoiceDate": "20/09/2023",
        "billingPeriod": "September 1 - September 30 2023",
        "recipientName": "Gaumat",
        "companyName": "Anderson & Sons",
        "address": "Street 69, City 7, Malta",
        "vatNumber": 5884951277,
        "subTotal": 1864,
        "vatPercentage": 21,
        "vatAmount": 391.44,
        "grandTotal": 2255.44,
        "dueDate": "05/10/2023",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Poland",
                "quantity": 35,
                "price": 26,
                "total": 910
            },
            {
                "description": "Czech Republic",
                "quantity": 41,
                "price": 22,
                "total": 902
            },
            {
                "description": "Slovenia",
                "quantity": 4,
                "price": 13,
                "total": 52
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-102023-1",
        "invoiceDate": "20/10/2023",
        "billingPeriod": "October 1 - October 31 2023",
        "recipientName": "Gaumat",
        "companyName": "Lopez & Co.",
        "address": "Street 247, City 3, Czech Republic",
        "vatNumber": 1889672861,
        "subTotal": 1000,
        "vatPercentage": 21,
        "vatAmount": 210,
        "grandTotal": 1210,
        "dueDate": "05/11/2023",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "France",
                "quantity": 30,
                "price": 14,
                "total": 420
            },
            {
                "description": "Czech Republic",
                "quantity": 25,
                "price": 10,
                "total": 250
            },
            {
                "description": "France",
                "quantity": 18,
                "price": 12,
                "total": 216
            },
            {
                "description": "Estonia",
                "quantity": 19,
                "price": 6,
                "total": 114
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-112023-1",
        "invoiceDate": "20/11/2023",
        "billingPeriod": "November 1 - November 30 2023",
        "recipientName": "Gaumat",
        "companyName": "Martin & Co.",
        "address": "Street 433, City 18, Estonia",
        "vatNumber": 7402824827,
        "subTotal": 1267,
        "vatPercentage": 21,
        "vatAmount": 266.07,
        "grandTotal": 1533.07,
        "dueDate": "05/12/2023",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "France",
                "quantity": 40,
                "price": 13,
                "total": 520
            },
            {
                "description": "Croatia",
                "quantity": 35,
                "price": 9,
                "total": 315
            },
            {
                "description": "Germany",
                "quantity": 27,
                "price": 16,
                "total": 432
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-122023-1",
        "invoiceDate": "20/12/2023",
        "billingPeriod": "December 1 - December 31 2023",
        "recipientName": "Gaumat",
        "companyName": "Jackson & Sons",
        "address": "Street 21, City 6, Poland",
        "vatNumber": 9266323744,
        "subTotal": 2192,
        "vatPercentage": 21,
        "vatAmount": 460.32,
        "grandTotal": 2652.32,
        "dueDate": "05/01/2024",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Sweden",
                "quantity": 14,
                "price": 31,
                "total": 434
            },
            {
                "description": "Latvia",
                "quantity": 12,
                "price": 54,
                "total": 648
            },
            {
                "description": "Estonia",
                "quantity": 11,
                "price": 10,
                "total": 110
            },
            {
                "description": "Bulgaria",
                "quantity": 50,
                "price": 20,
                "total": 1000
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-012024-1",
        "invoiceDate": "20/01/2024",
        "billingPeriod": "January 1 - January 31 2024",
        "recipientName": "Gaumat",
        "companyName": "White & Partners",
        "address": "Street 452, City 15, Malta",
        "vatNumber": 9174593432,
        "subTotal": 2988,
        "vatPercentage": 21,
        "vatAmount": 627.48,
        "grandTotal": 3615.48,
        "dueDate": "05/02/2024",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Malta",
                "quantity": 45,
                "price": 51,
                "total": 2295
            },
            {
                "description": "Austria",
                "quantity": 33,
                "price": 21,
                "total": 693
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-022024-1",
        "invoiceDate": "20/02/2024",
        "billingPeriod": "February 1 - February 29 2024",
        "recipientName": "Gaumat",
        "companyName": "White & Partners",
        "address": "Street 493, City 12, Poland",
        "vatNumber": 8481613993,
        "subTotal": 1780,
        "vatPercentage": 21,
        "vatAmount": 373.8,
        "grandTotal": 2153.8,
        "dueDate": "05/03/2024",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Austria",
                "quantity": 25,
                "price": 48,
                "total": 1200
            },
            {
                "description": "Portugal",
                "quantity": 4,
                "price": 19,
                "total": 76
            },
            {
                "description": "Portugal",
                "quantity": 18,
                "price": 28,
                "total": 504
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-032024-1",
        "invoiceDate": "20/03/2024",
        "billingPeriod": "March 1 - March 31 2024",
        "recipientName": "Gaumat",
        "companyName": "Anderson & Sons",
        "address": "Street 486, City 14, Czech Republic",
        "vatNumber": 1631690858,
        "subTotal": 1203,
        "vatPercentage": 21,
        "vatAmount": 252.63,
        "grandTotal": 1455.63,
        "dueDate": "05/04/2024",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Netherlands",
                "quantity": 2,
                "price": 30,
                "total": 60
            },
            {
                "description": "Slovenia",
                "quantity": 2,
                "price": 12,
                "total": 24
            },
            {
                "description": "Slovenia",
                "quantity": 14,
                "price": 21,
                "total": 294
            },
            {
                "description": "Spain",
                "quantity": 25,
                "price": 33,
                "total": 825
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-042024-1",
        "invoiceDate": "20/04/2024",
        "billingPeriod": "April 1 - April 30 2024",
        "recipientName": "Gaumat",
        "companyName": "Martinez & Co.",
        "address": "Street 129, City 3, Finland",
        "vatNumber": 2076101684,
        "subTotal": 3477,
        "vatPercentage": 21,
        "vatAmount": 730.17,
        "grandTotal": 4207.17,
        "dueDate": "05/05/2024",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Latvia",
                "quantity": 33,
                "price": 39,
                "total": 1287
            },
            {
                "description": "Croatia",
                "quantity": 32,
                "price": 18,
                "total": 576
            },
            {
                "description": "Austria",
                "quantity": 49,
                "price": 12,
                "total": 588
            },
            {
                "description": "Italy",
                "quantity": 38,
                "price": 27,
                "total": 1026
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-052024-1",
        "invoiceDate": "20/05/2024",
        "billingPeriod": "May 1 - May 31 2024",
        "recipientName": "Gaumat",
        "companyName": "Gonzalez Ltd.",
        "address": "Street 116, City 9, Greece",
        "vatNumber": 7160578900,
        "subTotal": 5639,
        "vatPercentage": 21,
        "vatAmount": 1184.19,
        "grandTotal": 6823.1900000000005,
        "dueDate": "05/06/2024",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Denmark",
                "quantity": 32,
                "price": 32,
                "total": 1024
            },
            {
                "description": "Croatia",
                "quantity": 35,
                "price": 31,
                "total": 1085
            },
            {
                "description": "Finland",
                "quantity": 44,
                "price": 45,
                "total": 1980
            },
            {
                "description": "Croatia",
                "quantity": 17,
                "price": 31,
                "total": 527
            },
            {
                "description": "Croatia",
                "quantity": 15,
                "price": 43,
                "total": 645
            },
            {
                "description": "Slovakia",
                "quantity": 42,
                "price": 9,
                "total": 378
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-062024-1",
        "invoiceDate": "20/06/2024",
        "billingPeriod": "June 1 - June 30 2024",
        "recipientName": "Gaumat",
        "companyName": "Lopez & Co.",
        "address": "Street 307, City 5, Spain",
        "vatNumber": 6699116762,
        "subTotal": 312,
        "vatPercentage": 21,
        "vatAmount": 65.52,
        "grandTotal": 377.52,
        "dueDate": "05/07/2024",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Poland",
                "quantity": 8,
                "price": 39,
                "total": 312
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-072024-1",
        "invoiceDate": "20/07/2024",
        "billingPeriod": "July 1 - July 31 2024",
        "recipientName": "Gaumat",
        "companyName": "Garcia Ltd.",
        "address": "Street 442, City 10, Estonia",
        "vatNumber": 7210278294,
        "subTotal": 1905,
        "vatPercentage": 21,
        "vatAmount": 400.05,
        "grandTotal": 2305.05,
        "dueDate": "05/08/2024",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Romania",
                "quantity": 20,
                "price": 48,
                "total": 960
            },
            {
                "description": "Finland",
                "quantity": 13,
                "price": 29,
                "total": 377
            },
            {
                "description": "Ireland",
                "quantity": 10,
                "price": 31,
                "total": 310
            },
            {
                "description": "Slovenia",
                "quantity": 21,
                "price": 8,
                "total": 168
            },
            {
                "description": "Germany",
                "quantity": 6,
                "price": 15,
                "total": 90
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-082024-1",
        "invoiceDate": "20/08/2024",
        "billingPeriod": "August 1 - August 31 2024",
        "recipientName": "Gaumat",
        "companyName": "Anderson & Sons",
        "address": "Street 31, City 4, Austria",
        "vatNumber": 3862381284,
        "subTotal": 2434,
        "vatPercentage": 21,
        "vatAmount": 511.14,
        "grandTotal": 2945.14,
        "dueDate": "05/09/2024",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Slovenia",
                "quantity": 10,
                "price": 23,
                "total": 230
            },
            {
                "description": "Poland",
                "quantity": 7,
                "price": 39,
                "total": 273
            },
            {
                "description": "Malta",
                "quantity": 37,
                "price": 23,
                "total": 851
            },
            {
                "description": "Ireland",
                "quantity": 40,
                "price": 27,
                "total": 1080
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-092024-1",
        "invoiceDate": "20/09/2024",
        "billingPeriod": "September 1 - September 30 2024",
        "recipientName": "Gaumat",
        "companyName": "White & Partners",
        "address": "Street 317, City 1, Austria",
        "vatNumber": 8032305793,
        "subTotal": 1058,
        "vatPercentage": 21,
        "vatAmount": 222.18,
        "grandTotal": 1280.18,
        "dueDate": "05/10/2024",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Malta",
                "quantity": 26,
                "price": 13,
                "total": 338
            },
            {
                "description": "Austria",
                "quantity": 24,
                "price": 30,
                "total": 720
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-102024-1",
        "invoiceDate": "20/10/2024",
        "billingPeriod": "October 1 - October 31 2024",
        "recipientName": "Gaumat",
        "companyName": "Moore & Co.",
        "address": "Street 219, City 7, Finland",
        "vatNumber": 5962503793,
        "subTotal": 750,
        "vatPercentage": 21,
        "vatAmount": 157.5,
        "grandTotal": 907.5,
        "dueDate": "05/11/2024",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Poland",
                "quantity": 15,
                "price": 50,
                "total": 750
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-112024-1",
        "invoiceDate": "20/11/2024",
        "billingPeriod": "November 1 - November 30 2024",
        "recipientName": "Gaumat",
        "companyName": "Jackson & Sons",
        "address": "Street 54, City 14, Greece",
        "vatNumber": 8167460514,
        "subTotal": 5074,
        "vatPercentage": 21,
        "vatAmount": 1065.54,
        "grandTotal": 6139.54,
        "dueDate": "05/12/2024",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Austria",
                "quantity": 28,
                "price": 15,
                "total": 420
            },
            {
                "description": "Bulgaria",
                "quantity": 39,
                "price": 54,
                "total": 2106
            },
            {
                "description": "Cyprus",
                "quantity": 39,
                "price": 8,
                "total": 312
            },
            {
                "description": "Estonia",
                "quantity": 26,
                "price": 50,
                "total": 1300
            },
            {
                "description": "Estonia",
                "quantity": 26,
                "price": 36,
                "total": 936
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-122024-1",
        "invoiceDate": "20/12/2024",
        "billingPeriod": "December 1 - December 31 2024",
        "recipientName": "Gaumat",
        "companyName": "Harris Ltd.",
        "address": "Street 22, City 16, Denmark",
        "vatNumber": 9088998068,
        "subTotal": 1764,
        "vatPercentage": 21,
        "vatAmount": 370.44,
        "grandTotal": 2134.44,
        "dueDate": "05/01/2025",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "France",
                "quantity": 30,
                "price": 30,
                "total": 900
            },
            {
                "description": "Austria",
                "quantity": 16,
                "price": 54,
                "total": 864
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-012025-1",
        "invoiceDate": "20/01/2025",
        "billingPeriod": "January 1 - January 31 2025",
        "recipientName": "Gaumat",
        "companyName": "Gonzalez Ltd.",
        "address": "Street 316, City 16, Hungary",
        "vatNumber": 1701522152,
        "subTotal": 1325,
        "vatPercentage": 21,
        "vatAmount": 278.25,
        "grandTotal": 1603.25,
        "dueDate": "05/02/2025",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Belgium",
                "quantity": 17,
                "price": 36,
                "total": 612
            },
            {
                "description": "Poland",
                "quantity": 31,
                "price": 23,
                "total": 713
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-022025-1",
        "invoiceDate": "20/02/2025",
        "billingPeriod": "February 1 - February 28 2025",
        "recipientName": "Gaumat",
        "companyName": "Lopez & Co.",
        "address": "Street 441, City 12, Spain",
        "vatNumber": 6254224628,
        "subTotal": 4654,
        "vatPercentage": 21,
        "vatAmount": 977.34,
        "grandTotal": 5631.34,
        "dueDate": "05/03/2025",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "France",
                "quantity": 49,
                "price": 46,
                "total": 2254
            },
            {
                "description": "Greece",
                "quantity": 1,
                "price": 15,
                "total": 15
            },
            {
                "description": "Greece",
                "quantity": 7,
                "price": 27,
                "total": 189
            },
            {
                "description": "Estonia",
                "quantity": 5,
                "price": 39,
                "total": 195
            },
            {
                "description": "Sweden",
                "quantity": 13,
                "price": 15,
                "total": 195
            },
            {
                "description": "Romania",
                "quantity": 43,
                "price": 42,
                "total": 1806
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-032025-1",
        "invoiceDate": "20/03/2025",
        "billingPeriod": "March 1 - March 31 2025",
        "recipientName": "Gaumat",
        "companyName": "Moore & Co.",
        "address": "Street 258, City 18, Romania",
        "vatNumber": 2303691282,
        "subTotal": 1430,
        "vatPercentage": 21,
        "vatAmount": 300.3,
        "grandTotal": 1730.3,
        "dueDate": "05/04/2025",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Germany",
                "quantity": 22,
                "price": 47,
                "total": 1034
            },
            {
                "description": "Netherlands",
                "quantity": 11,
                "price": 36,
                "total": 396
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-042025-1",
        "invoiceDate": "20/04/2025",
        "billingPeriod": "April 1 - April 30 2025",
        "recipientName": "Gaumat",
        "companyName": "Lopez & Co.",
        "address": "Street 491, City 6, Ireland",
        "vatNumber": 5214855865,
        "subTotal": 3013,
        "vatPercentage": 21,
        "vatAmount": 632.73,
        "grandTotal": 3645.73,
        "dueDate": "05/05/2025",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Malta",
                "quantity": 47,
                "price": 40,
                "total": 1880
            },
            {
                "description": "Finland",
                "quantity": 12,
                "price": 11,
                "total": 132
            },
            {
                "description": "Spain",
                "quantity": 17,
                "price": 16,
                "total": 272
            },
            {
                "description": "Austria",
                "quantity": 35,
                "price": 6,
                "total": 210
            },
            {
                "description": "Portugal",
                "quantity": 1,
                "price": 24,
                "total": 24
            },
            {
                "description": "Austria",
                "quantity": 33,
                "price": 15,
                "total": 495
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-052025-1",
        "invoiceDate": "20/05/2025",
        "billingPeriod": "May 1 - May 31 2025",
        "recipientName": "Gaumat",
        "companyName": "Martinez & Co.",
        "address": "Street 72, City 6, Spain",
        "vatNumber": 6162204634,
        "subTotal": 3804,
        "vatPercentage": 21,
        "vatAmount": 798.84,
        "grandTotal": 4602.84,
        "dueDate": "05/06/2025",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Sweden",
                "quantity": 17,
                "price": 47,
                "total": 799
            },
            {
                "description": "Cyprus",
                "quantity": 20,
                "price": 38,
                "total": 760
            },
            {
                "description": "Denmark",
                "quantity": 37,
                "price": 47,
                "total": 1739
            },
            {
                "description": "Estonia",
                "quantity": 38,
                "price": 7,
                "total": 266
            },
            {
                "description": "Bulgaria",
                "quantity": 15,
                "price": 16,
                "total": 240
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-062025-1",
        "invoiceDate": "20/06/2025",
        "billingPeriod": "June 1 - June 30 2025",
        "recipientName": "Gaumat",
        "companyName": "Anderson & Sons",
        "address": "Street 108, City 3, Poland",
        "vatNumber": 6155637020,
        "subTotal": 4395,
        "vatPercentage": 21,
        "vatAmount": 922.95,
        "grandTotal": 5317.95,
        "dueDate": "05/07/2025",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Germany",
                "quantity": 33,
                "price": 12,
                "total": 396
            },
            {
                "description": "Lithuania",
                "quantity": 41,
                "price": 8,
                "total": 328
            },
            {
                "description": "Czech Republic",
                "quantity": 2,
                "price": 6,
                "total": 12
            },
            {
                "description": "Czech Republic",
                "quantity": 47,
                "price": 44,
                "total": 2068
            },
            {
                "description": "Romania",
                "quantity": 43,
                "price": 37,
                "total": 1591
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-072025-1",
        "invoiceDate": "20/07/2025",
        "billingPeriod": "July 1 - July 31 2025",
        "recipientName": "Gaumat",
        "companyName": "Martin & Co.",
        "address": "Street 195, City 5, Greece",
        "vatNumber": 963158859,
        "subTotal": 3678,
        "vatPercentage": 21,
        "vatAmount": 772.38,
        "grandTotal": 4450.38,
        "dueDate": "05/08/2025",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Croatia",
                "quantity": 2,
                "price": 50,
                "total": 100
            },
            {
                "description": "Hungary",
                "quantity": 49,
                "price": 37,
                "total": 1813
            },
            {
                "description": "Slovakia",
                "quantity": 47,
                "price": 5,
                "total": 235
            },
            {
                "description": "Lithuania",
                "quantity": 34,
                "price": 45,
                "total": 1530
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-082025-1",
        "invoiceDate": "20/08/2025",
        "billingPeriod": "August 1 - August 31 2025",
        "recipientName": "Gaumat",
        "companyName": "Thompson & Sons",
        "address": "Street 494, City 3, Romania",
        "vatNumber": 3097736338,
        "subTotal": 4710,
        "vatPercentage": 21,
        "vatAmount": 989.1,
        "grandTotal": 5699.1,
        "dueDate": "05/09/2025",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Italy",
                "quantity": 1,
                "price": 21,
                "total": 21
            },
            {
                "description": "Greece",
                "quantity": 18,
                "price": 43,
                "total": 774
            },
            {
                "description": "Czech Republic",
                "quantity": 49,
                "price": 23,
                "total": 1127
            },
            {
                "description": "Poland",
                "quantity": 49,
                "price": 20,
                "total": 980
            },
            {
                "description": "Latvia",
                "quantity": 29,
                "price": 43,
                "total": 1247
            },
            {
                "description": "Hungary",
                "quantity": 17,
                "price": 33,
                "total": 561
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-092025-1",
        "invoiceDate": "20/09/2025",
        "billingPeriod": "September 1 - September 30 2025",
        "recipientName": "Gaumat",
        "companyName": "Lopez & Co.",
        "address": "Street 212, City 11, Germany",
        "vatNumber": 8337955630,
        "subTotal": 170,
        "vatPercentage": 21,
        "vatAmount": 35.7,
        "grandTotal": 205.7,
        "dueDate": "05/10/2025",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Finland",
                "quantity": 17,
                "price": 10,
                "total": 170
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-102025-1",
        "invoiceDate": "20/10/2025",
        "billingPeriod": "October 1 - October 31 2025",
        "recipientName": "Gaumat",
        "companyName": "Jackson & Sons",
        "address": "Street 148, City 16, Latvia",
        "vatNumber": 2184029060,
        "subTotal": 4826,
        "vatPercentage": 21,
        "vatAmount": 1013.46,
        "grandTotal": 5839.46,
        "dueDate": "05/11/2025",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Latvia",
                "quantity": 44,
                "price": 54,
                "total": 2376
            },
            {
                "description": "Lithuania",
                "quantity": 23,
                "price": 42,
                "total": 966
            },
            {
                "description": "Portugal",
                "quantity": 13,
                "price": 54,
                "total": 702
            },
            {
                "description": "Denmark",
                "quantity": 7,
                "price": 41,
                "total": 287
            },
            {
                "description": "Estonia",
                "quantity": 11,
                "price": 45,
                "total": 495
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-112025-1",
        "invoiceDate": "20/11/2025",
        "billingPeriod": "November 1 - November 30 2025",
        "recipientName": "Gaumat",
        "companyName": "Thomas & Co.",
        "address": "Street 404, City 12, Romania",
        "vatNumber": 8734846763,
        "subTotal": 3612,
        "vatPercentage": 21,
        "vatAmount": 758.52,
        "grandTotal": 4370.52,
        "dueDate": "05/12/2025",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Poland",
                "quantity": 31,
                "price": 12,
                "total": 372
            },
            {
                "description": "Austria",
                "quantity": 1,
                "price": 29,
                "total": 29
            },
            {
                "description": "Finland",
                "quantity": 33,
                "price": 24,
                "total": 792
            },
            {
                "description": "Lithuania",
                "quantity": 27,
                "price": 53,
                "total": 1431
            },
            {
                "description": "Lithuania",
                "quantity": 26,
                "price": 38,
                "total": 988
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-122025-1",
        "invoiceDate": "20/12/2025",
        "billingPeriod": "December 1 - December 31 2025",
        "recipientName": "Gaumat",
        "companyName": "Martin & Co.",
        "address": "Street 376, City 8, Italy",
        "vatNumber": 4656713981,
        "subTotal": 553,
        "vatPercentage": 21,
        "vatAmount": 116.13,
        "grandTotal": 669.13,
        "dueDate": "05/01/2026",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Slovakia",
                "quantity": 5,
                "price": 53,
                "total": 265
            },
            {
                "description": "Hungary",
                "quantity": 6,
                "price": 48,
                "total": 288
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-012026-1",
        "invoiceDate": "20/01/2026",
        "billingPeriod": "January 1 - January 31 2026",
        "recipientName": "Gaumat",
        "companyName": "Thomas & Co.",
        "address": "Street 161, City 14, Germany",
        "vatNumber": 3772693326,
        "subTotal": 2430,
        "vatPercentage": 21,
        "vatAmount": 510.3,
        "grandTotal": 2940.3,
        "dueDate": "05/02/2026",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Germany",
                "quantity": 45,
                "price": 54,
                "total": 2430
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-022026-1",
        "invoiceDate": "20/02/2026",
        "billingPeriod": "February 1 - February 28 2026",
        "recipientName": "Gaumat",
        "companyName": "Thomas & Co.",
        "address": "Street 328, City 3, Germany",
        "vatNumber": 1739106934,
        "subTotal": 1638,
        "vatPercentage": 21,
        "vatAmount": 343.98,
        "grandTotal": 1981.98,
        "dueDate": "05/03/2026",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Italy",
                "quantity": 39,
                "price": 42,
                "total": 1638
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-032026-1",
        "invoiceDate": "20/03/2026",
        "billingPeriod": "March 1 - March 31 2026",
        "recipientName": "Gaumat",
        "companyName": "Wilson & Partners",
        "address": "Street 172, City 16, Ireland",
        "vatNumber": 7808326848,
        "subTotal": 2371,
        "vatPercentage": 21,
        "vatAmount": 497.91,
        "grandTotal": 2868.91,
        "dueDate": "05/04/2026",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Finland",
                "quantity": 8,
                "price": 7,
                "total": 56
            },
            {
                "description": "Greece",
                "quantity": 15,
                "price": 28,
                "total": 420
            },
            {
                "description": "Germany",
                "quantity": 3,
                "price": 50,
                "total": 150
            },
            {
                "description": "Germany",
                "quantity": 43,
                "price": 11,
                "total": 473
            },
            {
                "description": "Spain",
                "quantity": 9,
                "price": 28,
                "total": 252
            },
            {
                "description": "Latvia",
                "quantity": 34,
                "price": 30,
                "total": 1020
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-042026-1",
        "invoiceDate": "20/04/2026",
        "billingPeriod": "April 1 - April 30 2026",
        "recipientName": "Gaumat",
        "companyName": "Davis & Co.",
        "address": "Street 164, City 17, Ireland",
        "vatNumber": 5294522119,
        "subTotal": 4036,
        "vatPercentage": 21,
        "vatAmount": 847.56,
        "grandTotal": 4883.5599999999995,
        "dueDate": "05/05/2026",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Belgium",
                "quantity": 22,
                "price": 20,
                "total": 440
            },
            {
                "description": "Czech Republic",
                "quantity": 13,
                "price": 28,
                "total": 364
            },
            {
                "description": "Denmark",
                "quantity": 22,
                "price": 10,
                "total": 220
            },
            {
                "description": "Finland",
                "quantity": 1,
                "price": 8,
                "total": 8
            },
            {
                "description": "Greece",
                "quantity": 40,
                "price": 53,
                "total": 2120
            },
            {
                "description": "Czech Republic",
                "quantity": 17,
                "price": 52,
                "total": 884
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-052026-1",
        "invoiceDate": "20/05/2026",
        "billingPeriod": "May 1 - May 31 2026",
        "recipientName": "Gaumat",
        "companyName": "Harris Ltd.",
        "address": "Street 324, City 16, Austria",
        "vatNumber": 3358634899,
        "subTotal": 2917,
        "vatPercentage": 21,
        "vatAmount": 612.57,
        "grandTotal": 3529.57,
        "dueDate": "05/06/2026",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Germany",
                "quantity": 36,
                "price": 18,
                "total": 648
            },
            {
                "description": "Austria",
                "quantity": 7,
                "price": 52,
                "total": 364
            },
            {
                "description": "Denmark",
                "quantity": 16,
                "price": 21,
                "total": 336
            },
            {
                "description": "Spain",
                "quantity": 27,
                "price": 23,
                "total": 621
            },
            {
                "description": "Croatia",
                "quantity": 2,
                "price": 42,
                "total": 84
            },
            {
                "description": "Hungary",
                "quantity": 32,
                "price": 27,
                "total": 864
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-062026-1",
        "invoiceDate": "20/06/2026",
        "billingPeriod": "June 1 - June 30 2026",
        "recipientName": "Gaumat",
        "companyName": "Harris Ltd.",
        "address": "Street 246, City 4, Slovakia",
        "vatNumber": 216702549,
        "subTotal": 5143,
        "vatPercentage": 21,
        "vatAmount": 1080.03,
        "grandTotal": 6223.03,
        "dueDate": "05/07/2026",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Malta",
                "quantity": 38,
                "price": 39,
                "total": 1482
            },
            {
                "description": "Spain",
                "quantity": 49,
                "price": 24,
                "total": 1176
            },
            {
                "description": "France",
                "quantity": 38,
                "price": 39,
                "total": 1482
            },
            {
                "description": "France",
                "quantity": 41,
                "price": 11,
                "total": 451
            },
            {
                "description": "Hungary",
                "quantity": 46,
                "price": 12,
                "total": 552
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-072026-1",
        "invoiceDate": "20/07/2026",
        "billingPeriod": "July 1 - July 31 2026",
        "recipientName": "Gaumat",
        "companyName": "Thompson & Sons",
        "address": "Street 215, City 16, Ireland",
        "vatNumber": 5541021068,
        "subTotal": 2101,
        "vatPercentage": 21,
        "vatAmount": 441.21,
        "grandTotal": 2542.21,
        "dueDate": "05/08/2026",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Slovakia",
                "quantity": 14,
                "price": 53,
                "total": 742
            },
            {
                "description": "Portugal",
                "quantity": 27,
                "price": 25,
                "total": 675
            },
            {
                "description": "Sweden",
                "quantity": 36,
                "price": 19,
                "total": 684
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-082026-1",
        "invoiceDate": "20/08/2026",
        "billingPeriod": "August 1 - August 31 2026",
        "recipientName": "Gaumat",
        "companyName": "White & Partners",
        "address": "Street 253, City 16, France",
        "vatNumber": 4033816120,
        "subTotal": 226,
        "vatPercentage": 21,
        "vatAmount": 47.46,
        "grandTotal": 273.46,
        "dueDate": "05/09/2026",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Malta",
                "quantity": 5,
                "price": 34,
                "total": 170
            },
            {
                "description": "Italy",
                "quantity": 2,
                "price": 28,
                "total": 56
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-092026-1",
        "invoiceDate": "20/09/2026",
        "billingPeriod": "September 1 - September 30 2026",
        "recipientName": "Gaumat",
        "companyName": "Garcia Ltd.",
        "address": "Street 43, City 0, Germany",
        "vatNumber": 6581958206,
        "subTotal": 3913,
        "vatPercentage": 21,
        "vatAmount": 821.73,
        "grandTotal": 4734.73,
        "dueDate": "05/10/2026",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Cyprus",
                "quantity": 40,
                "price": 50,
                "total": 2000
            },
            {
                "description": "Romania",
                "quantity": 19,
                "price": 28,
                "total": 532
            },
            {
                "description": "Croatia",
                "quantity": 19,
                "price": 32,
                "total": 608
            },
            {
                "description": "Finland",
                "quantity": 23,
                "price": 25,
                "total": 575
            },
            {
                "description": "Malta",
                "quantity": 6,
                "price": 33,
                "total": 198
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-102026-1",
        "invoiceDate": "20/10/2026",
        "billingPeriod": "October 1 - October 31 2026",
        "recipientName": "Gaumat",
        "companyName": "Thomas & Co.",
        "address": "Street 271, City 6, Germany",
        "vatNumber": 3252846657,
        "subTotal": 1240,
        "vatPercentage": 21,
        "vatAmount": 260.4,
        "grandTotal": 1500.4,
        "dueDate": "05/11/2026",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Italy",
                "quantity": 47,
                "price": 21,
                "total": 987
            },
            {
                "description": "Lithuania",
                "quantity": 4,
                "price": 53,
                "total": 212
            },
            {
                "description": "Hungary",
                "quantity": 1,
                "price": 41,
                "total": 41
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-112026-1",
        "invoiceDate": "20/11/2026",
        "billingPeriod": "November 1 - November 30 2026",
        "recipientName": "Gaumat",
        "companyName": "Thompson & Sons",
        "address": "Street 111, City 5, Cyprus",
        "vatNumber": 961381672,
        "subTotal": 3054,
        "vatPercentage": 21,
        "vatAmount": 641.34,
        "grandTotal": 3695.34,
        "dueDate": "05/12/2026",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Czech Republic",
                "quantity": 43,
                "price": 22,
                "total": 946
            },
            {
                "description": "Poland",
                "quantity": 3,
                "price": 14,
                "total": 42
            },
            {
                "description": "Croatia",
                "quantity": 41,
                "price": 46,
                "total": 1886
            },
            {
                "description": "Portugal",
                "quantity": 5,
                "price": 36,
                "total": 180
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-122026-1",
        "invoiceDate": "20/12/2026",
        "billingPeriod": "December 1 - December 31 2026",
        "recipientName": "Gaumat",
        "companyName": "Taylor Ltd.",
        "address": "Street 39, City 11, Greece",
        "vatNumber": 4981291166,
        "subTotal": 2368,
        "vatPercentage": 21,
        "vatAmount": 497.28,
        "grandTotal": 2865.2799999999997,
        "dueDate": "05/01/2027",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Ireland",
                "quantity": 19,
                "price": 13,
                "total": 247
            },
            {
                "description": "Austria",
                "quantity": 33,
                "price": 13,
                "total": 429
            },
            {
                "description": "Romania",
                "quantity": 12,
                "price": 6,
                "total": 72
            },
            {
                "description": "Austria",
                "quantity": 2,
                "price": 18,
                "total": 36
            },
            {
                "description": "Austria",
                "quantity": 48,
                "price": 33,
                "total": 1584
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-012027-1",
        "invoiceDate": "20/01/2027",
        "billingPeriod": "January 1 - January 31 2027",
        "recipientName": "Gaumat",
        "companyName": "Lopez & Co.",
        "address": "Street 328, City 0, Italy",
        "vatNumber": 9118749208,
        "subTotal": 3981,
        "vatPercentage": 21,
        "vatAmount": 836.01,
        "grandTotal": 4817.01,
        "dueDate": "05/02/2027",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Portugal",
                "quantity": 4,
                "price": 42,
                "total": 168
            },
            {
                "description": "Romania",
                "quantity": 19,
                "price": 54,
                "total": 1026
            },
            {
                "description": "Ireland",
                "quantity": 38,
                "price": 35,
                "total": 1330
            },
            {
                "description": "Netherlands",
                "quantity": 47,
                "price": 31,
                "total": 1457
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-022027-1",
        "invoiceDate": "20/02/2027",
        "billingPeriod": "February 1 - February 28 2027",
        "recipientName": "Gaumat",
        "companyName": "Martin & Co.",
        "address": "Street 145, City 0, Romania",
        "vatNumber": 8091631554,
        "subTotal": 3382,
        "vatPercentage": 21,
        "vatAmount": 710.22,
        "grandTotal": 4092.2200000000003,
        "dueDate": "05/03/2027",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Cyprus",
                "quantity": 46,
                "price": 36,
                "total": 1656
            },
            {
                "description": "Bulgaria",
                "quantity": 10,
                "price": 51,
                "total": 510
            },
            {
                "description": "Malta",
                "quantity": 40,
                "price": 23,
                "total": 920
            },
            {
                "description": "Malta",
                "quantity": 8,
                "price": 37,
                "total": 296
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-032027-1",
        "invoiceDate": "20/03/2027",
        "billingPeriod": "March 1 - March 31 2027",
        "recipientName": "Gaumat",
        "companyName": "Jackson & Sons",
        "address": "Street 63, City 2, Netherlands",
        "vatNumber": 8547433501,
        "subTotal": 3015,
        "vatPercentage": 21,
        "vatAmount": 633.15,
        "grandTotal": 3648.15,
        "dueDate": "05/04/2027",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Austria",
                "quantity": 43,
                "price": 10,
                "total": 430
            },
            {
                "description": "Greece",
                "quantity": 27,
                "price": 36,
                "total": 972
            },
            {
                "description": "Finland",
                "quantity": 27,
                "price": 19,
                "total": 513
            },
            {
                "description": "Greece",
                "quantity": 22,
                "price": 50,
                "total": 1100
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-042027-1",
        "invoiceDate": "20/04/2027",
        "billingPeriod": "April 1 - April 30 2027",
        "recipientName": "Gaumat",
        "companyName": "Martin & Co.",
        "address": "Street 128, City 5, Netherlands",
        "vatNumber": 2149183989,
        "subTotal": 1914,
        "vatPercentage": 21,
        "vatAmount": 401.94,
        "grandTotal": 2315.94,
        "dueDate": "05/05/2027",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Estonia",
                "quantity": 7,
                "price": 7,
                "total": 49
            },
            {
                "description": "Malta",
                "quantity": 15,
                "price": 45,
                "total": 675
            },
            {
                "description": "Austria",
                "quantity": 34,
                "price": 35,
                "total": 1190
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-052027-1",
        "invoiceDate": "20/05/2027",
        "billingPeriod": "May 1 - May 31 2027",
        "recipientName": "Gaumat",
        "companyName": "Harris Ltd.",
        "address": "Street 466, City 1, Estonia",
        "vatNumber": 9387540860,
        "subTotal": 4925,
        "vatPercentage": 21,
        "vatAmount": 1034.25,
        "grandTotal": 5959.25,
        "dueDate": "05/06/2027",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Czech Republic",
                "quantity": 30,
                "price": 46,
                "total": 1380
            },
            {
                "description": "Italy",
                "quantity": 27,
                "price": 41,
                "total": 1107
            },
            {
                "description": "Germany",
                "quantity": 46,
                "price": 53,
                "total": 2438
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-062027-1",
        "invoiceDate": "20/06/2027",
        "billingPeriod": "June 1 - June 30 2027",
        "recipientName": "Gaumat",
        "companyName": "Hernandez & Sons",
        "address": "Street 477, City 11, Hungary",
        "vatNumber": 5032694215,
        "subTotal": 1671,
        "vatPercentage": 21,
        "vatAmount": 350.91,
        "grandTotal": 2021.91,
        "dueDate": "05/07/2027",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Portugal",
                "quantity": 18,
                "price": 52,
                "total": 936
            },
            {
                "description": "Slovakia",
                "quantity": 35,
                "price": 21,
                "total": 735
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-072027-1",
        "invoiceDate": "20/07/2027",
        "billingPeriod": "July 1 - July 31 2027",
        "recipientName": "Gaumat",
        "companyName": "Harris Ltd.",
        "address": "Street 272, City 17, Cyprus",
        "vatNumber": 1134064549,
        "subTotal": 1147,
        "vatPercentage": 21,
        "vatAmount": 240.87,
        "grandTotal": 1387.87,
        "dueDate": "05/08/2027",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Italy",
                "quantity": 26,
                "price": 31,
                "total": 806
            },
            {
                "description": "Ireland",
                "quantity": 30,
                "price": 6,
                "total": 180
            },
            {
                "description": "Lithuania",
                "quantity": 23,
                "price": 7,
                "total": 161
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-082027-1",
        "invoiceDate": "20/08/2027",
        "billingPeriod": "August 1 - August 31 2027",
        "recipientName": "Gaumat",
        "companyName": "Thompson & Sons",
        "address": "Street 177, City 18, Croatia",
        "vatNumber": 339131028,
        "subTotal": 169,
        "vatPercentage": 21,
        "vatAmount": 35.49,
        "grandTotal": 204.49,
        "dueDate": "05/09/2027",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Romania",
                "quantity": 13,
                "price": 13,
                "total": 169
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-092027-1",
        "invoiceDate": "20/09/2027",
        "billingPeriod": "September 1 - September 30 2027",
        "recipientName": "Gaumat",
        "companyName": "Gonzalez Ltd.",
        "address": "Street 380, City 11, Slovenia",
        "vatNumber": 6109790558,
        "subTotal": 5997,
        "vatPercentage": 21,
        "vatAmount": 1259.37,
        "grandTotal": 7256.37,
        "dueDate": "05/10/2027",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Latvia",
                "quantity": 49,
                "price": 26,
                "total": 1274
            },
            {
                "description": "Bulgaria",
                "quantity": 34,
                "price": 25,
                "total": 850
            },
            {
                "description": "Slovakia",
                "quantity": 21,
                "price": 39,
                "total": 819
            },
            {
                "description": "Belgium",
                "quantity": 31,
                "price": 46,
                "total": 1426
            },
            {
                "description": "Cyprus",
                "quantity": 37,
                "price": 44,
                "total": 1628
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-102027-1",
        "invoiceDate": "20/10/2027",
        "billingPeriod": "October 1 - October 31 2027",
        "recipientName": "Gaumat",
        "companyName": "Martin & Co.",
        "address": "Street 178, City 1, Italy",
        "vatNumber": 9567260411,
        "subTotal": 2035,
        "vatPercentage": 21,
        "vatAmount": 427.35,
        "grandTotal": 2462.35,
        "dueDate": "05/11/2027",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Slovakia",
                "quantity": 45,
                "price": 39,
                "total": 1755
            },
            {
                "description": "Slovakia",
                "quantity": 35,
                "price": 8,
                "total": 280
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-112027-1",
        "invoiceDate": "20/11/2027",
        "billingPeriod": "November 1 - November 30 2027",
        "recipientName": "Gaumat",
        "companyName": "Thomas & Co.",
        "address": "Street 225, City 4, Belgium",
        "vatNumber": 7221645054,
        "subTotal": 6021,
        "vatPercentage": 21,
        "vatAmount": 1264.41,
        "grandTotal": 7285.41,
        "dueDate": "05/12/2027",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Ireland",
                "quantity": 45,
                "price": 50,
                "total": 2250
            },
            {
                "description": "Latvia",
                "quantity": 50,
                "price": 17,
                "total": 850
            },
            {
                "description": "Austria",
                "quantity": 1,
                "price": 38,
                "total": 38
            },
            {
                "description": "Ireland",
                "quantity": 6,
                "price": 42,
                "total": 252
            },
            {
                "description": "Sweden",
                "quantity": 45,
                "price": 37,
                "total": 1665
            },
            {
                "description": "Italy",
                "quantity": 46,
                "price": 21,
                "total": 966
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-122027-1",
        "invoiceDate": "20/12/2027",
        "billingPeriod": "December 1 - December 31 2027",
        "recipientName": "Gaumat",
        "companyName": "Gonzalez Ltd.",
        "address": "Street 344, City 3, Italy",
        "vatNumber": 2329279951,
        "subTotal": 2485,
        "vatPercentage": 21,
        "vatAmount": 521.85,
        "grandTotal": 3006.85,
        "dueDate": "05/01/2028",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Greece",
                "quantity": 10,
                "price": 28,
                "total": 280
            },
            {
                "description": "Greece",
                "quantity": 49,
                "price": 45,
                "total": 2205
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-012028-1",
        "invoiceDate": "20/01/2028",
        "billingPeriod": "January 1 - January 31 2028",
        "recipientName": "Gaumat",
        "companyName": "Hernandez & Sons",
        "address": "Street 283, City 7, Denmark",
        "vatNumber": 7161925404,
        "subTotal": 2160,
        "vatPercentage": 21,
        "vatAmount": 453.6,
        "grandTotal": 2613.6,
        "dueDate": "05/02/2028",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Portugal",
                "quantity": 40,
                "price": 54,
                "total": 2160
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-022028-1",
        "invoiceDate": "20/02/2028",
        "billingPeriod": "February 1 - February 29 2028",
        "recipientName": "Gaumat",
        "companyName": "Martinez & Co.",
        "address": "Street 394, City 17, Denmark",
        "vatNumber": 9455416056,
        "subTotal": 5688,
        "vatPercentage": 21,
        "vatAmount": 1194.48,
        "grandTotal": 6882.48,
        "dueDate": "05/03/2028",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Denmark",
                "quantity": 28,
                "price": 52,
                "total": 1456
            },
            {
                "description": "Slovenia",
                "quantity": 20,
                "price": 52,
                "total": 1040
            },
            {
                "description": "Bulgaria",
                "quantity": 20,
                "price": 12,
                "total": 240
            },
            {
                "description": "Slovakia",
                "quantity": 41,
                "price": 50,
                "total": 2050
            },
            {
                "description": "Italy",
                "quantity": 30,
                "price": 29,
                "total": 870
            },
            {
                "description": "Croatia",
                "quantity": 4,
                "price": 8,
                "total": 32
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-032028-1",
        "invoiceDate": "20/03/2028",
        "billingPeriod": "March 1 - March 31 2028",
        "recipientName": "Gaumat",
        "companyName": "Garcia Ltd.",
        "address": "Street 396, City 15, Sweden",
        "vatNumber": 7361344284,
        "subTotal": 4273,
        "vatPercentage": 21,
        "vatAmount": 897.33,
        "grandTotal": 5170.33,
        "dueDate": "05/04/2028",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Germany",
                "quantity": 46,
                "price": 40,
                "total": 1840
            },
            {
                "description": "Greece",
                "quantity": 7,
                "price": 51,
                "total": 357
            },
            {
                "description": "Portugal",
                "quantity": 11,
                "price": 48,
                "total": 528
            },
            {
                "description": "Denmark",
                "quantity": 35,
                "price": 15,
                "total": 525
            },
            {
                "description": "Hungary",
                "quantity": 29,
                "price": 27,
                "total": 783
            },
            {
                "description": "Netherlands",
                "quantity": 24,
                "price": 10,
                "total": 240
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-042028-1",
        "invoiceDate": "20/04/2028",
        "billingPeriod": "April 1 - April 30 2028",
        "recipientName": "Gaumat",
        "companyName": "Lopez & Co.",
        "address": "Street 28, City 4, Bulgaria",
        "vatNumber": 1675398467,
        "subTotal": 3767,
        "vatPercentage": 21,
        "vatAmount": 791.07,
        "grandTotal": 4558.07,
        "dueDate": "05/05/2028",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Ireland",
                "quantity": 33,
                "price": 45,
                "total": 1485
            },
            {
                "description": "Bulgaria",
                "quantity": 24,
                "price": 16,
                "total": 384
            },
            {
                "description": "Portugal",
                "quantity": 39,
                "price": 14,
                "total": 546
            },
            {
                "description": "Cyprus",
                "quantity": 29,
                "price": 28,
                "total": 812
            },
            {
                "description": "Belgium",
                "quantity": 27,
                "price": 20,
                "total": 540
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-052028-1",
        "invoiceDate": "20/05/2028",
        "billingPeriod": "May 1 - May 31 2028",
        "recipientName": "Gaumat",
        "companyName": "Martin & Co.",
        "address": "Street 487, City 18, Hungary",
        "vatNumber": 8175276227,
        "subTotal": 2168,
        "vatPercentage": 21,
        "vatAmount": 455.28,
        "grandTotal": 2623.2799999999997,
        "dueDate": "05/06/2028",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Hungary",
                "quantity": 15,
                "price": 26,
                "total": 390
            },
            {
                "description": "Romania",
                "quantity": 25,
                "price": 41,
                "total": 1025
            },
            {
                "description": "Spain",
                "quantity": 32,
                "price": 6,
                "total": 192
            },
            {
                "description": "Denmark",
                "quantity": 6,
                "price": 53,
                "total": 318
            },
            {
                "description": "Greece",
                "quantity": 9,
                "price": 27,
                "total": 243
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-062028-1",
        "invoiceDate": "20/06/2028",
        "billingPeriod": "June 1 - June 30 2028",
        "recipientName": "Gaumat",
        "companyName": "Hernandez & Sons",
        "address": "Street 444, City 7, Ireland",
        "vatNumber": 2202528152,
        "subTotal": 4348,
        "vatPercentage": 21,
        "vatAmount": 913.08,
        "grandTotal": 5261.08,
        "dueDate": "05/07/2028",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Spain",
                "quantity": 47,
                "price": 33,
                "total": 1551
            },
            {
                "description": "Ireland",
                "quantity": 45,
                "price": 5,
                "total": 225
            },
            {
                "description": "Belgium",
                "quantity": 46,
                "price": 43,
                "total": 1978
            },
            {
                "description": "Spain",
                "quantity": 7,
                "price": 18,
                "total": 126
            },
            {
                "description": "Latvia",
                "quantity": 26,
                "price": 18,
                "total": 468
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-072028-1",
        "invoiceDate": "20/07/2028",
        "billingPeriod": "July 1 - July 31 2028",
        "recipientName": "Gaumat",
        "companyName": "Taylor Ltd.",
        "address": "Street 71, City 6, Finland",
        "vatNumber": 9704213825,
        "subTotal": 3056,
        "vatPercentage": 21,
        "vatAmount": 641.76,
        "grandTotal": 3697.76,
        "dueDate": "05/08/2028",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Austria",
                "quantity": 32,
                "price": 47,
                "total": 1504
            },
            {
                "description": "Netherlands",
                "quantity": 3,
                "price": 54,
                "total": 162
            },
            {
                "description": "Germany",
                "quantity": 24,
                "price": 31,
                "total": 744
            },
            {
                "description": "Austria",
                "quantity": 50,
                "price": 5,
                "total": 250
            },
            {
                "description": "Malta",
                "quantity": 12,
                "price": 33,
                "total": 396
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-082028-1",
        "invoiceDate": "20/08/2028",
        "billingPeriod": "August 1 - August 31 2028",
        "recipientName": "Gaumat",
        "companyName": "Martinez & Co.",
        "address": "Street 468, City 5, Ireland",
        "vatNumber": 4600168230,
        "subTotal": 135,
        "vatPercentage": 21,
        "vatAmount": 28.35,
        "grandTotal": 163.35,
        "dueDate": "05/09/2028",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Hungary",
                "quantity": 3,
                "price": 45,
                "total": 135
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-092028-1",
        "invoiceDate": "20/09/2028",
        "billingPeriod": "September 1 - September 30 2028",
        "recipientName": "Gaumat",
        "companyName": "Harris Ltd.",
        "address": "Street 211, City 13, Slovakia",
        "vatNumber": 3392825858,
        "subTotal": 3042,
        "vatPercentage": 21,
        "vatAmount": 638.82,
        "grandTotal": 3680.82,
        "dueDate": "05/10/2028",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Netherlands",
                "quantity": 22,
                "price": 20,
                "total": 440
            },
            {
                "description": "Portugal",
                "quantity": 32,
                "price": 28,
                "total": 896
            },
            {
                "description": "Bulgaria",
                "quantity": 48,
                "price": 21,
                "total": 1008
            },
            {
                "description": "Slovenia",
                "quantity": 8,
                "price": 47,
                "total": 376
            },
            {
                "description": "Portugal",
                "quantity": 23,
                "price": 8,
                "total": 184
            },
            {
                "description": "Sweden",
                "quantity": 23,
                "price": 6,
                "total": 138
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-102028-1",
        "invoiceDate": "20/10/2028",
        "billingPeriod": "October 1 - October 31 2028",
        "recipientName": "Gaumat",
        "companyName": "Thompson & Sons",
        "address": "Street 281, City 18, Malta",
        "vatNumber": 3742112211,
        "subTotal": 3343,
        "vatPercentage": 21,
        "vatAmount": 702.03,
        "grandTotal": 4045.0299999999997,
        "dueDate": "05/11/2028",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Belgium",
                "quantity": 45,
                "price": 38,
                "total": 1710
            },
            {
                "description": "Slovenia",
                "quantity": 44,
                "price": 36,
                "total": 1584
            },
            {
                "description": "Sweden",
                "quantity": 1,
                "price": 49,
                "total": 49
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-112028-1",
        "invoiceDate": "20/11/2028",
        "billingPeriod": "November 1 - November 30 2028",
        "recipientName": "Gaumat",
        "companyName": "Lopez & Co.",
        "address": "Street 375, City 17, Sweden",
        "vatNumber": 3180744003,
        "subTotal": 5284,
        "vatPercentage": 21,
        "vatAmount": 1109.64,
        "grandTotal": 6393.64,
        "dueDate": "05/12/2028",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Belgium",
                "quantity": 19,
                "price": 36,
                "total": 684
            },
            {
                "description": "Poland",
                "quantity": 8,
                "price": 50,
                "total": 400
            },
            {
                "description": "Latvia",
                "quantity": 44,
                "price": 38,
                "total": 1672
            },
            {
                "description": "Denmark",
                "quantity": 29,
                "price": 32,
                "total": 928
            },
            {
                "description": "Slovakia",
                "quantity": 8,
                "price": 39,
                "total": 312
            },
            {
                "description": "Italy",
                "quantity": 28,
                "price": 46,
                "total": 1288
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-122028-1",
        "invoiceDate": "20/12/2028",
        "billingPeriod": "December 1 - December 31 2028",
        "recipientName": "Gaumat",
        "companyName": "Thompson & Sons",
        "address": "Street 452, City 14, France",
        "vatNumber": 9858235881,
        "subTotal": 2831,
        "vatPercentage": 21,
        "vatAmount": 594.51,
        "grandTotal": 3425.51,
        "dueDate": "05/01/2029",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Germany",
                "quantity": 29,
                "price": 22,
                "total": 638
            },
            {
                "description": "Hungary",
                "quantity": 41,
                "price": 35,
                "total": 1435
            },
            {
                "description": "Czech Republic",
                "quantity": 34,
                "price": 11,
                "total": 374
            },
            {
                "description": "Greece",
                "quantity": 12,
                "price": 32,
                "total": 384
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-012029-1",
        "invoiceDate": "20/01/2029",
        "billingPeriod": "January 1 - January 31 2029",
        "recipientName": "Gaumat",
        "companyName": "Taylor Ltd.",
        "address": "Street 285, City 19, Spain",
        "vatNumber": 4454939498,
        "subTotal": 1239,
        "vatPercentage": 21,
        "vatAmount": 260.19,
        "grandTotal": 1499.19,
        "dueDate": "05/02/2029",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Poland",
                "quantity": 29,
                "price": 39,
                "total": 1131
            },
            {
                "description": "Latvia",
                "quantity": 9,
                "price": 12,
                "total": 108
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-022029-1",
        "invoiceDate": "20/02/2029",
        "billingPeriod": "February 1 - February 28 2029",
        "recipientName": "Gaumat",
        "companyName": "Garcia Ltd.",
        "address": "Street 227, City 15, Lithuania",
        "vatNumber": 7968488330,
        "subTotal": 4211,
        "vatPercentage": 21,
        "vatAmount": 884.31,
        "grandTotal": 5095.3099999999995,
        "dueDate": "05/03/2029",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "France",
                "quantity": 43,
                "price": 49,
                "total": 2107
            },
            {
                "description": "Bulgaria",
                "quantity": 25,
                "price": 21,
                "total": 525
            },
            {
                "description": "Malta",
                "quantity": 43,
                "price": 31,
                "total": 1333
            },
            {
                "description": "Latvia",
                "quantity": 41,
                "price": 6,
                "total": 246
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-032029-1",
        "invoiceDate": "20/03/2029",
        "billingPeriod": "March 1 - March 31 2029",
        "recipientName": "Gaumat",
        "companyName": "Anderson & Sons",
        "address": "Street 319, City 5, Sweden",
        "vatNumber": 6726645596,
        "subTotal": 5740,
        "vatPercentage": 21,
        "vatAmount": 1205.4,
        "grandTotal": 6945.4,
        "dueDate": "05/04/2029",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Cyprus",
                "quantity": 42,
                "price": 8,
                "total": 336
            },
            {
                "description": "Austria",
                "quantity": 27,
                "price": 45,
                "total": 1215
            },
            {
                "description": "Estonia",
                "quantity": 29,
                "price": 29,
                "total": 841
            },
            {
                "description": "Slovakia",
                "quantity": 41,
                "price": 54,
                "total": 2214
            },
            {
                "description": "Belgium",
                "quantity": 30,
                "price": 29,
                "total": 870
            },
            {
                "description": "Ireland",
                "quantity": 6,
                "price": 44,
                "total": 264
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-042029-1",
        "invoiceDate": "20/04/2029",
        "billingPeriod": "April 1 - April 30 2029",
        "recipientName": "Gaumat",
        "companyName": "Martin & Co.",
        "address": "Street 479, City 18, Italy",
        "vatNumber": 5820209473,
        "subTotal": 1355,
        "vatPercentage": 21,
        "vatAmount": 284.55,
        "grandTotal": 1639.55,
        "dueDate": "05/05/2029",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Croatia",
                "quantity": 31,
                "price": 14,
                "total": 434
            },
            {
                "description": "Sweden",
                "quantity": 5,
                "price": 37,
                "total": 185
            },
            {
                "description": "Romania",
                "quantity": 23,
                "price": 32,
                "total": 736
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-052029-1",
        "invoiceDate": "20/05/2029",
        "billingPeriod": "May 1 - May 31 2029",
        "recipientName": "Gaumat",
        "companyName": "Martin & Co.",
        "address": "Street 185, City 15, Czech Republic",
        "vatNumber": 7116915194,
        "subTotal": 5547,
        "vatPercentage": 21,
        "vatAmount": 1164.87,
        "grandTotal": 6711.87,
        "dueDate": "05/06/2029",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Bulgaria",
                "quantity": 34,
                "price": 22,
                "total": 748
            },
            {
                "description": "Malta",
                "quantity": 12,
                "price": 54,
                "total": 648
            },
            {
                "description": "Spain",
                "quantity": 43,
                "price": 25,
                "total": 1075
            },
            {
                "description": "Netherlands",
                "quantity": 25,
                "price": 41,
                "total": 1025
            },
            {
                "description": "Portugal",
                "quantity": 23,
                "price": 40,
                "total": 920
            },
            {
                "description": "Italy",
                "quantity": 29,
                "price": 39,
                "total": 1131
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-062029-1",
        "invoiceDate": "20/06/2029",
        "billingPeriod": "June 1 - June 30 2029",
        "recipientName": "Gaumat",
        "companyName": "Martinez & Co.",
        "address": "Street 35, City 3, Portugal",
        "vatNumber": 2376809136,
        "subTotal": 2340,
        "vatPercentage": 21,
        "vatAmount": 491.4,
        "grandTotal": 2831.4,
        "dueDate": "05/07/2029",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Ireland",
                "quantity": 45,
                "price": 52,
                "total": 2340
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-072029-1",
        "invoiceDate": "20/07/2029",
        "billingPeriod": "July 1 - July 31 2029",
        "recipientName": "Gaumat",
        "companyName": "Thompson & Sons",
        "address": "Street 468, City 3, Greece",
        "vatNumber": 5750433595,
        "subTotal": 1774,
        "vatPercentage": 21,
        "vatAmount": 372.54,
        "grandTotal": 2146.54,
        "dueDate": "05/08/2029",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Romania",
                "quantity": 4,
                "price": 13,
                "total": 52
            },
            {
                "description": "Denmark",
                "quantity": 41,
                "price": 42,
                "total": 1722
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-082029-1",
        "invoiceDate": "20/08/2029",
        "billingPeriod": "August 1 - August 31 2029",
        "recipientName": "Gaumat",
        "companyName": "Thompson & Sons",
        "address": "Street 372, City 6, Czech Republic",
        "vatNumber": 4843682755,
        "subTotal": 1129,
        "vatPercentage": 21,
        "vatAmount": 237.09,
        "grandTotal": 1366.09,
        "dueDate": "05/09/2029",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Ireland",
                "quantity": 28,
                "price": 13,
                "total": 364
            },
            {
                "description": "Netherlands",
                "quantity": 15,
                "price": 51,
                "total": 765
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-092029-1",
        "invoiceDate": "20/09/2029",
        "billingPeriod": "September 1 - September 30 2029",
        "recipientName": "Gaumat",
        "companyName": "Taylor Ltd.",
        "address": "Street 28, City 17, Bulgaria",
        "vatNumber": 1789186900,
        "subTotal": 2823,
        "vatPercentage": 21,
        "vatAmount": 592.83,
        "grandTotal": 3415.83,
        "dueDate": "05/10/2029",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Belgium",
                "quantity": 20,
                "price": 45,
                "total": 900
            },
            {
                "description": "France",
                "quantity": 18,
                "price": 52,
                "total": 936
            },
            {
                "description": "Croatia",
                "quantity": 37,
                "price": 7,
                "total": 259
            },
            {
                "description": "Belgium",
                "quantity": 26,
                "price": 28,
                "total": 728
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-102029-1",
        "invoiceDate": "20/10/2029",
        "billingPeriod": "October 1 - October 31 2029",
        "recipientName": "Gaumat",
        "companyName": "Wilson & Partners",
        "address": "Street 60, City 12, Poland",
        "vatNumber": 5480296841,
        "subTotal": 3786,
        "vatPercentage": 21,
        "vatAmount": 795.06,
        "grandTotal": 4581.0599999999995,
        "dueDate": "05/11/2029",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Lithuania",
                "quantity": 10,
                "price": 53,
                "total": 530
            },
            {
                "description": "Greece",
                "quantity": 10,
                "price": 38,
                "total": 380
            },
            {
                "description": "Czech Republic",
                "quantity": 46,
                "price": 43,
                "total": 1978
            },
            {
                "description": "Italy",
                "quantity": 46,
                "price": 16,
                "total": 736
            },
            {
                "description": "Italy",
                "quantity": 6,
                "price": 27,
                "total": 162
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-112029-1",
        "invoiceDate": "20/11/2029",
        "billingPeriod": "November 1 - November 30 2029",
        "recipientName": "Gaumat",
        "companyName": "Gonzalez Ltd.",
        "address": "Street 387, City 16, Finland",
        "vatNumber": 8982623764,
        "subTotal": 2854,
        "vatPercentage": 21,
        "vatAmount": 599.34,
        "grandTotal": 3453.34,
        "dueDate": "05/12/2029",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Greece",
                "quantity": 39,
                "price": 19,
                "total": 741
            },
            {
                "description": "Slovakia",
                "quantity": 41,
                "price": 29,
                "total": 1189
            },
            {
                "description": "Spain",
                "quantity": 33,
                "price": 28,
                "total": 924
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-122029-1",
        "invoiceDate": "20/12/2029",
        "billingPeriod": "December 1 - December 31 2029",
        "recipientName": "Gaumat",
        "companyName": "White & Partners",
        "address": "Street 329, City 19, Germany",
        "vatNumber": 2930861809,
        "subTotal": 880,
        "vatPercentage": 21,
        "vatAmount": 184.8,
        "grandTotal": 1064.8,
        "dueDate": "05/01/2030",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Slovenia",
                "quantity": 20,
                "price": 44,
                "total": 880
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-012030-1",
        "invoiceDate": "20/01/2030",
        "billingPeriod": "January 1 - January 31 2030",
        "recipientName": "Gaumat",
        "companyName": "Hernandez & Sons",
        "address": "Street 460, City 0, Romania",
        "vatNumber": 7944599595,
        "subTotal": 863,
        "vatPercentage": 21,
        "vatAmount": 181.23,
        "grandTotal": 1044.23,
        "dueDate": "05/02/2030",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Denmark",
                "quantity": 1,
                "price": 42,
                "total": 42
            },
            {
                "description": "Denmark",
                "quantity": 20,
                "price": 5,
                "total": 100
            },
            {
                "description": "Slovenia",
                "quantity": 17,
                "price": 23,
                "total": 391
            },
            {
                "description": "Croatia",
                "quantity": 11,
                "price": 30,
                "total": 330
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-022030-1",
        "invoiceDate": "20/02/2030",
        "billingPeriod": "February 1 - February 28 2030",
        "recipientName": "Gaumat",
        "companyName": "Taylor Ltd.",
        "address": "Street 249, City 15, Cyprus",
        "vatNumber": 3271444908,
        "subTotal": 2275,
        "vatPercentage": 21,
        "vatAmount": 477.75,
        "grandTotal": 2752.75,
        "dueDate": "05/03/2030",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Austria",
                "quantity": 9,
                "price": 48,
                "total": 432
            },
            {
                "description": "Cyprus",
                "quantity": 21,
                "price": 33,
                "total": 693
            },
            {
                "description": "Romania",
                "quantity": 4,
                "price": 8,
                "total": 32
            },
            {
                "description": "Cyprus",
                "quantity": 26,
                "price": 43,
                "total": 1118
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-032030-1",
        "invoiceDate": "20/03/2030",
        "billingPeriod": "March 1 - March 31 2030",
        "recipientName": "Gaumat",
        "companyName": "White & Partners",
        "address": "Street 22, City 16, Bulgaria",
        "vatNumber": 3155438352,
        "subTotal": 2078,
        "vatPercentage": 21,
        "vatAmount": 436.38,
        "grandTotal": 2514.38,
        "dueDate": "05/04/2030",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Spain",
                "quantity": 28,
                "price": 41,
                "total": 1148
            },
            {
                "description": "Latvia",
                "quantity": 37,
                "price": 19,
                "total": 703
            },
            {
                "description": "Italy",
                "quantity": 15,
                "price": 9,
                "total": 135
            },
            {
                "description": "Austria",
                "quantity": 2,
                "price": 46,
                "total": 92
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-042030-1",
        "invoiceDate": "20/04/2030",
        "billingPeriod": "April 1 - April 30 2030",
        "recipientName": "Gaumat",
        "companyName": "Moore & Co.",
        "address": "Street 225, City 9, Portugal",
        "vatNumber": 1819508225,
        "subTotal": 1466,
        "vatPercentage": 21,
        "vatAmount": 307.86,
        "grandTotal": 1773.8600000000001,
        "dueDate": "05/05/2030",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Slovakia",
                "quantity": 28,
                "price": 35,
                "total": 980
            },
            {
                "description": "Greece",
                "quantity": 6,
                "price": 51,
                "total": 306
            },
            {
                "description": "Austria",
                "quantity": 30,
                "price": 6,
                "total": 180
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-052030-1",
        "invoiceDate": "20/05/2030",
        "billingPeriod": "May 1 - May 31 2030",
        "recipientName": "Gaumat",
        "companyName": "Harris Ltd.",
        "address": "Street 72, City 9, Latvia",
        "vatNumber": 9318922445,
        "subTotal": 1664,
        "vatPercentage": 21,
        "vatAmount": 349.44,
        "grandTotal": 2013.44,
        "dueDate": "05/06/2030",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Germany",
                "quantity": 50,
                "price": 6,
                "total": 300
            },
            {
                "description": "Czech Republic",
                "quantity": 46,
                "price": 19,
                "total": 874
            },
            {
                "description": "Latvia",
                "quantity": 13,
                "price": 20,
                "total": 260
            },
            {
                "description": "Bulgaria",
                "quantity": 46,
                "price": 5,
                "total": 230
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-062030-1",
        "invoiceDate": "20/06/2030",
        "billingPeriod": "June 1 - June 30 2030",
        "recipientName": "Gaumat",
        "companyName": "Martinez & Co.",
        "address": "Street 30, City 12, Estonia",
        "vatNumber": 4797966380,
        "subTotal": 1260,
        "vatPercentage": 21,
        "vatAmount": 264.6,
        "grandTotal": 1524.6,
        "dueDate": "05/07/2030",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Denmark",
                "quantity": 28,
                "price": 45,
                "total": 1260
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-072030-1",
        "invoiceDate": "20/07/2030",
        "billingPeriod": "July 1 - July 31 2030",
        "recipientName": "Gaumat",
        "companyName": "Lopez & Co.",
        "address": "Street 184, City 19, Cyprus",
        "vatNumber": 8505569083,
        "subTotal": 2122,
        "vatPercentage": 21,
        "vatAmount": 445.62,
        "grandTotal": 2567.62,
        "dueDate": "05/08/2030",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Malta",
                "quantity": 41,
                "price": 48,
                "total": 1968
            },
            {
                "description": "Czech Republic",
                "quantity": 22,
                "price": 7,
                "total": 154
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-082030-1",
        "invoiceDate": "20/08/2030",
        "billingPeriod": "August 1 - August 31 2030",
        "recipientName": "Gaumat",
        "companyName": "Hernandez & Sons",
        "address": "Street 115, City 11, Netherlands",
        "vatNumber": 5657021095,
        "subTotal": 3665,
        "vatPercentage": 21,
        "vatAmount": 769.65,
        "grandTotal": 4434.65,
        "dueDate": "05/09/2030",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Finland",
                "quantity": 21,
                "price": 10,
                "total": 210
            },
            {
                "description": "Germany",
                "quantity": 13,
                "price": 43,
                "total": 559
            },
            {
                "description": "Estonia",
                "quantity": 10,
                "price": 27,
                "total": 270
            },
            {
                "description": "Sweden",
                "quantity": 29,
                "price": 46,
                "total": 1334
            },
            {
                "description": "Germany",
                "quantity": 38,
                "price": 34,
                "total": 1292
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-092030-1",
        "invoiceDate": "20/09/2030",
        "billingPeriod": "September 1 - September 30 2030",
        "recipientName": "Gaumat",
        "companyName": "Taylor Ltd.",
        "address": "Street 300, City 3, Belgium",
        "vatNumber": 8630889014,
        "subTotal": 2960,
        "vatPercentage": 21,
        "vatAmount": 621.6,
        "grandTotal": 3581.6,
        "dueDate": "05/10/2030",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Spain",
                "quantity": 36,
                "price": 52,
                "total": 1872
            },
            {
                "description": "Slovakia",
                "quantity": 24,
                "price": 22,
                "total": 528
            },
            {
                "description": "Estonia",
                "quantity": 35,
                "price": 16,
                "total": 560
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-102030-1",
        "invoiceDate": "20/10/2030",
        "billingPeriod": "October 1 - October 31 2030",
        "recipientName": "Gaumat",
        "companyName": "White & Partners",
        "address": "Street 447, City 19, Lithuania",
        "vatNumber": 5886595867,
        "subTotal": 621,
        "vatPercentage": 21,
        "vatAmount": 130.41,
        "grandTotal": 751.41,
        "dueDate": "05/11/2030",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Italy",
                "quantity": 23,
                "price": 27,
                "total": 621
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-112030-1",
        "invoiceDate": "20/11/2030",
        "billingPeriod": "November 1 - November 30 2030",
        "recipientName": "Gaumat",
        "companyName": "Gonzalez Ltd.",
        "address": "Street 341, City 1, Malta",
        "vatNumber": 8957494823,
        "subTotal": 1528,
        "vatPercentage": 21,
        "vatAmount": 320.88,
        "grandTotal": 1848.88,
        "dueDate": "05/12/2030",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Portugal",
                "quantity": 15,
                "price": 50,
                "total": 750
            },
            {
                "description": "Ireland",
                "quantity": 31,
                "price": 9,
                "total": 279
            },
            {
                "description": "Czech Republic",
                "quantity": 3,
                "price": 36,
                "total": 108
            },
            {
                "description": "Czech Republic",
                "quantity": 20,
                "price": 15,
                "total": 300
            },
            {
                "description": "Romania",
                "quantity": 7,
                "price": 13,
                "total": 91
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-122030-1",
        "invoiceDate": "20/12/2030",
        "billingPeriod": "December 1 - December 31 2030",
        "recipientName": "Gaumat",
        "companyName": "Moore & Co.",
        "address": "Street 475, City 2, Slovakia",
        "vatNumber": 9826442100,
        "subTotal": 5965,
        "vatPercentage": 21,
        "vatAmount": 1252.65,
        "grandTotal": 7217.65,
        "dueDate": "05/01/2031",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Poland",
                "quantity": 11,
                "price": 46,
                "total": 506
            },
            {
                "description": "Greece",
                "quantity": 20,
                "price": 16,
                "total": 320
            },
            {
                "description": "Estonia",
                "quantity": 23,
                "price": 29,
                "total": 667
            },
            {
                "description": "Bulgaria",
                "quantity": 35,
                "price": 39,
                "total": 1365
            },
            {
                "description": "Germany",
                "quantity": 29,
                "price": 48,
                "total": 1392
            },
            {
                "description": "Croatia",
                "quantity": 35,
                "price": 49,
                "total": 1715
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-012031-1",
        "invoiceDate": "20/01/2031",
        "billingPeriod": "January 1 - January 31 2031",
        "recipientName": "Gaumat",
        "companyName": "Davis & Co.",
        "address": "Street 436, City 14, Cyprus",
        "vatNumber": 5984553078,
        "subTotal": 5639,
        "vatPercentage": 21,
        "vatAmount": 1184.19,
        "grandTotal": 6823.1900000000005,
        "dueDate": "05/02/2031",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Lithuania",
                "quantity": 26,
                "price": 51,
                "total": 1326
            },
            {
                "description": "Latvia",
                "quantity": 48,
                "price": 42,
                "total": 2016
            },
            {
                "description": "Finland",
                "quantity": 27,
                "price": 27,
                "total": 729
            },
            {
                "description": "Estonia",
                "quantity": 19,
                "price": 14,
                "total": 266
            },
            {
                "description": "Netherlands",
                "quantity": 42,
                "price": 31,
                "total": 1302
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-022031-1",
        "invoiceDate": "20/02/2031",
        "billingPeriod": "February 1 - February 28 2031",
        "recipientName": "Gaumat",
        "companyName": "Taylor Ltd.",
        "address": "Street 352, City 0, Czech Republic",
        "vatNumber": 7816529845,
        "subTotal": 1749,
        "vatPercentage": 21,
        "vatAmount": 367.29,
        "grandTotal": 2116.29,
        "dueDate": "05/03/2031",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Spain",
                "quantity": 1,
                "price": 6,
                "total": 6
            },
            {
                "description": "Ireland",
                "quantity": 49,
                "price": 21,
                "total": 1029
            },
            {
                "description": "Romania",
                "quantity": 17,
                "price": 42,
                "total": 714
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-032031-1",
        "invoiceDate": "20/03/2031",
        "billingPeriod": "March 1 - March 31 2031",
        "recipientName": "Gaumat",
        "companyName": "Harris Ltd.",
        "address": "Street 349, City 15, Finland",
        "vatNumber": 8271944116,
        "subTotal": 1757,
        "vatPercentage": 21,
        "vatAmount": 368.97,
        "grandTotal": 2125.9700000000003,
        "dueDate": "05/04/2031",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Czech Republic",
                "quantity": 17,
                "price": 19,
                "total": 323
            },
            {
                "description": "Slovakia",
                "quantity": 26,
                "price": 23,
                "total": 598
            },
            {
                "description": "Cyprus",
                "quantity": 44,
                "price": 19,
                "total": 836
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-042031-1",
        "invoiceDate": "20/04/2031",
        "billingPeriod": "April 1 - April 30 2031",
        "recipientName": "Gaumat",
        "companyName": "Lopez & Co.",
        "address": "Street 238, City 7, Denmark",
        "vatNumber": 6284458076,
        "subTotal": 705,
        "vatPercentage": 21,
        "vatAmount": 148.05,
        "grandTotal": 853.05,
        "dueDate": "05/05/2031",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Denmark",
                "quantity": 15,
                "price": 47,
                "total": 705
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-052031-1",
        "invoiceDate": "20/05/2031",
        "billingPeriod": "May 1 - May 31 2031",
        "recipientName": "Gaumat",
        "companyName": "Harris Ltd.",
        "address": "Street 268, City 13, Hungary",
        "vatNumber": 1687547061,
        "subTotal": 2975,
        "vatPercentage": 21,
        "vatAmount": 624.75,
        "grandTotal": 3599.75,
        "dueDate": "05/06/2031",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Malta",
                "quantity": 34,
                "price": 18,
                "total": 612
            },
            {
                "description": "Portugal",
                "quantity": 23,
                "price": 31,
                "total": 713
            },
            {
                "description": "Cyprus",
                "quantity": 18,
                "price": 14,
                "total": 252
            },
            {
                "description": "Croatia",
                "quantity": 3,
                "price": 44,
                "total": 132
            },
            {
                "description": "Croatia",
                "quantity": 10,
                "price": 30,
                "total": 300
            },
            {
                "description": "Finland",
                "quantity": 23,
                "price": 42,
                "total": 966
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-062031-1",
        "invoiceDate": "20/06/2031",
        "billingPeriod": "June 1 - June 30 2031",
        "recipientName": "Gaumat",
        "companyName": "Hernandez & Sons",
        "address": "Street 272, City 6, Romania",
        "vatNumber": 2818296680,
        "subTotal": 423,
        "vatPercentage": 21,
        "vatAmount": 88.83,
        "grandTotal": 511.83,
        "dueDate": "05/07/2031",
        "currencySymbol": "€",
        "status": "Unpaid",
        "items": [
            {
                "description": "Greece",
                "quantity": 17,
                "price": 22,
                "total": 374
            },
            {
                "description": "Poland",
                "quantity": 7,
                "price": 7,
                "total": 49
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-072031-1",
        "invoiceDate": "20/07/2031",
        "billingPeriod": "July 1 - July 31 2031",
        "recipientName": "Gaumat",
        "companyName": "Lopez & Co.",
        "address": "Street 276, City 6, Italy",
        "vatNumber": 1447215047,
        "subTotal": 111,
        "vatPercentage": 21,
        "vatAmount": 23.31,
        "grandTotal": 134.31,
        "dueDate": "05/08/2031",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Ireland",
                "quantity": 3,
                "price": 37,
                "total": 111
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-082031-1",
        "invoiceDate": "20/08/2031",
        "billingPeriod": "August 1 - August 31 2031",
        "recipientName": "Gaumat",
        "companyName": "Harris Ltd.",
        "address": "Street 446, City 12, Romania",
        "vatNumber": 6698294674,
        "subTotal": 1566,
        "vatPercentage": 21,
        "vatAmount": 328.86,
        "grandTotal": 1894.8600000000001,
        "dueDate": "05/09/2031",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Portugal",
                "quantity": 29,
                "price": 48,
                "total": 1392
            },
            {
                "description": "Sweden",
                "quantity": 18,
                "price": 7,
                "total": 126
            },
            {
                "description": "Austria",
                "quantity": 6,
                "price": 8,
                "total": 48
            }
        ]
    },
    {
        "invoiceNumber": "Gaumat-092031-1",
        "invoiceDate": "20/09/2031",
        "billingPeriod": "September 1 - September 30 2031",
        "recipientName": "Gaumat",
        "companyName": "Martinez & Co.",
        "address": "Street 396, City 11, Croatia",
        "vatNumber": 3333567449,
        "subTotal": 5933,
        "vatPercentage": 21,
        "vatAmount": 1245.93,
        "grandTotal": 7178.93,
        "dueDate": "05/10/2031",
        "currencySymbol": "€",
        "status": "Paid",
        "items": [
            {
                "description": "Italy",
                "quantity": 35,
                "price": 48,
                "total": 1680
            },
            {
                "description": "Croatia",
                "quantity": 12,
                "price": 33,
                "total": 396
            },
            {
                "description": "Czech Republic",
                "quantity": 49,
                "price": 47,
                "total": 2303
            },
            {
                "description": "Slovenia",
                "quantity": 36,
                "price": 16,
                "total": 576
            },
            {
                "description": "Greece",
                "quantity": 16,
                "price": 24,
                "total": 384
            },
            {
                "description": "Finland",
                "quantity": 11,
                "price": 54,
                "total": 594
            }
        ]
    }
];