import { Pipe, PipeTransform } from '@angular/core';
import { PlanType } from '../enums/plan-type';

@Pipe({
  name: 'planType'
})
export class PlanTypePipe implements PipeTransform {

  transform(value: string, isPartner: boolean): string {
    if(isPartner) {
      if(value === PlanType.PREMIUM) return 'COMFORT+';
      if(value === PlanType.ULTRA) return 'FIRST CLASS';
    }
    return value;
  }

}
