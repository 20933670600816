import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiResponse } from "../../shared/models/api-response";
import { Member } from "../../shared/models/member";
import { FirebaseAuthService } from "./firebase-auth.service";
import { User } from "@angular/fire/auth";

@Injectable({
    providedIn: "root"
})
export class StatsService {
    adminUid: string;

    public constructor(
        private readonly httpClient: HttpClient,
        private readonly firebaseAuthService: FirebaseAuthService
    ) {
        this.firebaseAuthService.user.subscribe((user: User) => {
            this.adminUid = user.uid;
        });
    }

    public getEsimStats(): Observable<any> {
        return this.httpClient.get<any>(`/api/backoffice/dashboard/${this.adminUid}/esims`);
    }

    public getUserStats(): Observable<any> {
        return this.httpClient.get<any>(`/api/backoffice/dashboard/${this.adminUid}/users`);
    }

    public getSubStats(): Observable<any> {
        return this.httpClient.get<any>(`/api/backoffice/dashboard/${this.adminUid}/subs`);
    }

    public getDevRoles(): Observable<any> {
        return this.httpClient.get<any>(`/api/backoffice/dashboard/${this.adminUid}/roles`);
    }
}
