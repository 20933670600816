import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PartnerCompanyDashboardRoutingModule } from './partner-company-dashboard-routing.module';
import { PartnerCompanyDashboardComponent } from './partner-company-dashboard.component';
import { PartnersHttpService } from 'src/app/core/services/partners.http.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProviderHttpService } from 'src/app/core/services/provider.http.service';


@NgModule({
  declarations: [
    PartnerCompanyDashboardComponent
  ],
  imports: [
    CommonModule,
    PartnerCompanyDashboardRoutingModule,
    SharedModule
  ],
  providers: [
    PartnersHttpService,
    ProviderHttpService
  ]
})
export class PartnerCompanyDashboardModule { }
