import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { MOCK_INVOICES_LIST } from 'src/assets/mocks/mockInvoices';
import { Invoice } from 'src/app/core/models/invoices/invoice';
import { InvoicesHttpService } from 'src/app/core/services/invoices.http.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { MessageModalService } from 'src/app/core/services/message-modal.service';
import { FirebaseAuthService } from 'src/app/core/services/firebase-auth.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

  sortingColumn$ = new BehaviorSubject<string>('');
  sortingOrder: string = 'asc';
  currentPage$ = new BehaviorSubject(1);
  itemsPerPage = 10;
  startIndex = 0;
  endIndex = this.itemsPerPage;

  columns = [
    { name: 'invoiceNumber', label: 'Invoice Number' },
    { name: 'grandTotal', label: 'Amount' },
    { name: 'dueDate', label: 'Due Date' },
    { name: 'status', label: 'Status' }
  ];

  invoicesList$ = new BehaviorSubject([]);

  resultSize = 0;
  searchTerm$ = new BehaviorSubject('');

  userRole$ = this._firebaseAuthService.role;

  filteredInvoicesList$ = combineLatest([
    this.invoicesList$,
    this.searchTerm$,
    this.sortingColumn$,
    this.currentPage$
  ]).pipe(
    map(([invoicesList, searchTerm, sortingColumn, currentPage]) => {
      const result = invoicesList.filter(invoice => invoice.invoiceNumber?.toLowerCase().includes(searchTerm?.toLowerCase()) || (invoice.grandTotal + '').toLowerCase().includes(searchTerm?.toLowerCase()))
        .sort((a, b) => {
          if (sortingColumn && sortingColumn !== 'dueDate') {
            if (this.sortingOrder === 'asc') {
              return a[sortingColumn] > b[sortingColumn] ? 1 : -1;
            } else {
              return a[sortingColumn] < b[sortingColumn] ? 1 : -1;
            }
          }
          else if(sortingColumn === 'dueDate') {
            const dueDateA = a.dueDate.split('/').reverse().map(n => +n).join('/');
            const dueDateB = b.dueDate.split('/').reverse().map(n => +n).join('/');

            if (this.sortingOrder === 'asc') {
              return new Date(dueDateA) > new Date(dueDateB) ? 1 : -1;
            } else {
              return new Date(dueDateA) < new Date(dueDateB) ? 1 : -1;
            }
          }
          return 0;
        })

      this.resultSize = result.length;
      this.startIndex = (currentPage - 1) * this.itemsPerPage;
      this.endIndex = currentPage * this.itemsPerPage;

      return result.slice((currentPage - 1) * this.itemsPerPage, currentPage * this.itemsPerPage);
    })
  );

  constructor(
    private _invoicesHttpService: InvoicesHttpService,
    private _messageModalService: MessageModalService,
    private _firebaseAuthService: FirebaseAuthService
  ) { }

  ngOnInit(): void {
    this.loadInvoices();
  }

  searchTermInput(event: Event) {
    this.searchTerm$.next(event.target['value']);
  }

  sort(columnName: string) {
    if (this.sortingColumn$.value === columnName) {
      this.sortingOrder = this.sortingOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortingOrder = 'asc';
    }
    this.sortingColumn$.next(columnName);
  }

  downloadInvoice(e: Event, invoice: Invoice) {
    e.preventDefault();
    this._invoicesHttpService.downloadInvoice(invoice)
      .subscribe({
        next: (result: ArrayBuffer) => {
          saveAs(new Blob([new Uint8Array(result)]), `${invoice.invoiceNumber}.pdf`);
        },
        error: (err) => {
          console.log('Error:', err);
        },
      });
  }

  loadInvoices() {
    this.invoicesList$.next(MOCK_INVOICES_LIST);
  }

  pageChange(page: number) {
    this.currentPage$.next(page);
  }

  openInvoiceOptions(popover: NgbPopover, invoice: Invoice) {
    popover.open({ invoice });
		// if (popover.isOpen()) {
		// 	popover.close();
		// } else {
		// 	popover.open({ greeting, language });
		// }
	}

  askForMarkingAsPaid(invoice: Invoice) {
    this._messageModalService.open('warn', 'Are you sure you want to mark this invoice as paid?', [
      {
        btnText: 'Mark as Paid',
        action: this.markAsPaid.bind(this, invoice),
        btnClass: 'btn-primary'
      },
      {
        btnText: 'Cancel',
        action: () => {},
        btnClass: 'btn-outline-secondary'
      }
    ]);
  }

  askForMarkingAsUnpaid(invoice: Invoice) {
    this._messageModalService.open('warn', 'Are you sure you want to mark this invoice as unpaid?', [
      {
        btnText: 'Mark as Unpaid',
        action: this.markAsUnpaid.bind(this, invoice),
        btnClass: 'btn-primary'
      },
      {
        btnText: 'Cancel',
        action: () => {},
        btnClass: 'btn-outline-secondary'
      }
    ]);
  }
  
  markAsPaid(invoice: Invoice) {
    invoice.status = 'Paid';
  }

  markAsUnpaid(invoice: Invoice) {
    invoice.status = 'Unpaid';
  }
}
