<form class="form-inline">
  <div class="form-group">
    <div class="input-group" id="dateRangePicker">
      <div class="input-group-prepend px-2" (click)="dp.toggle();">
        <i class="bi bi-calendar4"></i>
      </div>
      <input class="form-control" id="dateRange" [placeholder]="placeholder" name="dp" [value]="formattedDateRange" ngbDatepicker
        #dp="ngbDatepicker" (dateSelect)="onDateSelection($event)" [displayMonths]="2"  [autoClose]="false"
        (click)="dp.toggle(); isOpen = !isOpen" readonly />
    </div>
  </div>
</form>