import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { User } from "@angular/fire/auth";
import { Observable } from "rxjs";
import { ApiResponse } from "src/app/shared/models/api-response";
import { FirebaseAuthService } from "src/app/core/services/firebase-auth.service";

@Injectable()
export class ProviderHttpService {
    adminUid: string;

    public constructor(
        private readonly httpClient: HttpClient,
        private readonly firebaseAuthService: FirebaseAuthService
    ) {
        this.firebaseAuthService.user.subscribe((user: User) => {
            this.adminUid = user.uid;
        });
    }

    public getPlans(uid: string): Observable<ApiResponse<any[]>> {
        return this.httpClient.get<ApiResponse<any[]>>(`/api/backoffice/plans/${this.adminUid}/users/${uid}`);
    }

    public getCountries(): Observable<ApiResponse<any[]>> {
        return this.httpClient.get<ApiResponse<any[]>>("/api/backoffice/countries", {});
    } 

    public getBalances(uid: string, iccId: string): Observable<any[]> {
        return this.httpClient.get<any[]>(`api/backoffice/providers/${this.adminUid}/uid/${uid}/iccId/${iccId}/balances`);
    }

    public getRealtimeSessions(uid: string, iccId: string): Observable<any> {
        return this.httpClient.get<any[]>(`api/backoffice/providers/${this.adminUid}/uid/${uid}/iccId/${iccId}/sessions`);
    }
}
