import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Invoice } from "../models/invoices/invoice";

@Injectable()
export class InvoicesHttpService {

    public constructor(
        private readonly httpClient: HttpClient,
    ) { }

    public downloadInvoice(invoice: Invoice): Observable<ArrayBuffer> {
        return this.httpClient.post(
            `/api/backoffice/invoices/${invoice.invoiceNumber}/download`,
            invoice,
            {responseType: 'arraybuffer'}
        );
    }
}
