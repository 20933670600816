export enum PlanType {
    FREEMIUM = "FREEMIUM",
    PREMIUM = "PREMIUM",
    ULTRA = "ULTRA",
    ADVERTISEMENT = "ADVERTISEMENT",
    LIMITED_ADVERTISEMENT = "LIMITED_ADVERTISEMENT",
    QUOTA = "QUOTA",
    THROTTLE = "THROTTLE",
    LIMITED_PREMIUM = "LIMITED_PREMIUM",
    LIMITED_ULTRA = "LIMITED_ULTRA",
    BYTE_PLAN = "BYTE_PLAN"

}