import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-firsty-loader',
  templateUrl: './firsty-loader.component.html',
  styleUrls: ['./firsty-loader.component.scss']
})
export class FirstyLoaderComponent implements OnChanges {

  @Input() isLoading: boolean = false;
  internalIsLoading = false;

  options: AnimationOptions = {    
    path: '/assets/animations/lottie/firsty-loader-lottie.json'
  };  

  isAppearing = true;
  constructor() { }  

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLoading) {
      if(changes.isLoading.currentValue) {
        this.internalIsLoading = true;
        setTimeout(() => {
          this.isAppearing = true;
        }, 0);
      } else {
        this.isAppearing = false;

        setTimeout(() => {
          this.internalIsLoading = false;
        }, 500);
      }
    }
  }

  onAnimate(animationItem: AnimationItem): void {}

}
