import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { User } from "@angular/fire/auth";
import { EMPTY, forkJoin, Subject } from "rxjs";
import { catchError, takeUntil } from "rxjs/operators";
import { FirebaseAuthService } from "src/app/core/services/firebase-auth.service";
import { ApiResponse } from "src/app/shared/models/api-response";
import * as bootstrap from "bootstrap";
import { StatsService } from "src/app/core/services/stats.service";

@Component({
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {
    public esimResultsLoaded: boolean = false;
    public subResultsLoaded: boolean = false;
    public userResultsLoaded: boolean = false;

    colorScheme = {
        domain: ['#647ACB', '#7D5BA6', '#86A59C', '#89C2D9']
    };

    public subscriptionStats = [
        {
            "name": "Advertisement",
            "value": 0,
        },
        // {
        //     "name": "Lite",
        //     "value": 0,
        // },
        {
            "name": "Premium",
            "value": 0,
        },
        {
            "name": "Ultra",
            "value": 0,
        },
        {
            "name": "Quota",
            "value": 0,
        }
    ];

    public signUpData = [
        {
            name: 'Installations',
            value: 0
        },
        {
            name: 'eSIM Assignments',
            value: 0
        },
        {
            name: 'Signups',
            value: 0
        }
    ];

    public userData = [
        {
            name: 'UberOne Users',
            value: 0
        },
        {
            name: 'Analytics Users',
            value: 0
        },
        {
            name: 'Partner Users',
            value: 0
        },
        {
            name: 'Active Users',
            value: 0
        }
    ];

    public lastDayData = [
        {
            name: 'Free Users',
            value: 0
        },
        {
            name: 'Promo Codes',
            value: 0
        },
        {
            name: 'Payments',
            value: 0
        },
        {
            name: 'Blocked',
            value: 0
        },
        {
            name: 'Partner',
            value: 0
        }
    ];

    esimData = [
        {
            name: 'Untracked eSIM',
            value: 0
        },
        {
            name: 'Analytics eSIM',
            value: 0
        },
        {
            name: 'Applet eSIM',
            value: 0
        }
    ];

    public constructor(
        private readonly statsService: StatsService,
        private cdr: ChangeDetectorRef
    ) { }

    private getEsimCount(): void {
        this.statsService.getEsimStats().subscribe((esim: any) => {

            this.esimData.find(stat => stat.name === 'Untracked eSIM').value = esim.esimCount;
            this.esimData.find(stat => stat.name === "Analytics eSIM").value = esim.analyticsEsimCount;
            this.esimData.find(stat => stat.name === "Applet eSIM").value = esim.appletEsimCount;
            this.signUpData.find(stat => stat.name === 'Installations').value = esim.installations;
            this.signUpData.find(stat => stat.name === 'eSIM Assignments').value = esim.esimAssignments;
            this.signUpData.find(stat => stat.name === "Signups").value = esim.lastUsers;

            this.esimResultsLoaded = true;


        });

        this.statsService.getSubStats().subscribe((sub: any) => {
            this.subscriptionStats.find(stat => stat.name === "Advertisement").value = sub.advertisementCount;
            //this.subscriptionStats.find(stat => stat.name === "Lite").value = sub.litePlanCount;
            this.subscriptionStats.find(stat => stat.name === "Premium").value = sub.premiumCount;
            this.subscriptionStats.find(stat => stat.name === "Ultra").value = sub.ultraCount;
            this.subscriptionStats.find(stat => stat.name === "Quota").value = sub.quotaCount;

            this.subResultsLoaded = true;


        });

        this.statsService.getUserStats().subscribe((user: any) => {

            this.userData.find(stat => stat.name === 'Analytics Users').value = user.totalAnalyticsUsers;
            this.userData.find(stat => stat.name === 'Active Users').value = user.activeUsers;
            this.userData.find(stat => stat.name === 'Partner Users').value = user.allPartnerUsers;
            this.userData.find(stat => stat.name === 'UberOne Users').value = user.uberOneUsers;

            this.lastDayData.find(stat => stat.name === "Free Users").value = user.adsWatched;
            this.lastDayData.find(stat => stat.name === 'Promo Codes').value = user.promoCodes;
            this.lastDayData.find(stat => stat.name === 'Payments').value = user.paidCount;
            this.lastDayData.find(stat => stat.name === "Blocked").value = user.blockCount;
            this.lastDayData.find(stat => stat.name === 'Partner').value = user.newPartnerUsers;
            this.userResultsLoaded = true;

            this.refreshData();


        });
    }

    refreshData() {
        this.cdr.detectChanges();
    }

    public ngOnInit(): void {
        this.getEsimCount();
        this.initialiseToolTips();
    }

    private initialiseToolTips(): void {
        setTimeout(() => {
            const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
            const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
                return new bootstrap.Tooltip(tooltipTriggerEl);
            })
        });
    }
}
