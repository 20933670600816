import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExcelUtilsService } from 'src/app/core/services/excel-utils.service';
import { MOCK_EMPLOYEES_DATA } from 'src/assets/mocks/mockEmployeesData';

@Component({
  selector: 'app-employee-data-consumption',
  templateUrl: './employee-data-consumption.component.html',
  styleUrls: ['./employee-data-consumption.component.scss']
})
export class EmployeeDataConsumptionComponent implements OnInit {

  sortingColumn$ = new BehaviorSubject<string>('');
  sortingOrder: string = 'asc';
  currentPage$ = new BehaviorSubject(1);
  itemsPerPage = 10;
  startIndex = 0;
  endIndex = this.itemsPerPage;

  columns = [
    { name: 'email', label: 'Employee' },
    { name: 'country', label: 'Country' },
    { name: 'days', label: 'Days' },
    { name: 'data_consumed', label: 'Data Consumed' },
    { name: 'total_price', label: 'Total Price' }
  ];

  list$ = new BehaviorSubject<EmployeeDataConsumption[]>([]);

  resultSize = 0;
  searchTerm$ = new BehaviorSubject('');

  filteredList$ = combineLatest([
    this.list$,
    this.searchTerm$,
    this.sortingColumn$,
    this.currentPage$
  ]).pipe(
    map(([list, searchTerm, sortingColumn, currentPage]) => {
      const result = list.filter(item =>
        item.country?.toLowerCase().includes(searchTerm?.toLowerCase())
        || item.email?.toLowerCase().includes(searchTerm?.toLowerCase())
        || item.country?.toLowerCase().includes(searchTerm?.toLowerCase())
        || (item.total_price + '').toLowerCase().includes(searchTerm?.toLowerCase())
        || (item.data_consumed + '').toLowerCase().includes(searchTerm?.toLowerCase())
        || (item.days + '').toLowerCase().includes(searchTerm?.toLowerCase())
      )
        .sort((a, b) => {
          if (sortingColumn) {
            if (this.sortingOrder === 'asc') {
              return a[sortingColumn] > b[sortingColumn] ? 1 : -1;
            } else {
              return a[sortingColumn] < b[sortingColumn] ? 1 : -1;
            }
          }
          return 0;
        })

      this.resultSize = result.length;
      this.startIndex = (currentPage - 1) * this.itemsPerPage;
      this.endIndex = currentPage * this.itemsPerPage;

      return result.slice((currentPage - 1) * this.itemsPerPage, currentPage * this.itemsPerPage);
    })
  );

  constructor(private _excelUtilsService: ExcelUtilsService) { }

  ngOnInit(): void {
    this.loadEmployeesDataConsumption();
  }

  searchTermInput(event: Event) {
    this.searchTerm$.next(event.target['value']);
  }

  loadEmployeesDataConsumption() {
    this.list$.next(MOCK_EMPLOYEES_DATA as unknown as EmployeeDataConsumption[]);
  }

  pageChange(page: number) {
    this.currentPage$.next(page);
  }

  sort(columnName: string) {
    if (this.sortingColumn$.value === columnName) {
      this.sortingOrder = this.sortingOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortingOrder = 'asc';
    }
    this.sortingColumn$.next(columnName);
  }

  exportAsExcel(e: Event) {
    e.preventDefault();
    const columns = this.columns.map((column) => ({ 'key': column.name, 'header': column.label }));
    this._excelUtilsService.exportAsExcel(columns, this.list$.value, `employee_data_consumption_${new Date().getTime()}`);
  }

}

export interface EmployeeDataConsumption {
  email: string;
  country: string;
  days: number;
  data_consumed: string;
  total_price: number;
}
