export const MOCK_PRICE_LIST = [
    {
        "name": "Firsty",
        "code": "firsty",
        "partnerId": "08764e0c-5f5a-4869-9ec0-2058dd170248",
        "link": null,
        "clientId": null,
        "domainEmailRegistirationAllowed": true,
        "prices": [
            { "country": { "name": "France", "isoCountryCode": "FR" }, "tier1": "200", "tier2": "150", "tier3": "100", "tier4": "100", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "United States", "isoCountryCode": "US" }, "tier1": "250", "tier2": "175", "tier3": "120", "tier4": "120", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "Canada", "isoCountryCode": "CA" }, "tier1": "230", "tier2": "165", "tier3": "115", "tier4": "115", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "United Kingdom", "isoCountryCode": "GB" }, "tier1": "220", "tier2": "160", "tier3": "110", "tier4": "110", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "Germany", "isoCountryCode": "DE" }, "tier1": "210", "tier2": "155", "tier3": "105", "tier4": "105", "comfort_plus": "220", "first_class": "280" }
        ]
    },
    {
        "name": "Melike Company",
        "code": "melikecompany",
        "partnerId": "4e7bfe1b-dbc4-4011-a904-0e15d8855bdd",
        "link": null,
        "clientId": null,
        "domainEmailRegistirationAllowed": true,
        "prices": [
            { "country": { "name": "Germany", "isoCountryCode": "DE" }, "tier1": "180", "tier2": "140", "tier3": "90", "tier4": "90", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "Turkey", "isoCountryCode": "TR" }, "tier1": "150", "tier2": "120", "tier3": "80", "tier4": "80", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "France", "isoCountryCode": "FR" }, "tier1": "170", "tier2": "130", "tier3": "85", "tier4": "85", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "United Kingdom", "isoCountryCode": "GB" }, "tier1": "190", "tier2": "145", "tier3": "95", "tier4": "95", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "Italy", "isoCountryCode": "IT" }, "tier1": "160", "tier2": "125", "tier3": "75", "tier4": "75", "comfort_plus": "220", "first_class": "280" }
        ]
    },
    {
        "name": "Utku's New & Good Test",
        "code": "utkusnewgoodtest",
        "partnerId": "80b2d151-cb02-4c7a-94f9-7ce56ba6397c",
        "link": null,
        "clientId": "2e5887bbabc545c2969927c8e6bb8c35",
        "domainEmailRegistirationAllowed": false,
        "prices": [
            { "country": { "name": "United Kingdom", "isoCountryCode": "GB" }, "tier1": "220", "tier2": "160", "tier3": "110", "tier4": "110", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "Ireland", "isoCountryCode": "IE" }, "tier1": "210", "tier2": "155", "tier3": "105", "tier4": "105", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "France", "isoCountryCode": "FR" }, "tier1": "200", "tier2": "150", "tier3": "100", "tier4": "100", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "Netherlands", "isoCountryCode": "NL" }, "tier1": "215", "tier2": "158", "tier3": "108", "tier4": "108", "comfort_plus": "220", "first_class": "280" }
        ]
    },
    {
        "name": "Stippl",
        "code": "stippl",
        "partnerId": "90e86bbe-6498-492a-a8ef-11f80786f0c1",
        "link": null,
        "clientId": "b14d612f52684490be0bd1d82a7b1881",
        "domainEmailRegistirationAllowed": false,
        "prices": [
            { "country": { "name": "Netherlands", "isoCountryCode": "NL" }, "tier1": "190", "tier2": "145", "tier3": "95", "tier4": "95", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "Germany", "isoCountryCode": "DE" }, "tier1": "185", "tier2": "140", "tier3": "90", "tier4": "90", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "Belgium", "isoCountryCode": "BE" }, "tier1": "188", "tier2": "143", "tier3": "93", "tier4": "93", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "France", "isoCountryCode": "FR" }, "tier1": "195", "tier2": "150", "tier3": "100", "tier4": "100", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "Luxembourg", "isoCountryCode": "LU" }, "tier1": "200", "tier2": "155", "tier3": "105", "tier4": "105", "comfort_plus": "220", "first_class": "280" }
        ]
    },
    {
        "name": "Partner Test",
        "code": "partner-test",
        "partnerId": "99380caa-f3d8-4565-8492-db923f2c2b22",
        "link": null,
        "clientId": "f6f59de5bccd472990f0a4d9839aca75",
        "domainEmailRegistirationAllowed": false,
        "prices": [
            { "country": { "name": "Canada", "isoCountryCode": "CA" }, "tier1": "230", "tier2": "165", "tier3": "115", "tier4": "115", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "United States", "isoCountryCode": "US" }, "tier1": "225", "tier2": "160", "tier3": "110", "tier4": "110", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "Mexico", "isoCountryCode": "MX" }, "tier1": "220", "tier2": "155", "tier3": "105", "tier4": "105", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "United Kingdom", "isoCountryCode": "GB" }, "tier1": "235", "tier2": "170", "tier3": "120", "tier4": "120", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "Australia", "isoCountryCode": "AU" }, "tier1": "240", "tier2": "175", "tier3": "125", "tier4": "125", "comfort_plus": "220", "first_class": "280" }
        ]
    },
    {
        "name": "Uber",
        "code": "uber",
        "partnerId": "9f283754-430a-472b-b687-71523af03fa2",
        "link": null,
        "clientId": null,
        "domainEmailRegistirationAllowed": false,
        "prices": [
            { "country": { "name": "United States", "isoCountryCode": "US" }, "tier1": "240", "tier2": "170", "tier3": "120", "tier4": "120", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "Canada", "isoCountryCode": "CA" }, "tier1": "235", "tier2": "165", "tier3": "115", "tier4": "115", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "United Kingdom", "isoCountryCode": "GB" }, "tier1": "230", "tier2": "160", "tier3": "110", "tier4": "110", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "Australia", "isoCountryCode": "AU" }, "tier1": "245", "tier2": "175", "tier3": "125", "tier4": "125", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "France", "isoCountryCode": "FR" }, "tier1": "225", "tier2": "155", "tier3": "105", "tier4": "105", "comfort_plus": "220", "first_class": "280" }
        ]
    },
    {
        "name": "Weekend Testing & Co",
        "code": "WeekendTestingCo",
        "partnerId": "f3132fe6-6ed6-42d9-b1f8-5776cfdee201",
        "link": null,
        "clientId": "db167dcc3df24681a54ead659365cba2",
        "domainEmailRegistirationAllowed": false,
        "prices": [
            { "country": { "name": "United Kingdom", "isoCountryCode": "GB" }, "tier1": "210", "tier2": "150", "tier3": "100", "tier4": "100", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "Australia", "isoCountryCode": "AU" }, "tier1": "220", "tier2": "160", "tier3": "110", "tier4": "110", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "United States", "isoCountryCode": "US" }, "tier1": "230", "tier2": "165", "tier3": "115", "tier4": "115", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "Canada", "isoCountryCode": "CA" }, "tier1": "225", "tier2": "155", "tier3": "105", "tier4": "105", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "Ireland", "isoCountryCode": "IE" }, "tier1": "215", "tier2": "153", "tier3": "103", "tier4": "103", "comfort_plus": "220", "first_class": "280" }
        ]
    },
    {
        "name": "Amsterdam Schiphol Airport",
        "code": "iuKk",
        "partnerId": "",
        "link": "https://connectedtest.page.link/iuKk",
        "clientId": "oKoBjbjcBh74j5aj62lL",
        "domainEmailRegistirationAllowed": false,
        "prices": [
            { "country": { "name": "Netherlands", "isoCountryCode": "NL" }, "tier1": "180", "tier2": "130", "tier3": "80", "tier4": "80", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "Germany", "isoCountryCode": "DE" }, "tier1": "185", "tier2": "135", "tier3": "85", "tier4": "85", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "Belgium", "isoCountryCode": "BE" }, "tier1": "190", "tier2": "140", "tier3": "90", "tier4": "90", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "France", "isoCountryCode": "FR" }, "tier1": "195", "tier2": "145", "tier3": "95", "tier4": "95", "comfort_plus": "220", "first_class": "280" },
            { "country": { "name": "United Kingdom", "isoCountryCode": "GB" }, "tier1": "200", "tier2": "150", "tier3": "100", "tier4": "100", "comfort_plus": "220", "first_class": "280" }
        ]
    },
    {
        "name": "DEME",
        "code": "DEME",
        "partnerId": "deme-1234",
        "link": null,
        "clientId": "DEME-1234",
        "domainEmailRegistirationAllowed": false,
        "prices": [
            { "country": { "name": "Netherlands", "isoCountryCode": "NL" }, "tier1": "190", "tier2": "145", "tier3": "95", "tier4": "70" },
            { "country": { "name": "Germany", "isoCountryCode": "DE" }, "tier1": "185", "tier2": "140", "tier3": "90", "tier4": "65" },
            { "country": { "name": "Belgium", "isoCountryCode": "BE" }, "tier1": "188", "tier2": "143", "tier3": "93", "tier4": "68" },
            { "country": { "name": "United Kingdom", "isoCountryCode": "GB" }, "tier1": "210", "tier2": "150", "tier3": "100", "tier4": "75" },
            { "country": { "name": "Australia", "isoCountryCode": "AU" }, "tier1": "220", "tier2": "160", "tier3": "110", "tier4": "80" },
            { "country": { "name": "United States", "isoCountryCode": "US" }, "tier1": "230", "tier2": "165", "tier3": "115", "tier4": "85" },
            { "country": { "name": "Canada", "isoCountryCode": "CA" }, "tier1": "225", "tier2": "155", "tier3": "105", "tier4": "78" },
            { "country": { "name": "Ireland", "isoCountryCode": "IE" }, "tier1": "215", "tier2": "153", "tier3": "103", "tier4": "77" }
        ]
    },
]