<div class="firsty-loader-page" [class.loading]="internalIsLoading" [class.appearing]="isAppearing">
    <div class="firsty-loader-container">
        <ng-lottie
            width="80px"
            height="80px"
            containerClass=""
            [options]="options"
            (animationCreated)="onAnimate($event)"
        ></ng-lottie>
    </div>
</div>

