<div class="bg-dark-blue-transparent-0-3 px-5 pt-5 br-0-5rem position-relative">
    <div class="position-absolute top-0 end-0 p-3">
        <span class="d-inline-flex align-items-center">
            <button (click)="close()" [tabindex]="isVisible ? 0 : -1" class="unstyled-btn">
                <i class="bi bi-arrow-left-circle-fill h1 text-firsty-green"></i>
            </button>
        </span>
    </div>
    
    <h1 class="text-center mb-4">Partner Registration</h1>

    <form [formGroup]="registrationForm" (ngSubmit)="sendData()">
        <div class="mb-3">
            <div class="form-floating">
                <input [tabindex]="isVisible ? 0 : -1" type="text" id="name-input" formControlName="name" class="form-control" placeholder="Name">
                <label for="name-input">Name</label>
            </div>
            <div *ngIf="registrationForm.get('name')?.touched && registrationForm.get('name')?.hasError('required')">
                <small class="form-input-error">Name required</small>
            </div>
        </div>

        <div class="mb-3">
            <div class="form-floating">
                <input [tabindex]="isVisible ? 0 : -1" type="email" id="email-input" formControlName="email" class="form-control" placeholder="Email address">
                <label for="email-input">Email address</label>
            </div>
            <div *ngIf="registrationForm.get('email')?.touched && registrationForm.get('email')?.hasError('required')">
                <small class="form-input-error">Email required</small>
            </div>
            <div *ngIf="registrationForm.get('email')?.touched && registrationForm.get('email')?.hasError('email')">
                <small class="form-input-error">Invalid e-mail format</small>
            </div>
        </div>
        
        <div class="mb-3">
            <div class="form-floating">
                <input [tabindex]="isVisible ? 0 : -1" type="password" id="password-input" formControlName="password" class="form-control" placeholder="Password">
                <label for="password-input">Password</label>
            </div>
            <div *ngIf="registrationForm.get('password')?.touched && registrationForm.get('password')?.hasError('required')">
                <small class="form-input-error">Password required</small>
            </div>
            <div *ngIf="registrationForm.get('password')?.touched && registrationForm.get('password')?.hasError('minlength')">
                <small class="form-input-error">Should contain at least 6 characters</small>
            </div>
        </div>

        <div class="mb-3">
            <div class="form-floating">
                <input [tabindex]="isVisible ? 0 : -1" type="password" id="confirm-password-input" formControlName="confirm_password" class="form-control" placeholder="Confirm Password">
                <label for="confirm-password-input">Confirm password</label>
            </div>
            <div *ngIf="registrationForm.get('confirm_password')?.touched && registrationForm.get('confirm_password')?.hasError('required')">
                <small class="form-input-error">Confirm password required</small>
            </div>
            <div *ngIf="registrationForm.get('confirm_password')?.touched && registrationForm.errors?.['notSame']">
                <small class="form-input-error">Must be identical to the password</small>
            </div>
        </div>

        <div class="mb-3 break-spaces text-white">
            <div class="d-flex align-items-center">
                <i class="bi bi-info-circle-fill"></i>
                <small class="fw-semibold ps-2">Password hints</small>
            </div>
            <div>
                <small>* The password should contain at least 6 characters</small>
            </div>
        </div>
        
        <div>
            <button type="submit" [disabled]="registrationForm.invalid" class="large-pill-btn">Register</button>
        </div>

        <div class="text-center pt-3 pb-4">
            <span class="footer-logo-container bg-firsty-green">
                <img src="assets/images/logo/firsty-logo-dark.svg" alt="">
            </span>
            <span class="footer-text-logo-container ms-1">
                <img src="assets/images/logo/firsty-text-logo.svg" alt="">
            </span>
        </div>
    </form>
</div>